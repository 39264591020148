import * as React from 'react';

import { SVGComponentProps } from './Venu';
const GooglePay: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='194'
    height='55'
    viewBox='0 0 194 55'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect y='0.719971' width='194' height='54.0408' rx='27.0204' fill='white' />
    <rect
      x='0.5'
      y='1.21997'
      width='193'
      height='53.0408'
      rx='26.5204'
      stroke='#2A6AC0'
      strokeOpacity='0.09'
    />
    <path
      d='M96.9623 27.387V33.6631H94.8945V18.1648H100.376C101.766 18.1648 102.95 18.6109 103.92 19.503C104.911 20.3952 105.406 21.4844 105.406 22.7707C105.406 24.0882 104.911 25.1774 103.92 26.0591C102.961 26.9409 101.776 27.3766 100.376 27.3766H96.9623V27.387ZM96.9623 20.0736V25.4782H100.419C101.238 25.4782 101.927 25.2085 102.466 24.6794C103.015 24.1504 103.295 23.5073 103.295 22.7811C103.295 22.0653 103.015 21.4325 102.466 20.9035C101.927 20.3536 101.249 20.0839 100.419 20.0839H96.9623V20.0736Z'
      fill='#383E41'
    />
    <path
      d='M110.813 22.7085C112.342 22.7085 113.549 23.1027 114.432 23.8911C115.315 24.6795 115.756 25.7583 115.756 27.1277V33.6631H113.786V32.1901H113.699C112.849 33.4038 111.707 34.0055 110.285 34.0055C109.068 34.0055 108.056 33.6631 107.238 32.9681C106.419 32.273 106.01 31.412 106.01 30.3747C106.01 29.2751 106.441 28.4037 107.302 27.7605C108.164 27.107 109.316 26.7854 110.749 26.7854C111.976 26.7854 112.989 27.0033 113.775 27.4389V26.9825C113.775 26.2875 113.495 25.7066 112.924 25.219C112.353 24.7315 111.686 24.4928 110.921 24.4928C109.768 24.4928 108.853 24.9596 108.185 25.9037L106.365 24.8041C107.367 23.4035 108.853 22.7085 110.813 22.7085ZM108.142 30.4057C108.142 30.9244 108.368 31.3602 108.831 31.7024C109.284 32.0448 109.822 32.2211 110.436 32.2211C111.309 32.2211 112.084 31.9099 112.763 31.2875C113.441 30.665 113.786 29.9388 113.786 29.0986C113.139 28.6111 112.246 28.3621 111.093 28.3621C110.253 28.3621 109.553 28.5592 108.993 28.943C108.422 29.3475 108.142 29.8352 108.142 30.4057Z'
      fill='#383E41'
    />
    <path
      d='M127 23.0508L120.107 38.3208H117.975L120.538 32.9784L115.993 23.0508H118.244L121.518 30.665H121.561L124.749 23.0508H127Z'
      fill='#383E41'
    />
    <path
      d='M84.8912 24.2531H76.2236V27.6764L81.2155 27.6774C81.013 28.8165 80.3614 29.7874 79.3631 30.4348V30.4354C79.3634 30.4352 79.3638 30.435 79.3642 30.4348L79.2743 32.4808L82.3356 32.6547C82.3352 32.6551 82.3347 32.6555 82.3344 32.6558C84.0694 31.1091 85.0636 28.8227 85.0636 26.1214C85.0635 25.472 85.0032 24.8506 84.8912 24.2531Z'
      fill='#0085F7'
    />
    <path
      d='M79.3647 30.4347C79.3644 30.4349 79.3639 30.4352 79.3636 30.4354C78.5366 30.9723 77.4718 31.2864 76.2264 31.2864C73.8193 31.2864 71.7773 29.7241 71.0461 27.6183H71.0457C71.0459 27.6186 71.046 27.619 71.0461 27.6193L68.4904 27.2295L67.9814 29.9095C69.5002 32.8116 72.621 34.8031 76.2265 34.8031C78.7179 34.8031 80.8111 34.014 82.335 32.6557C82.3355 32.6553 82.3359 32.655 82.3362 32.6546L79.3647 30.4347Z'
      fill='#00A94B'
    />
    <path
      d='M70.7576 25.9191C70.7576 25.3278 70.8599 24.7562 71.0463 24.2189L70.2482 21.9283H67.9811C67.3533 23.1286 67 24.4834 67 25.9191C67 27.3548 67.3544 28.7096 67.9811 29.9099L67.9815 29.9096L71.0463 27.6193C71.0462 27.619 71.046 27.6187 71.0459 27.6183C70.8598 27.0813 70.7576 26.5101 70.7576 25.9191Z'
      fill='#FFBB00'
    />
    <path
      d='M76.2259 17.0341C72.6212 17.0341 69.4991 19.0258 67.9805 21.9284L71.0455 24.2189C71.7768 22.1131 73.8187 20.5508 76.2258 20.5508C77.586 20.5508 78.8042 21.0021 79.7659 21.8839L82.3992 19.3496C80.7999 17.9147 78.7149 17.0341 76.2259 17.0341Z'
      fill='#FF4031'
    />
  </svg>
);
export default GooglePay;
