import React from 'react';
import { useTranslation } from 'react-i18next';

interface CopyRightFooterProps {
  bgColor?: string;
}

const CopyRightFooter: React.FC<CopyRightFooterProps> = ({ bgColor }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`w-full text-center py-2 h-[56px] items-center flex justify-center flex-wrap ${bgColor || 'bg-brand-bg'}`}
    >
      <p
        className={`${bgColor ? 'text-white' : 'text-paragraphText'} text-[14px] font-sans px-2`}
      >
        {t('copyRightFooter.heading')}
      </p>
    </div>
  );
};

export default CopyRightFooter;
