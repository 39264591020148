import * as React from 'react';

import { SVGComponentProps } from '../Venu';

const Alcohol: React.FC<SVGComponentProps> = (props) => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='22.163' rx='2.93333' fill='#6B759D' />
    <path
      d='M10.271 14.522C10.6762 14.0437 10.8495 13.4147 10.7465 12.7964L10.4434 10.9778H6.85078L6.54768 12.7964C6.44461 13.4147 6.61792 14.0437 7.02311 14.522C7.33406 14.8891 7.75513 15.1354 8.21739 15.2304V17.8814H7.07107V18.7408H10.2231V17.8814H9.07676V15.2304C9.53905 15.1354 9.96011 14.8891 10.271 14.522Z'
      fill='white'
    />
    <path d='M15.7503 11.8372H13.7451V14.9882H15.7503V11.8372Z' fill='white' />
    <path d='M14.1748 4.0741H11.5967V5.79285H14.1748V4.0741Z' fill='white' />
    <path
      d='M15.7507 10.9777V10.147C15.7507 9.18881 15.075 8.38538 14.1751 8.18815V6.65222H11.597V8.18815C10.7061 8.3834 10.0355 9.17285 10.0221 10.1184H11.1708L11.2905 10.8365L11.5936 12.6551C11.7382 13.5231 11.495 14.406 10.9262 15.0775C10.6742 15.375 10.3652 15.6193 10.0215 15.7976V17.022H11.0819V18.7408H15.7507V15.8475H12.8861V10.9777H15.7507Z'
      fill='white'
    />
  </svg>
);
export default Alcohol;
