import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom'; // For extracting the token from the URL

import { ROUTES } from '@/constants';
import { verifyEmail } from '@/services/authServices';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams(); // Hook to get URL query parameters
  const [verificationStatus, setVerificationStatus] = useState<
    'loading' | 'success' | 'error'
  >('loading');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const token = searchParams.get('token'); // Get the token from the query params

    if (token) {
      emailVerification(token);
    } else {
      setVerificationStatus('error');
      setErrorMessage('Invalid or missing token.');
    }
  }, [searchParams]);

  const emailVerification = async (token: string) => {
    const response = await verifyEmail({ token });
    if (response.status) {
      setVerificationStatus('success');
    } else {
      setVerificationStatus('error');
      setErrorMessage(response.message as string);
    }
  };

  return (
    <div className='flex items-center justify-center flex-col p-6 min-h-screen'>
      {verificationStatus === 'loading' && <p>Verifying your email...</p>}

      {verificationStatus === 'success' && (
        <div className='text-center'>
          <h1 className='text-xl font-bold text-green-600'>
            Email Verified Successfully!
          </h1>
          <p className='mt-4'>
            Thank you for verifying your email. You can now log in.
          </p>
          <Link
            to={ROUTES.LOGIN}
            className='mt-4 inline-block px-4 py-2 bg-brandGreen text-white font-semibold rounded'
          >
            Go to Login
          </Link>
        </div>
      )}

      {verificationStatus === 'error' && (
        <div className='text-center'>
          <h1 className='text-xl font-bold text-red-600'>
            Email Verification Failed
          </h1>
          <p className='mt-4'>{errorMessage}</p>
          <Link
            to={ROUTES.HOME}
            className='mt-4 inline-block px-4 py-2 bg-red-500 text-white font-semibold rounded'
          >
            Go Back to Home
          </Link>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
