import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import CheckboxCombo from '@/components/ui/Checkbox';
import TextInputCombo from '@/components/ui/Input';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FoodService = ({ values }: any) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const {
    cateringAmenities,
    checkboxCuisineOptions,
    checkboxDietaryOptions,
    deliveryOptionPickup,
    servingStyleOptions,
  } = useConstantData();

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };
  return (
    <div className='md:w-[440px]'>
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.foodService.heading')}
      </h1>
      <CheckboxCombo
        name='selectedCuisineOptions'
        options={checkboxCuisineOptions}
        label=''
      />
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.foodService.dietaryOptionsAvailable')}
      </h1>
      <CheckboxCombo
        name='selectedDietaryOptions'
        options={checkboxDietaryOptions}
        label=''
      />
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.foodService.servingStylesOffered')}
      </h1>
      {servingStyleOptions.map((style) => (
        <div key={style.value}>
          <CheckboxCombo
            name='servingStyleOptions'
            options={[{ value: style.value, label: style.label }]}
            label=''
          />
          {values.servingStyleOptions.includes(style.value) && (
            <div className='flex justify-between w-full gap-4'>
              <TextInputCombo
                label=''
                name={`${style.value}_min`}
                placeholder={t('vendorSignUpQuestionnaire.min')}
                type='number'
                min={0}
              />
              <p className='mx-2.5 mt-5 md:mx-8'>
                {t('vendorSignUpQuestionnaire.to')}
              </p>
              <TextInputCombo
                label=''
                name={`${style.value}_max`}
                placeholder={t('vendorSignUpQuestionnaire.max')}
                type='number'
              />
            </div>
          )}
        </div>
      ))}
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.foodService.cateringAmenities')}
      </h1>
      {cateringAmenities.map((val, index) => (
        <div key={val.value}>
          <CheckboxCombo
            name='cateringAmenities'
            options={[{ value: val.value, label: val.label }]}
            label=''
          />
          {values.cateringAmenities.includes(val.value) && (
            <div>
              {index < cateringAmenities.length - 2 ? (
                <div className='flex justify-between w-full gap-4'>
                  <TextInputCombo
                    label=''
                    name={`${val.value}_min`}
                    placeholder={t('vendorSignUpQuestionnaire.min')}
                    type='number'
                  />
                  <p className='mx-2.5 mt-5 md:mx-8'>
                    {t('vendorSignUpQuestionnaire.to')}
                  </p>
                  <TextInputCombo
                    label=''
                    name={`${val.value}_max`}
                    placeholder={t('vendorSignUpQuestionnaire.max')}
                    type='number'
                  />
                </div>
              ) : (
                <div className='flex items-center'>
                  <TextInputCombo
                    label={t(
                      'vendorSignUpQuestionnaire.equipmentRental.perHourPrice',
                    )}
                    inputStyle='w-full sm:w-[200px]'
                    name={`${val.value}_per_hour`}
                    placeholder={t(
                      'vendorSignUpQuestionnaire.foodService.perHourPlaceholder',
                    )}
                    type='number'
                    min={0}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.foodService.deliveryOptions')}
      </h1>

      {deliveryOptionPickup.map((style) => (
        <div key={style.value}>
          <CheckboxCombo
            name='deliveryOptionPickup'
            options={[{ value: style.value, label: style.label }]}
            label=''
          />
          {style.value === 'delivery' &&
            values.deliveryOptionPickup.includes(style.value) && (
              <div className='flex justify-between w-full gap-4'>
                <TextInputCombo
                  label=''
                  name={`${style.value}_min`}
                  placeholder={t('vendorSignUpQuestionnaire.min')}
                  type='number'
                  min={0}
                />
                <p className='mx-2.5 mt-5 md:mx-8'>
                  {t('vendorSignUpQuestionnaire.to')}
                </p>
                <TextInputCombo
                  label=''
                  name={`${style.value}_max`}
                  placeholder={t('vendorSignUpQuestionnaire.max')}
                  type='number'
                />
              </div>
            )}
        </div>
      ))}
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default FoodService;
