/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import GuestList1 from '@/assets/svg/GuestList1';
import GuestList2 from '@/assets/svg/GuestList2';
// import Settings from '@/assets/svg/Settings';
import { Typography } from '@/components/Typography';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setGuestList, setTotalCount } from '@/redux/slices/guestListSlice';
import {
  AddGuestList,
  DeleteGuest,
  DeleteGuestList,
  GetGuestList,
} from '@/services/guest';
import { showErrorToast } from '@/utils/toast';

import GuestInput from './GuestInputs';
import GuestTable from './GuestTable';
import FileUploader from './UploadCsv';

interface GrayBoxProps {
  icon: React.ReactNode;
  text: string;
}

const GrayBox: React.FC<GrayBoxProps> = ({ icon, text }) => (
  <div className='p-5 rounded-2xl w-full bg-lightGray'>
    {icon}
    <Typography
      variant='subheading'
      className='text-astrogranite md:text-lg text-xs'
    >
      {text}
    </Typography>
  </div>
);

const AddGuest = ({ setSelectedValue }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('eventId');
  const { guest, totalCount } = useAppSelector((state) => state.guestList);
  const [searchTerm, setSearchTerm] = useState('');
  const [GuestData, setGuestData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addGuestLoading, setAddGuestLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState('');
  const [filteredGuests, setFilteredGuests] = useState<any>([]);
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc');

  const fetchGuests = async (count = 5) => {
    setLoader(true);
    const response = await GetGuestList(count, searchTerm);
    dispatch(setGuestList({ guest: response?.data }));
    dispatch(setTotalCount(response?.totalCount));
    setFilteredGuests(response?.data);
    setLoading(false);
    setLoader(false);
  };

  useEffect(() => {
    fetchGuests();
  }, [searchTerm]);

  useEffect(() => {
    setSelectedValue(guest?.map((d) => d?._id));
  }, [guest]);

  const handleSortByName = () => {
    const direction = sortBy === 'asc' ? 'desc' : 'asc';
    setSortBy(direction);

    const sortedGuests = [...filteredGuests]?.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      return direction === 'asc'
        ? nameA < nameB
          ? -1
          : 1
        : nameA > nameB
          ? -1
          : 1;
    });
    setFilteredGuests(sortedGuests);
  };

  const handleAddGuest = async () => {
    if (GuestData.email && GuestData.name && GuestData.phone) {
      setAddGuestLoading(true);
      const response = await AddGuestList(GuestData);
      if (response) {
        fetchGuests();
        setGuestData({
          name: '',
          email: '',
          phone: '',
        });
      }
      setAddGuestLoading(false);
    } else {
      showErrorToast('All fields are required');
    }
  };

  const handleDeleteAll = async () => {
    setLoader(true);
    const { status } = await DeleteGuestList();
    if (status === 200) {
      fetchGuests();
    }
    setLoader(false);
  };

  const handleDeleteGuest = async (id: string) => {
    setDeleteLoading(id);
    await DeleteGuest(id);
    fetchGuests();
    setDeleteLoading('');
  };

  return (
    <div className='my-10'>
      <div className='md:flex justify-end'>
        <Typography
          variant='subheading'
          className='font-medium leading-[30px] text-textDarkGray'
        >
          Still deciding on the guest list?{' '}
          <span
            className='cursor-pointer'
            onClick={() => navigate(`/theme?eventId=${id}`)}
          >
            Skip this step{' '}
          </span>
        </Typography>
      </div>
      <div className='border border-[#EBEBEB] h-full rounded-3xl mt-4 p-4 md:p-9 md:flex gap-9'>
        <div className='md:w-2/5'>
          <div className='flex justify-between'>
            <Typography variant='subheading'>Add/Import Guests</Typography>
            {/* <Typography
              variant='subheading'
              className='text-voiletColor flex gap-2 items-center'
            >
              <Settings />
              Guest Options
            </Typography> */}
          </div>
          <div className='flex gap-3 mt-4'>
            <GrayBox
              icon={<GuestList1 className='mb-2.5' />}
              text='Use Past Guest Lists'
            />
            <GrayBox
              icon={<GuestList2 className='mb-2.5' />}
              text='Search Address Book'
            />
          </div>
          <FileUploader
            setLoading={setLoader}
            fetchGuests={() => {
              fetchGuests(99999999999);
            }}
          />
          <div className='my-5 md:mb-0 md:mt-10'>
            <Typography variant='xl' className='md:text-xl text-base'>
              Manually add guests
            </Typography>
            <GuestInput
              setGuestData={setGuestData}
              GuestData={GuestData}
              onAddGuest={handleAddGuest}
              addGuestLoading={addGuestLoading}
            />
          </div>
        </div>
        <GuestTable
          filteredGuests={filteredGuests}
          onDeleteGuest={handleDeleteGuest}
          onDeleteAll={handleDeleteAll}
          onSort={handleSortByName}
          // selectedValue={selectedValue}
          onSearchChange={(e) => setSearchTerm(e.target.value)}
          searchTerm={searchTerm}
          loading={loading}
          loader={loader}
          deleteLoading={deleteLoading}
          totalCount={totalCount}
          onViewAll={() => {
            if (guest?.length !== totalCount) {
              setLoading(true);
              fetchGuests(totalCount);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AddGuest;
