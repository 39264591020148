import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Twitter: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='58'
    height='57'
    viewBox='0 0 58 57'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    fill='none'
  >
    <g filter='url(#filter0_d_1969_9334)'>
      <rect
        x='15.5791'
        y='15'
        width='26.5792'
        height='26.5792'
        rx='13.2896'
        fill={props.fill ? '' : 'white'}
      />
      <path
        d='M36.831 22.9257C36.2346 23.1895 35.602 23.3628 34.9544 23.4398C35.637 23.0315 36.1479 22.3889 36.3919 21.6319C35.7513 22.0131 35.0489 22.2803 34.3169 22.4249C33.8251 21.8987 33.1734 21.5498 32.4629 21.4323C31.7524 21.3148 31.023 21.4354 30.388 21.7752C29.7531 22.115 29.2482 22.6551 28.9519 23.3115C28.6556 23.9678 28.5844 24.7037 28.7495 25.4047C27.4503 25.3395 26.1793 25.0019 25.0191 24.4137C23.8589 23.8256 22.8353 22.9999 22.0149 21.9905C21.7245 22.4893 21.5719 23.0563 21.5727 23.6335C21.5727 24.7664 22.1493 25.7672 23.0259 26.3531C22.5071 26.3368 21.9998 26.1967 21.5461 25.9445V25.9852C21.5463 26.7396 21.8074 27.4708 22.2851 28.0548C22.7628 28.6388 23.4278 29.0395 24.1673 29.1892C23.6857 29.3197 23.1808 29.3389 22.6907 29.2454C22.8992 29.8948 23.3056 30.4628 23.8529 30.8698C24.4003 31.2768 25.0612 31.5024 25.7431 31.515C25.0654 32.0473 24.2893 32.4408 23.4594 32.673C22.6294 32.9052 21.7618 32.9715 20.9062 32.8682C22.3998 33.8287 24.1385 34.3387 25.9142 34.337C31.9246 34.337 35.2114 29.3579 35.2114 25.0398C35.2114 24.8992 35.2075 24.757 35.2013 24.6179C35.841 24.1555 36.3932 23.5827 36.8318 22.9265L36.831 22.9257Z'
        fill='#04AAEB'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_1969_9334'
        x='0.715259'
        y='0.136157'
        width='56.3068'
        height='56.3069'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='7.43192' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1969_9334'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1969_9334'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
export default Twitter;
