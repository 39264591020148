import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import StarIcon from '@/assets/png/Star.png';
import UnionIcon from '@/assets/png/Union.png';
import Button from '@/components/ui/button';
import { TextInputCombo } from '@/components/ui/Input';
import { ROUTES, SignupType, UserRoles } from '@/constants';
import { signup } from '@/services/authServices';

const RegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    firstName: Yup.string().required(
      t('register.validation.firstNameRequired'),
    ),
    lastName: Yup.string().required(t('register.validation.lastNameRequired')),
    email: Yup.string()
      .email(t('register.validation.emailInvalid'))
      .required(t('register.validation.emailRequired')),
    password: Yup.string()
      .min(8, t('register.validation.passwordMinLength'))
      .required(t('register.validation.passwordRequired')),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        t('register.validation.confirmPasswordMatch'),
      )
      .required(t('register.validation.confirmPasswordRequired')),
  });

  const INITIAL_VALUES = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    confirmPassword: '',
  };

  const handleSubmit = async (values: typeof INITIAL_VALUES) => {
    const response = await signup({
      userRole: UserRoles.PLANNER,
      signupType: SignupType.EMAIL,
      ...values,
    });
    if (response) navigate(ROUTES.LOGIN);
  };

  return (
    <div className='flex items-center justify-center w-full px-3 md:flex-col'>
      <img
        src={UnionIcon}
        width={31}
        height={26}
        alt='Union Icon'
        className='absolute hidden left-10 top-24 md:block'
      />

      <div className='flex items-center self-center justify-center mt-5 md:flex-col sm:mt-14'>
        <div className='mt-1.5 flex flex-col items-center self-center w-full'>
          <div className='flex flex-col items-center gap-3.5 w-full'>
            <h1 className='sm:text-[36px] text-[23px] font-semibold text-headingColor font-sans'>
              {t('register.heading')}
            </h1>
            <img
              width={28}
              height={28}
              src={StarIcon}
              alt='Star Icon'
              className='absolute md:right-[400px] md:block hidden top-40'
            />

            <div className='flex justify-center gap-1 mb-7'>
              <p className='text-[16px] font-normal text-paragraphText font-sans'>
                {t('register.alreadyHaveAccount')}
              </p>
              <Link
                to={ROUTES.LOGIN}
                className='text-[16px] font-semibold text-brandGreen font-sans'
              >
                {t('register.loginHere')}
              </Link>
            </div>
          </div>

          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className='flex w-full sm:w-[500px] flex-col gap-2.5'>
                <div className='flex flex-col w-full gap-2 md:flex-row'>
                  <TextInputCombo
                    name='firstName'
                    placeholder={t('register.firstNamePlaceholder')}
                    type='text'
                    label={t('register.firstNameLabel')}
                  />
                  <TextInputCombo
                    name='lastName'
                    type='text'
                    placeholder={t('register.lastNamePlaceholder')}
                    label={t('register.lastNameLabel')}
                  />
                </div>
                <TextInputCombo
                  name='email'
                  placeholder={t('register.emailPlaceholder')}
                  type='email'
                  label={t('register.emailLabel')}
                />
                <TextInputCombo
                  name='password'
                  type='password'
                  placeholder={t('register.passwordPlaceholder')}
                  label={t('register.passwordLabel')}
                />
                <TextInputCombo
                  name='confirmPassword'
                  type='password'
                  placeholder={t('register.confirmPasswordPlaceholder')}
                  label={t('register.confirmPasswordLabel')}
                />

                <Button
                  variant='filled'
                  size='medium'
                  type='submit'
                  disabled={isSubmitting}
                  className='w-full mt-4 text-white bg-brandGreen'
                >
                  {isSubmitting
                    ? t('register.signUpLoadingBtn')
                    : t('register.signUpBtn')}
                </Button>
              </Form>
            )}
          </Formik>
          <p className='font-sans text-base font-medium text-textGray text-center my-4'>
            {t('login.areYouAVendorTxt')}
          </p>
          <Link
            to={ROUTES.VENDOR_REGISTER}
            className='text-textDarkGray text-[16px] font-medium font-sans'
          >
            {t('login.joinAsVendorBtn')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
