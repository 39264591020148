import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

import Invitationpreview from '@/assets/png/Invitationpreview.png';
import { Typography } from '@/components/Typography';
import { plannerHomeData } from '@/constants/plannerSection';
import { getAllEvents } from '@/services/planner';

interface IEvent {
  id: number;
  invitationCard: string;
  themeImage: string;
  eventType: string;
  _id: number;
}

const StatsSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allEvents, setAllEvents] = useState([]);

  const getEvents = async () => {
    try {
      const { data, status }: any = await getAllEvents();
      if (status) {
        setAllEvents(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className='xl:w-1/2'>
      <div className='bg-light-bg p-5 md:p-10 rounded-3xl w-full'>
        <div className='bg-white px-10 drop-shadow-md rounded-3xl p-6 w-full'>
          <div className='mb-5'>
            <Typography variant='heading' className='text-xl md:text-[32px]'>
              {`${t('dashboard.needAttention')} (4)`}
            </Typography>
          </div>
          <div className='space-y-3'>
            {plannerHomeData.map((data) => (
              <div
                key={data}
                className='flex justify-between items-center border p-4 rounded-lg shadow-sm '
              >
                <span>{data}</span>
                <a href='#'>
                  <ChevronRight color='#D2D3D6' />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='my-10'>
        <Typography
          variant='heading'
          className='text-xl md:text-[32px] text-center md:text-start'
        >
          Upcoming Events
        </Typography>
        <div className='flex md:gap-4 justify-center md:justify-start flex-wrap mt-5'>
          {allEvents.map((event: IEvent, idx) => (
            <div
              onClick={() => navigate(`/guest?eventId=${event?._id}`)}
              key={idx}
              className='bg-light-bg p-4 cursor-pointer rounded-[20px] w-48'
            >
              <img
                src={
                  event?.invitationCard ||
                  event?.themeImage ||
                  Invitationpreview
                }
                className='h-56 w-44 rounded-[15px]'
                alt=''
              />
              <Typography
                variant='subheading'
                className='text-xl mt-2 text-center leading-[26px]'
              >
                {event?.eventType}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
