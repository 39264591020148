import { SignupType, UserRoles } from '@/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterValuesByCategory = (values: any, category: string) => {
  const generalFields = {
    firstName: values.firstName,
    lastName: values.lastName,
    designation: values.title,
    email: values.email,
    phone: values.phone,
    businessName: values.business,
    location: values.location,
    website: values.website,
    socialLinks: values.socialMedia,
    description: values.description,
    images: values.images,
    category: values.category,
    password: values.password,
  };
  switch (category) {
    case 'Venue':
      return {
        ...generalFields,
        [category.toLocaleLowerCase()]: {
          venueType: values.venuType,
          wirelessInternet: values.wirelessInternet === 'yes',
          outsideCatering: values.outsideCatering === 'yes',
          smokingAllowed: values.smokingAllowed === 'yes',
          OutsideAlcohol: values.outsideAlcohol === 'yes',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          spaces: values.spaces.map((elem: any) => {
            return {
              capacity: {
                seated: elem.capacitySeated,
                standing: elem.capacityStanding,
              },
              rate: {
                amount: elem.rate,
                unit: elem.hourlyDropdown,
              },
              environment: elem.environmentOptions,
              vibe: elem.vibeDropdown,
            };
          }),
        },
      };

    case 'Decor':
      return {
        ...generalFields,
        [category.toLocaleLowerCase()]: {
          fullServiceDecor: values.selectedServiceOption === 'yes',
          averagePricePerPerson: values.averagePricePerPerson,
          balloonOptions: values.selectedBallonOption === 'yes',
          partyFavors: values.selectedPartyOption === 'yes',
          decorRentals: values.selectedDecorOption === 'yes',
          rentalOptions:
            values.selectedDecorOption === 'yes'
              ? [
                  values.selectedDecorsubOption.includes('chairRental')
                    ? 'chairRental'
                    : null,
                  values.selectedDecorsubOption.includes('clothRental')
                    ? 'clothRental'
                    : null,
                ].filter(Boolean)
              : [],
          decorPieces: values.selectedAccentsOptions || [],
        },
      };

    case 'Food Service':
      return {
        ...generalFields,
        [category.toLocaleLowerCase()]: {
          cuisine: values.selectedCuisineOptions || [],
          dietaryOptions: values.selectedDietaryOptions || [],
          buffetPriceRangeLow: values.buffet_min || '',
          buffetPriceRangeHigh: values.buffet_max || '',
          platedDinnerPriceRangeLow: values.platedDinner_min || '',
          platedDinnerPriceRangeHigh: values.platedDinner_max || '',
          familyStylePriceRangeLow: values.familyStyle_min || '',
          familyStylePriceRangeHigh: values.familyStyle_max || '',
          passedAppetizersPriceRangeLow: values.passedAppetizers_min || '',
          passedAppetizersPriceRangeHigh: values.passedAppetizers_max || '',
          setUp: values.cateringAmenities.includes('setUp') ? ['setUp'] : [],
          setUpFeeLow: values.setUp_min || '',
          setUpFeeHigh: values.setUp_max || '',
          cleanUp: values.cateringAmenities.includes('cleanUp')
            ? ['cleanUp']
            : [],
          cleanUpFeeLow: values.cleanUp_min || '',
          cleanUpFeeHigh: values.cleanUp_max || '',
          foodServiceRentals: values.cateringAmenities.includes(
            'foodServiceRentals',
          )
            ? ['foodServiceRentals']
            : [],
          foodServiceRentalsFeeLow: values.foodServiceRentals_min || '',
          foodServiceRentalsFeeHigh: values.foodServiceRentals_max || '',
          servers: values.cateringAmenities.includes('servers')
            ? ['servers']
            : [],
          serverFeePerHour: values.servers_per_hour || '',
          chefStations: values.cateringAmenities.includes('chefStations')
            ? ['chefStations']
            : [],
          chefStationsFeePerHour: values.chefStations_per_hour || '',
          deliveryOption: [
            ...(values.deliveryOptionPickup.includes('pickUp')
              ? ['pickUp']
              : []),
            ...(values.deliveryOptionPickup.includes('delivery')
              ? ['delivery']
              : []),
          ],
          deliveryFeeLow: values.delivery_min || '',
          deliveryFeeHigh: values.delivery_max || '',
        },
      };

    case 'Drink Services':
      return {
        ...generalFields,
        [category.toLocaleLowerCase()]: {
          drinkServiceDetails: {
            pricePerAttendantPerHour: values.attendantPrice,
            drinkPriceRange: {
              low: values.drinkPriceRange_min,
              high: values.drinkPriceRange_max,
            },
          },
          drinkServiceOptions: {
            services: values.selectedDrinkServices,
            provideAlcohol: values.provideAlcohol === 'yes',
            customerBuyAlcohol: values.serveAlcohol === 'yes',
          },
          drinkTypesOffered: values.selectedDrinkTypes,
        },
      };
    case 'Photographer/Videographer':
      return {
        ...generalFields,
        [category.toLocaleLowerCase()]: {
          photographer: {
            priceRange: {
              low: values.photography_min,
              high: values.photography_max,
            },
          },
          videography: {
            priceRange: {
              low: values.videography_min,
              high: values.videography_max,
            },
          },
          photobooth: {
            priceRange: {
              low: values.photobooth_min,
              high: values.photobooth_max,
            },
          },
          additionalServices: values.selectedAdditionalServices,
        },
      };

    case 'Entertainment':
      return {
        ...generalFields,
        entertainment: {
          entertainmentType: values.entertainmentOptions,
          ...(values.entertainmentOptions === 'Musician' && {
            musicianOptions: values.musiciansOptions,
            entertainmentHourlyRate: {
              low: values.musiciansHourlyFromRate,
              high: values.musiciansHourlyToRate,
            },
          }),
        },
      };

    case 'Party Favors':
      return {
        ...generalFields,
        party: {
          partyFavorsPriceRange: {
            low: values.partyFavorFromRate,
            high: values.partyFavorToRate,
          },
        },
      };

    case 'Equipment Rentals':
      return {
        ...generalFields,
        equipment: {
          partyFavorsPriceRange: {
            low: values.equipmentRentalFromRate,
            high: values.equipmentRentalToRate,
          },
          musicianOptions: values.rentalMusiciansOptions,
        },
      };

    case 'Bakery/Treats':
      return {
        ...generalFields,
        [category.toLocaleLowerCase()]: {
          bakeryCuisine: values.selectedBakeryCuisineOptions || [],
          bakeryDietaryOptions: values.selectedBakeryDietaryOptions || [],
          desserts: [
            values.bakeryDesertType.includes('cake')
              ? {
                  dessertType: 'Cake',
                  price: {
                    low: values.cake_min || 0,
                    high: values.cake_max || 0,
                  },
                }
              : null,
            values.bakeryDesertType.includes('cupCake')
              ? {
                  dessertType: 'Cupcakes',
                  price: {
                    low: values.cupCake_min || 0,
                    high: values.cupCake_max || 0,
                  },
                }
              : null,
            values.bakeryDesertType.includes('cookies')
              ? {
                  dessertType: 'Cookies',
                  price: {
                    low: values.cookies_min || 0,
                    high: values.cookies_max || 0,
                  },
                }
              : null,
          ].filter(Boolean),
          pickUp: values.bakeryDeliveryOptionPickup.includes('pickUp'),
          deliveryFee: {
            low: values.bakery_delivery_min || 0,
            high: values.bakery_delivery_max || 0,
          },
        },
      };

    default:
      return generalFields;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterSignupDataForVendor = (values: any) => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    signupType: SignupType.EMAIL,
    userRole: UserRoles.VENDOR,
  };
};

export const getApiEndpointByCategory = (category: string) => {
  switch (category) {
    case 'Venue':
      return 'venue';
    case 'Decor':
      return 'decor';
    case 'Food Service':
      return 'food';
    case 'Drink Services':
      return 'drinkServices';
    case 'Photographer/Videographer':
      return 'photographer';
    case 'Bakery/Treats':
      return 'bakery';
    case 'Entertainment':
      return 'entertainment';
    case 'Equipment Rentals':
      return 'equipment';
    case 'Party Favors':
      return 'party';
    default:
      throw new Error('Invalid category');
  }
};
