/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import { Calendar, Clock } from 'lucide-react';

import EasePlan from '@/assets/png/Ease-plan.png';
import EveryOnePlace from '@/assets/png/Everyone-place.png';
import HeroBackground from '@/assets/png/Planner-hero-background.png';
import SaveMoney from '@/assets/png/Save-money.png';
import SaveTime from '@/assets/png/Save-time.png';
import Event from '@/assets/svg/Event';
import Location from '@/assets/svg/Location';
import PurpleStar from '@/assets/svg/PurpleStar';
import Menu from '@/components/Menu';
import { Typography } from '@/components/Typography';
import Container from '@/components/ui/Container';
import { ROUTES, TIME } from '@/constants';
import { CreateEvent } from '@/services/planner';

import 'react-datepicker/dist/react-datepicker.css';

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const [, setLocation] = useState('');
  const datePickerRef = useRef<any>(null);
  const [type, setType] = useState('');
  const [eventDate, setEventDate] = useState(new Date());
  const [time, setTime] = useState({ start: '', end: '' });
  const disable = !type || !time?.start || !time.end;

  const typesDropdownData = [
    { label: 'Birthday Party', disable: false },
    { label: 'Engagement Party', disable: false },
    { label: 'Bridal Shower', disable: false },
    { label: 'General Party', disable: false },
    { label: 'Baby Shower', disable: false },
    { label: 'Bachelor/Bachelorette Party (Coming Soon)', disable: true },
    { label: 'Family Reunion (Coming soon)', disable: true },
    { label: 'High School Reunion (Coming soon)', disable: true },
    { label: 'Funeral/Memorial (Coming soon)', disable: true },
  ].map((type: { label: string; disable: boolean }) => ({
    label: type?.label,
    disable: type.disable,
    onClick: () => {
      setType(type?.label);
    },
  }));

  const startTimeDropDownData = TIME.map((time) => ({
    label: time,
    onClick: () => {
      setTime((prev) => ({
        ...prev,
        start: time,
      }));
    },
  }));
  const endTimeDropDownData = TIME.map((time) => ({
    label: time,
    onClick: () => {
      setTime((prev) => ({
        ...prev,
        end: time,
      }));
    },
  }));

  const handleCreateEvent = async () => {
    const formatTimeForApi = (hour: string) => {
      const parsedHour = parseInt(hour, 10);
      if (isNaN(parsedHour)) return null;
      if (parsedHour === 12 && hour.includes('am')) return 0;
      if (parsedHour < 12 && hour.includes('pm')) return parsedHour + 12;
      return parsedHour;
    };

    const formattedTime = {
      start: formatTimeForApi(time.start),
      end: formatTimeForApi(time.end),
    };
    try {
      const { data, status }: any = await CreateEvent({
        eventType: type,
        location: 'Central Park, NYC',
        eventDate: eventDate,
        time: formattedTime,
        approxGuestCount: 0,
        guestList: [],
      });
      if (status === 200) {
        navigate(`${ROUTES.PLANNER_GUEST}?eventId=${data?._id}`);
      }
    } catch (error) {
      console.error(error);
      // showErrorToast('Something went wrong');
    }
  };

  return (
    <div>
      <div className='blurred-background h-full blur-background'>
        <Container className='!mt-0 relative'>
          <PurpleStar className='absolute left-0 top-28 md:block hidden w-4' />
          <div className='flex lg:flex-row flex-col gap-10 pb-5 md:py-16'>
            <div className='pt-10 md:pt-20'>
              <div className='xl:w-[558px] lg:w-[450px]'>
                <Typography
                  variant='title'
                  className='font-extrabold !md:leading-[78px] '
                >
                  <span className='text-voiletColor'>Event planning</span> made
                  easier <span className='text-brandGreen'>for everyone</span>
                </Typography>
                <Typography className='text-eveningEast block mt-3'>
                  GoPlanMe provides guidance and vendors for a variety of event
                  types including funerals, birthday parties, family reunions,
                  baby showers, and many more!
                </Typography>
              </div>

              <div className='flex md:flex-row flex-col md:gap-0 gap-3 mt-10'>
                <div className='bg-white flex gap-2 items-center pl-0 md:pl-2 h-10 md:h-14 md:p-4 py-0.5 w-full lg:w-52 xl:w-64 2xl:w-80 rounded-[10px] md:rounded-bl-none md:rounded-tr-none md:rounded-br-none'>
                  <Menu
                    leftIcon={<Event />}
                    options={typesDropdownData}
                    selectedValue={type}
                    placeholder='Event type'
                    className='border-0 !justify-normal !font-medium !text-[10px] md:!text-base h-10 md:h-14'
                    fullWidth
                    isArrow={false}
                  />
                </div>
                <div className='bg-white border-l  border-r rounded-[10px] md:rounded-tr-[10px] md:rounded-none flex gap-2 items-center h-10 md:h-14 pl-2 md:p-4 py-0.5 w-full lg:w-52 xl:w-64 2xl:w-80 '>
                  <Location />
                  <ReactGoogleAutocomplete
                    defaultValue={''}
                    onChange={(e) => {
                      if (!(e.target as HTMLInputElement).value) {
                        setLocation('');
                      }
                    }}
                    placeholder='Location'
                    apiKey={process.env.REACT_GOOGLE_MAPS_API_KEY}
                    onPlaceSelected={(place) => {
                      setLocation(place);
                    }}
                    className='h-full focus:outline-none md:w-28 text-xs md:text-base'
                  />
                </div>
              </div>
              <div className='flex md:flex-row flex-col md:gap-0 gap-3 mt-3 md:mt-0'>
                <div className='bg-white rounded-[10px] md:border-t md:rounded-none md:rounded-bl-[10px] w-full md:w-[94%] flex gap-2 items-center h-10 md:h-14 pl-2 md:p-4 py-0.5 lg:w-52 xl:w-64 2xl:w-80 '>
                  <Calendar
                    color='#6B759D'
                    className='w-4 md:w-5 cursor-pointer'
                    onClick={() => datePickerRef.current?.setOpen(true)}
                  />
                  <DatePicker
                    selected={eventDate}
                    onChange={(date: any) => setEventDate(date)}
                    dateFormat='yyyy-MM-dd'
                    placeholderText='Select Date'
                    className='w-full cursor-pointer focus:outline-none text-xs md:text-base'
                    ref={datePickerRef}
                    minDate={new Date()}
                  />
                </div>
                <div className='bg-white md:border md:border-b-0 flex items-center h-10 md:h-14 pr-2 py-0.5 text-[10px] w-full md:text-base lg:w-52 xl:w-64 2xl:w-80 rounded-[10px] md:rounded-none md:rounded-br-[10px]'>
                  <Clock
                    color='#6B759D'
                    className='w-9 md:w-12 md:ml-4 ml-2 mr-2'
                  />
                  <Menu
                    options={startTimeDropDownData}
                    selectedValue={time.start}
                    className='border-none !rounded-[10px] !text-xs xl:!text-sm !font-normal !h-7 focus:outline-none !bg-light-bg '
                    placeholder='9:00 am'
                    minWidth='w-full'
                    isArrow={false}
                  />
                  -
                  <Menu
                    options={endTimeDropDownData}
                    selectedValue={time.end}
                    className='border-none !rounded-[10px] !text-xs xl:!text-sm !font-normal !h-7 focus:outline-none !bg-light-bg '
                    placeholder='6:00 pm'
                    minWidth='w-full'
                    isArrow={false}
                  />
                </div>
              </div>
              <button
                disabled={disable}
                onClick={() => {
                  handleCreateEvent();
                }}
                className='bg-voiletColor cursor-pointer mt-3 disabled:bg-gray-300 rounded-[10px] h-10 md:h-14 px-5 text-center md:text-left md:w-40 md:py-4 py-0.5'
              >
                <span className='text-white font-medium text-xs md:text-base'>
                  Start Planning
                </span>
              </button>
            </div>
            <img
              src={HeroBackground}
              alt='Hero Background'
              className='lg:w-96 xl:w-full'
            />
          </div>
          <div className='flex justify-center xl:justify-start flex-wrap xl:flex-nowrap lg:mt-20 mt-10'>
            <img
              src={EasePlan}
              alt='Ease plan Icon'
              className='lg:-ml-10 w-48 md:w-72 lg:w-fit xl:w-80 2xl:w-fit'
            />
            <img
              src={SaveMoney}
              alt='Save money Icon'
              className='w-48 md:w-72 lg:w-fit xl:w-80 2xl:w-fit'
            />
            <img
              src={SaveTime}
              alt='Save time Icon'
              className='w-48 md:w-72 lg:w-fit xl:w-80 2xl:w-fit'
            />
            <img
              src={EveryOnePlace}
              alt='Place Icon'
              className='w-48 md:w-72 lg:w-fit xl:w-80 2xl:w-fit'
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
