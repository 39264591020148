import React from 'react';

import Footer from '@/components/Footer';
import PlannerHeader from '@/components/PlannerHeader';

import Dashboard from './modules/Dashboard';

const PlannerHome = () => {
  return (
    <div className='w-full'>
      <PlannerHeader />
      <Dashboard />
      <Footer />
    </div>
  );
};

export default PlannerHome;
