import * as React from 'react';

import { SVGComponentProps } from './Venu';
const GuestListUpload: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='37'
    height='36'
    viewBox='0 0 37 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect x='0.5' width='36' height='36' rx='5' fill='#EAEBEF' />
    <path
      d='M26 18.75C25.8011 18.75 25.6103 18.829 25.4697 18.9697C25.329 19.1103 25.25 19.3011 25.25 19.5V22.6597C25.2494 23.2139 25.029 23.7453 24.6371 24.1371C24.2453 24.529 23.7139 24.7494 23.1597 24.75H13.8403C13.2861 24.7494 12.7547 24.529 12.3629 24.1371C11.971 23.7453 11.7506 23.2139 11.75 22.6597V19.5C11.75 19.3011 11.671 19.1103 11.5303 18.9697C11.3897 18.829 11.1989 18.75 11 18.75C10.8011 18.75 10.6103 18.829 10.4697 18.9697C10.329 19.1103 10.25 19.3011 10.25 19.5V22.6597C10.251 23.6116 10.6296 24.5243 11.3027 25.1973C11.9757 25.8704 12.8884 26.249 13.8403 26.25H23.1597C24.1116 26.249 25.0243 25.8704 25.6973 25.1973C26.3704 24.5243 26.749 23.6116 26.75 22.6597V19.5C26.75 19.3011 26.671 19.1103 26.5303 18.9697C26.3897 18.829 26.1989 18.75 26 18.75Z'
      fill={color}
    />
    <path
      d='M14.5298 15.5303L17.7496 12.3106V21.7501C17.7496 21.949 17.8286 22.1398 17.9692 22.2804C18.1099 22.4211 18.3007 22.5001 18.4996 22.5001C18.6985 22.5001 18.8893 22.4211 19.0299 22.2804C19.1706 22.1398 19.2496 21.949 19.2496 21.7501V12.3106L22.4693 15.5303C22.6108 15.667 22.8002 15.7425 22.9969 15.7408C23.1935 15.7391 23.3816 15.6603 23.5207 15.5212C23.6597 15.3821 23.7386 15.194 23.7403 14.9974C23.742 14.8007 23.6664 14.6113 23.5298 14.4698L19.0298 9.96983C18.8892 9.82923 18.6984 9.75024 18.4996 9.75024C18.3007 9.75024 18.11 9.82923 17.9693 9.96983L13.4693 14.4698C13.3327 14.6113 13.2571 14.8007 13.2588 14.9974C13.2605 15.194 13.3394 15.3821 13.4785 15.5212C13.6175 15.6603 13.8056 15.7391 14.0023 15.7408C14.1989 15.7425 14.3884 15.667 14.5298 15.5303Z'
      fill={color}
    />
  </svg>
);
export default GuestListUpload;
