import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Required: React.FC<SVGComponentProps> = ({
  color = '#FA4017',
  ...props
}) => (
  <svg
    width='11'
    height='10'
    viewBox='0 0 11 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.46413 9.86359L4.66016 6.41189L1.76243 8.31246L0.722656 6.50564L3.81641 4.9545L0.722656 3.40336L1.76243 1.59655L4.66016 3.49711L4.46413 0.0454102H6.53516L6.34766 3.49711L9.24538 1.59655L10.2852 3.40336L7.18288 4.9545L10.2852 6.50564L9.24538 8.31246L6.34766 6.41189L6.53516 9.86359H4.46413Z'
      fill={color}
    />
  </svg>
);
export default Required;
