import * as React from 'react';

import { SVGComponentProps } from './Venu';
const PhotographerSVG: React.FC<SVGComponentProps> = ({
  color = '#0A0C2C',
  ...props
}) => (
  <svg
    width={50}
    height={51}
    viewBox='0 0 50 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1969_2106)'>
      <path
        d='M39.4565 15.087H35.4913L33.3022 11.7827C32.4761 10.5435 31.0304 9.80005 29.5435 9.80005H20.4565C18.9696 9.80005 17.5239 10.5435 16.6978 11.7827L14.5087 15.087H10.5435C8.02391 15.087 6 17.1109 6 19.6305V35.6566C6 38.1761 8.02391 40.2 10.5435 40.2H39.4565C41.9761 40.2 44 38.1761 44 35.6566V19.6305C44 17.1109 41.9761 15.087 39.4565 15.087ZM25 36.4827C19.3413 36.4827 14.7565 31.8979 14.7565 26.2392C14.7565 20.5805 19.3413 16.037 25 16.037C30.6587 16.037 35.2435 20.6218 35.2435 26.2805C35.2435 31.8979 30.6587 36.4827 25 36.4827ZM38.8783 21.4892C38.837 21.4892 38.7957 21.4892 38.713 21.4892H37.0609C36.3174 21.4479 35.7391 20.8283 35.7804 20.0848C35.8217 19.3827 36.3587 18.8457 37.0609 18.8044H38.713C39.4565 18.7631 40.0761 19.3414 40.1174 20.0848C40.1587 20.8283 39.6217 21.4479 38.8783 21.4892Z'
        fill={color}
      />
      <path
        d='M25.0008 20.5804C21.8617 20.5804 19.3008 23.1413 19.3008 26.2804C19.3008 29.4196 21.8617 31.9391 25.0008 31.9391C28.1399 31.9391 30.7008 29.3783 30.7008 26.2391C30.7008 23.1 28.1399 20.5804 25.0008 20.5804Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1969_2106'>
        <rect width={38} height={32} fill='white' transform='translate(6 9)' />
      </clipPath>
    </defs>
  </svg>
);
export default PhotographerSVG;
