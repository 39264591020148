import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Facebook from '@/assets/svg/Facebook';
import Instagram from '@/assets/svg/Instagram';
import Selected from '@/assets/svg/Selected';
import { tagIcons } from '@/assets/svg/tagIcons';
import Tiktok from '@/assets/svg/Tiktok';
import Twitter from '@/assets/svg/Twitter';
import Youtube from '@/assets/svg/Youtube';
import { ICard } from '@/utils/types/storeStateInterface';

import { Typography } from './Typography';

const Card = ({
  imgUrl,
  title,
  tags,
  // type = 'theme',
  cardData,
  socialLinks,
  handleSelect,
  isSelected,
  _id,
}: ICard) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const getSubtitle = () => {
    if (!cardData?.subtitle) return '';
    return isExpanded
      ? cardData.subtitle
      : `${cardData.subtitle.slice(0, 70)}...`;
  };

  // const expandableContent = () => {
  //   return (
  //     isExpanded && (
  //       <div>
  //         <Typography className='text-paragraphText mt-2'>
  //           When an unknown printer took a galley of type and scrambled it to
  //           make a type specimen book. It was popularised in the 1960s with the
  //           release of Letraset sheets containing Lorem Ipsum passages,
  //         </Typography>
  //         <div className='mt-2 mb-4 px-4'>
  //           <Typography className='font-semibold text-black !text-lg'>
  //             Services
  //           </Typography>
  //           <ul className='list-disc pl-5'>
  //             <li className='text-paragraphText'>
  //               Lorem Ipsum is simply dummy text of the printing.
  //             </li>
  //             <li className='text-paragraphText'>
  //               When an unknown printer took a galley of type and scrambled.
  //             </li>
  //             <li>Lorem Ipsum has been the industry standard.</li>
  //           </ul>
  //         </div>
  //       </div>
  //     )
  //   );
  // };

  const renderCards = () => {
    // switch (type) {
    // case 'venue':
    //   return (
    //     <div className='w-full flex flex-col justify-between my-3'>
    //       <div>
    //         <Typography className='text-[#22211F] text-lg md:text-2xl lg:!text-[34px] font-semibold leading-4 md:!leading-[44px]'>
    //           {title}
    //         </Typography>
    //         <div className='flex gap-2 md:gap-3 mt-2 md:mt-0 flex-wrap items-center'>
    //           {tags?.map((tag: string, idx: number) => {
    //             const selectedIcon = tagIcons?.find(
    //               (d) => d.name.toLowerCase() === tag.toLowerCase(),
    //             );
    //             return (
    //               <div
    //                 className='text-paragraphText md:text-base text-[10px] flex items-center gap-2'
    //                 key={idx}
    //               >
    //                 {selectedIcon
    //                   ? React.cloneElement(selectedIcon.icon, {
    //                       className: 'w-3 h-3 md:w-6 md:h-6',
    //                     })
    //                   : null}
    //                 {tag}
    //               </div>
    //             );
    //           })}
    //         </div>
    //         {/* {isExpanded && (
    //           <button
    //             className='border py-[15px] px-[30px] leading-5 w-[98%] mt-4 border-voiletColor text-voiletColor rounded-[100px]'
    //             onClick={() => handleSelect(_id)}
    //           >
    //             Select
    //           </button>
    //         )} */}
    //       </div>
    //       {/* <div className='flex justify-between items-center mt-3 md:mt-0 mb-2 mr-4'>
    //         <div className='flex gap-2'>
    //           <div className='md:p-3 p-2 bg-[#89B9A580] rounded-[4px] text-[9px]'>
    //             {cardData?.rating}
    //           </div>
    //           <div>
    //             <Typography
    //               variant='subheading'
    //               className='text-textDarkGray font-semibold text-[9px]'
    //             >
    //               {cardData?.review}
    //             </Typography>
    //             <Typography className='md:!text-sm text-inputTxtColor block text-[9px]'>
    //               {cardData?.reviewCount} reviews
    //             </Typography>
    //           </div>
    //         </div>
    //         <Typography className='md:!text-[28px] md:leading-[34px] font-semibold text-[9px]'>
    //           $$$$
    //         </Typography>
    //       </div> */}
    //     </div>
    //   );
    // case 'decor':
    //   return (
    //     <div className='w-full flex flex-col justify-between my-3'>
    //       <div>
    //         <Typography className='text-[#22211F] text-base md:text-2xl lg:!text-[34px] font-semibold leading-4 lg:!leading-[40px]'>
    //           {title}
    //         </Typography>
    //         <Typography
    //           className={`text-paragraphText md:text-sm lg:text-base ${isExpanded ? 'h-44' : ''}  overflow-auto mt-1 text-[10px]`}
    //         >
    //           {getSubtitle()}
    //           {/* {expandableContent()} */}
    //         </Typography>
    //         <div className='flex items-center md:my-2'>
    //           {socialLinks?.map((link: { domain: string; url: string }) => {
    //             switch (link.domain) {
    //               case 'facebook':
    //                 return (
    //                   <Link to={link.url}>
    //                     <Facebook className='' />
    //                   </Link>
    //                 );
    //               case 'twitter':
    //                 return (
    //                   <Link to={link.url}>
    //                     <Twitter />
    //                   </Link>
    //                 );
    //               case 'instagram':
    //                 return (
    //                   <Link to={link.url}>
    //                     <Instagram />
    //                   </Link>
    //                 );
    //               case 'tiktok':
    //                 return (
    //                   <Link to={link.url}>
    //                     <Tiktok />
    //                   </Link>
    //                 );
    //               case 'youtube':
    //                 return (
    //                   <Link to={link.url}>
    //                     <Youtube />
    //                   </Link>
    //                 );
    //             }
    //             return;
    //           })}
    //         </div>
    //       </div>
    //       {/* <div className='flex justify-between items-center mb-2 mr-4'>
    //         <div className='flex gap-2'>
    //           <div className='p-3 bg-[#89B9A580] rounded-[4px]'>
    //             {cardData?.rating}
    //           </div>
    //           <div>
    //             <Typography
    //               variant='subheading'
    //               className='text-textDarkGray font-semibold'
    //             >
    //               {cardData?.review}
    //             </Typography>
    //             <Typography className='!text-sm text-inputTxtColor block'>
    //               {cardData?.reviewCount} reviews
    //             </Typography>
    //           </div>
    //         </div>
    //         <Typography className='!text-[28px] leading-[34px] font-semibold'>
    //           $$$$
    //         </Typography>
    //       </div> */}
    //       {isExpanded && (
    //         <button
    //           className='border py-1 md:py-2 lg:py-[15px] px-3 lg:px-[30px] leading-5 w-[98%] mt-4 border-voiletColor text-voiletColor text-sm md:text-base rounded-[100px]'
    //           onClick={() => handleSelect(_id)}
    //         >
    //           {!isSelected ? 'Select' : 'Unselect'}
    //         </button>
    //       )}
    //     </div>
    //   );
    //   default:
    //     return (
    //       <div className='w-full my-3'>
    //         <Typography className='text-[#22211F] text-lg md:text-2xl lg:!text-[34px] font-semibold leading-4 md:!leading-[44px]'>
    //           {title}
    //         </Typography>
    //         <div className='flex flex-wrap gap-2 md:gap-3 items-center mt-2'>
    //           {tags.map((tag: string, idx: number) => {
    //             const selectedIcon = tagIcons?.find(
    //               (d) => d.name.toLowerCase() === tag.toLowerCase(),
    //             );
    //             return (
    //               <div
    //                 className='text-paragraphText lg:text-base text-[10px] flex items-center gap-2'
    //                 key={idx}
    //               >
    //                 {selectedIcon
    //                   ? React.cloneElement(selectedIcon.icon, {
    //                       className: 'w-3 h-3 md:w-6 md:h-6',
    //                     })
    //                   : null}
    //                 {tag}
    //               </div>
    //             );
    //           })}
    //         </div>
    //         {/* {isExpanded && (
    //           <button
    //             className='border py-[15px] px-[30px] leading-5 w-[98%] mt-4 border-voiletColor text-voiletColor rounded-[100px]'
    //             onClick={() => handleSelect(_id)}
    //           >
    //             Select
    //           </button>
    //         )} */}
    //       </div>
    //     );
    // }
    // };
    return (
      <div className='w-full flex flex-col justify-between my-3'>
        <div>
          <Typography className='text-[#22211F] text-base md:text-2xl lg:!text-[34px] font-semibold leading-4 lg:!leading-[40px]'>
            {title}
          </Typography>
          <Typography
            className={`text-paragraphText md:text-sm lg:text-base ${isExpanded ? 'h-44' : ''}  overflow-auto mt-1 text-[10px]`}
          >
            {getSubtitle()}
            <div className='flex gap-2 md:gap-3 mt-2 md:mt-0 flex-wrap items-center'>
              {tags?.map((tag: string, idx: number) => {
                const selectedIcon = tagIcons?.find(
                  (d) => d.name.toLowerCase() === tag.toLowerCase(),
                );
                return (
                  <div
                    className='text-paragraphText md:text-base text-[10px] flex items-center gap-2'
                    key={idx}
                  >
                    {selectedIcon
                      ? React.cloneElement(selectedIcon.icon, {
                          className: 'w-3 h-3 md:w-6 md:h-6',
                        })
                      : null}
                    {tag}
                  </div>
                );
              })}
            </div>
          </Typography>
          <div className='flex items-center md:my-2'>
            {socialLinks?.map((link: { domain: string; url: string }) => {
              switch (link.domain) {
                case 'facebook':
                  return (
                    <Link to={link.url}>
                      <Facebook className='' />
                    </Link>
                  );
                case 'twitter':
                  return (
                    <Link to={link.url}>
                      <Twitter />
                    </Link>
                  );
                case 'instagram':
                  return (
                    <Link to={link.url}>
                      <Instagram />
                    </Link>
                  );
                case 'tiktok':
                  return (
                    <Link to={link.url}>
                      <Tiktok />
                    </Link>
                  );
                case 'youtube':
                  return (
                    <Link to={link.url}>
                      <Youtube />
                    </Link>
                  );
              }
              return;
            })}
          </div>
        </div>

        {/* <div className='flex justify-between items-center mb-2 mr-4'>
          <div className='flex gap-2'>
            <div className='p-3 bg-[#89B9A580] rounded-[4px]'>
              {cardData?.rating}
            </div>
            <div>
              <Typography
                variant='subheading'
                className='text-textDarkGray font-semibold'
              >
                {cardData?.review}
              </Typography>
              <Typography className='!text-sm text-inputTxtColor block'>
                {cardData?.reviewCount} reviews
              </Typography>
            </div>
          </div>
          <Typography className='!text-[28px] leading-[34px] font-semibold'>
            $$$$
          </Typography>
        </div> */}
        {isExpanded && (
          <button
            className='border py-1 md:py-2 lg:py-[15px] px-3 lg:px-[30px] leading-5 w-[98%] mt-4 border-voiletColor text-voiletColor text-sm md:text-base rounded-[100px]'
            onClick={() => handleSelect(_id)}
          >
            {!isSelected ? 'Select' : 'Unselect'}
          </button>
        )}
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        // if (type !== 'decor') {
        //   handleSelect(_id);
        // }
        // if (type === 'decor') {
        toggleExpand();
        // }
      }}
      style={{
        backgroundColor: isSelected ? '#FAF8FF' : 'white',
        border: isSelected ? '2px solid #6B759D' : '2px solid #f9f9f9',
      }}
      className='cursor-pointer flex gap-5 my-3 CardShadow h-fit rounded-xl w-full'
    >
      <div
        className={`w-[200px] md:w-[275px] ${isExpanded ? '' : 'h-[125px] md:h-[175px] lg:h-[250px]'}`}
      >
        <img
          src={imgUrl}
          alt=''
          className='rounded-tl-xl rounded-bl-xl h-full w-full'
        />
      </div>

      {renderCards()}
      <div className='w-7 md:w-10'>
        <Selected
          className={`m-2 md:w-6 md:h-6 h-4 w-4 ${isSelected ? 'block' : 'hidden'}`}
        />
      </div>
    </div>
  );
};

export default Card;
