/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Form, Formik } from 'formik';
import { Minus, Plus } from 'lucide-react';

import Button from '@/components/ui/button';
import SelectInputCombo from '@/components/ui/Dropdown';
import TextInputCombo from '@/components/ui/Input';
import TextAreaCombo from '@/components/ui/TextArea';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

import getValidationSchema from '../validation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StepOneForm = ({ initialValues, onNext }: any) => {
  const { t } = useTranslation();
  const { socialMediaOptions } = useConstantData();

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const schema = getValidationSchema(values || '', t);
        try {
          schema.validateSync(values, { abortEarly: false });
        } catch (err) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (err as any).inner.reduce((acc: any, validationError: any) => {
            acc[validationError.path] = validationError.message;
            return acc;
          }, {});
        }
      }}
      onSubmit={(values) => onNext(values)}
    >
      {({ isSubmitting, values }) => (
        <Form className='flex flex-col gap-2.5 sm:w-[500px] w-full'>
          <div className='flex flex-col w-full gap-2 md:flex-row'>
            <TextInputCombo
              name='firstName'
              placeholder={t('vendorSignUpQuestionnaire.firstNamePlaceholder')}
              type='text'
              label={t('vendorSignUpQuestionnaire.firstNameLabel')}
            />
            <TextInputCombo
              name='lastName'
              placeholder={t('vendorSignUpQuestionnaire.lastNamePlaceholder')}
              type='text'
              label={t('vendorSignUpQuestionnaire.lastNameLabel')}
            />
          </div>
          <TextInputCombo
            name='email'
            placeholder={t('vendorSignUpQuestionnaire.emailPlaceholder')}
            type='email'
            label={t('vendorSignUpQuestionnaire.emailLabel')}
          />
          <TextInputCombo
            name='password'
            type='password'
            placeholder={t('vendorSignUpQuestionnaire.passwordPlaceholder')}
            label={t('vendorSignUpQuestionnaire.passwordLabel')}
          />

          <TextInputCombo
            name='confirmPassword'
            type='password'
            placeholder={t('register.confirmPasswordPlaceholder')}
            label={t('register.confirmPasswordLabel')}
          />
          <TextInputCombo
            name='location'
            placeholder={t('vendorSignUpQuestionnaire.locationPlaceholder')}
            type='text'
            label={t('vendorSignUpQuestionnaire.locationLabel')}
          />

          <TextInputCombo
            name='website'
            placeholder={t('vendorSignUpQuestionnaire.websitePlaceholder')}
            type='text'
            label={t('vendorSignUpQuestionnaire.websiteLabel')}
          />

          <FieldArray
            name='socialMedia'
            render={({ push, remove }) => (
              <>
                {values.socialMedia.map((_: any, index: number) => (
                  <div
                    key={index}
                    className='block sm:flex flex-col sm:flex-row w-full h-auto sm:gap-2'
                  >
                    <TextInputCombo
                      name={`socialMedia.${index}.url`}
                      type='text'
                      inputStyle='w-full sm:w-[180px]'
                      placeholder={t(
                        'vendorSignUpQuestionnaire.webUrlPlaceholder',
                      )}
                      label={t('vendorSignUpQuestionnaire.webUrl')}
                    />
                    <SelectInputCombo
                      name={`socialMedia.${index}.platform`}
                      inputStyle='!w-full sm:w-[180px]'
                      containerStyle='sm:mt-6'
                      label=''
                      options={socialMediaOptions}
                      placeholder={t(
                        'vendorSignUpQuestionnaire.socialMediaPlaceholder',
                      )}
                    />
                    {index === 0 ? (
                      <Button
                        type='button'
                        variant='filled'
                        size='small'
                        iconRight={<Plus size={12} />}
                        className='text-white bg-brandGreen w-20 h-12 mt-3 sm:mt-8'
                        iconClassName='ml-0.5'
                        onClick={() => push({ url: '', platform: '' })}
                      >
                        {t('Constant.add')}
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        variant='filled'
                        size='small'
                        iconRight={<Minus size={12} />}
                        className='text-white bg-redColor/70 w-20 h-12 mt-3 sm:mt-8'
                        iconClassName='ml-0.5'
                        onClick={() => remove(index)}
                      >
                        {t('Constant.remove')}
                      </Button>
                    )}
                  </div>
                ))}
              </>
            )}
          />

          <TextAreaCombo
            label={t('vendorSignUpQuestionnaire.descriptionLabel')}
            name={'description'}
            placeholder={t('vendorSignUpQuestionnaire.descriptionPlaceholder')}
            rows={4}
          />
          <TextInputCombo
            name='title'
            placeholder={t('vendorSignUpQuestionnaire.titlePlaceholder')}
            type='text'
            label={t('vendorSignUpQuestionnaire.titleLabel')}
          />
          <TextInputCombo
            name='phone'
            placeholder={t('vendorSignUpQuestionnaire.phonePlaceholder')}
            type='number'
            label={t('vendorSignUpQuestionnaire.phoneLabel')}
            isPhone
          />
          <TextInputCombo
            name='business'
            placeholder={t('vendorSignUpQuestionnaire.businessNamePlaceholder')}
            type='text'
            label={t('vendorSignUpQuestionnaire.businessNameLabel')}
          />
          <Button
            variant='filled'
            size='medium'
            type='submit'
            className='w-full mt-4 text-white bg-brandGreen'
          >
            {isSubmitting
              ? t('register.signUpLoadingBtn')
              : t('register.nextStepBtn')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default StepOneForm;
