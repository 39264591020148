/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import GoldenStar from '@/assets/svg/GoldenStar';
import PurpleStar from '@/assets/svg/PurpleStar';
import SaveProgressButton from '@/components/SaveProgressButton';
import { Typography } from '@/components/Typography';
import {
  PlannerHeroSectionData,
  TopFourData,
} from '@/constants/plannerSection';
import { getEvents } from '@/services/planner';

const PlannerHero = ({
  length,
  route,
  apiRoute,
  selectedValue,
  setSelectedValue,
  setGuestCount,
  guest,
  invitation,
  partyFavour,
  setPartyFavour,
}: {
  length: number;
  route: string;
  apiRoute: string;
  selectedValue?: any;
  setSelectedValue?: any;
  partyFavour?: string[];
  setGuestCount?: any;
  guest?: boolean;
  invitation?: boolean;
  setPartyFavour?: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('eventId');
  const [topHeroValues, setTopHeroValues] = useState<any>([]);
  const [HeroValues, setHeroValues] = useState<any>([]);

  const getAllEvents = async () => {
    if (id) {
      try {
        const { data }: any = await getEvents(id);
        if (data) {
          setTopHeroValues([
            data?.eventType,
            data?.location,
            moment(data?.eventDate).format('MMMM D, YYYY'),
            `${moment(data?.time?.start, 'HH:mm').format('h A')} - ${moment(data?.time?.end, 'HH:mm').format('h A')}`,
          ]);
          setHeroValues([
            data?.approxGuestCount,
            data?.theme[0]?.title,
            data?.venue?.businessName,
            data?.decor[0]?.businessName,
            data?.partyFavors[0]?.businessName,
            data?.food[0]?.businessName,
            data?.bakery[0]?.businessName,
            data?.drink[0]?.businessName,
            data?.photographer[0]?.businessName,
            data?.entertainment[0]?.businessName,
          ]);
          if (setSelectedValue) {
            const fieldName =
              apiRoute === 'guest'
                ? 'guestList'
                : apiRoute === 'party-favors'
                  ? 'partyFavors'
                  : apiRoute;

            const selectedData =
              apiRoute === 'venue'
                ? data?.venue?._id
                : apiRoute === 'invitation'
                  ? data?.invitationCard
                  : data?.[fieldName]?.map((res: any) => res?._id);

            setSelectedValue(selectedData);
            if (setPartyFavour) {
              setPartyFavour(data?.partyFavors?.map((res: any) => res?._id));
            }
          }
        }
        if (setGuestCount) {
          setGuestCount(data?.approxGuestCount);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <div className='bg-cream relative py-5 md:py-24 w-full h-full'>
      <GoldenStar className='absolute left-60 md:block hidden' />
      <GoldenStar className='absolute right-60 md:block hidden' />
      <GoldenStar className='absolute left-96 bottom-32 md:block hidden w-4' />
      <GoldenStar className='absolute right-96 bottom-32 md:block hidden w-4' />
      <PurpleStar className='absolute left-10 top-64 md:block hidden w-4' />
      <div className='text-center mx-auto'>
        <Typography variant='heading'>Event Info</Typography>
        <div
          className={`flex flex-wrap justify-center mx-auto md:w-[60%] gap-[10px] my-5 mb-3 ${length ? ' border-b' : ''} border-[#EBEBEB] pb-5`}
        >
          {TopFourData?.map((section, idx) => (
            <div
              onClick={() => navigate(`${section?.route}?eventId=${id}`)}
              key={section.title}
              className='bg-white cursor-pointer rounded-md py-3 px-5 text-sm md:text-lg'
            >
              <span className='text-astrogranite font-medium'>
                {section.title}:
              </span>
              <span className='text-textDarkGray font-semibold ml-2'>
                {topHeroValues[idx]}
              </span>
            </div>
          ))}
        </div>

        {length ? (
          <div className='flex flex-wrap gap-[10px] justify-center max-w-[75%] mx-auto my-5 mb-5'>
            {PlannerHeroSectionData?.slice(0, length)?.map((section, idx) => (
              <div
                onClick={() => navigate(`${section?.route}?eventId=${id}`)}
                key={section.title}
                className='bg-white cursor-pointer w-fit rounded-md py-3 px-5 text-sm md:text-lg'
              >
                <span className='text-astrogranite font-medium'>
                  {section.title}:
                </span>
                <span className='text-textDarkGray font-semibold ml-2'>
                  {HeroValues[idx] ? HeroValues[idx] : '-'}
                </span>
              </div>
            ))}
          </div>
        ) : null}
        <SaveProgressButton
          route={route}
          apiRoute={apiRoute}
          id={id}
          selectedValue={selectedValue}
          partyFavour={partyFavour}
          invitation={invitation}
          guest={guest}
        />
      </div>
    </div>
  );
};

export default PlannerHero;
