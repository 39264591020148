import * as React from 'react';

import { SVGComponentProps } from '../Venu';

const Spring: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='22.163' rx='2.93333' fill={color} />
    <path
      d='M7.61034 16.2777C7.20883 16.0118 6.93058 15.6188 6.82361 15.191C6.78951 15.053 6.78254 15.0708 6.8988 14.9887C7.37237 14.6538 8.03045 14.5577 8.65441 14.7895C9.32408 15.0375 9.7775 15.5971 9.88138 16.2102C9.5101 16.5187 8.99932 16.6652 8.47613 16.5985'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.9336 16.2102C13.4118 16.6071 14.1211 16.7357 14.7907 16.4877C15.4147 16.2559 15.8511 15.7537 15.9914 15.191C16.0263 15.053 16.0324 15.0708 15.9162 14.9887C15.4426 14.6538 14.7845 14.5577 14.1606 14.7895C13.4909 15.0375 13.0375 15.5971 12.9336 16.2102Z'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00077 13.8655C9.40381 13.9043 9.83399 13.7152 10.1045 13.3361C10.3564 12.9835 10.4091 12.5525 10.2789 12.1975C10.2471 12.1099 10.2572 12.1177 10.165 12.1154C9.78671 12.1076 9.39608 12.2967 9.14339 12.6494C8.87288 13.0284 8.83258 13.4966 9.00077 13.8655Z'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.8142 13.8655C13.4103 13.9043 12.9809 13.7152 12.7096 13.3361C12.4577 12.9835 12.4058 12.5525 12.5353 12.1975C12.567 12.1099 12.5569 12.1177 12.65 12.1154C13.0282 12.1076 13.4188 12.2967 13.6708 12.6494C13.9413 13.0284 13.9816 13.4966 13.8142 13.8655Z'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.73313 10.6682C5.96722 10.4031 6.57023 10.545 7.1345 10.979C7.1407 10.2675 7.39959 9.70479 7.75304 9.68384C8.14058 9.65983 8.49482 10.2969 8.54442 11.1062C8.61959 12.3409 7.74374 13.3307 6.50901 13.4059C5.6998 13.4547 5.02468 13.1803 5.00065 12.7928C4.97896 12.4393 5.50601 12.1138 6.21137 12.0215C5.71221 11.5138 5.49827 10.9333 5.73313 10.6682Z'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.4977 13.4113C17.2193 13.4097 17.7921 13.1492 17.8138 12.7927C17.8355 12.4393 17.3092 12.1137 16.6031 12.0215C17.1022 11.5138 17.3162 10.9333 17.0821 10.6682C16.8472 10.4031 16.245 10.5449 15.6807 10.979C15.6745 10.2675 15.4149 9.70473 15.0622 9.68381C14.6739 9.65977 14.3196 10.2969 14.27 11.1061C14.2111 12.0843 14.7483 12.9082 15.5885 13.2462'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.01855 12.6904C9.29127 14.0236 9.99195 15.1328 9.99195 17.5898'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.7974 12.6904C13.5247 14.0236 12.8232 15.1328 12.8232 17.5898'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.4131 10.4667V17.5898'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.4126 4.00001C11.8118 4.01166 12.042 4.66892 12.1273 4.97665C12.2226 5.3208 12.342 5.22313 12.5978 5.0805C12.859 4.93479 13.4364 4.6984 13.6992 4.94641C13.9666 5.2301 13.6837 5.83935 13.5286 6.11216C13.3574 6.41291 13.4186 6.45321 13.7348 6.54003C14.0309 6.62141 14.6355 6.85703 14.6463 7.23295C14.6355 7.61664 14.0046 7.85537 13.7046 7.93751C13.3744 8.02745 13.386 8.10417 13.5465 8.38554C13.6976 8.65138 13.9565 9.24744 13.6992 9.51949C13.417 9.78612 12.8078 9.50478 12.5342 9.34974C12.2389 9.1831 12.1908 9.25209 12.1063 9.55747C12.025 9.85201 11.787 10.4558 11.4126 10.4667C11.0228 10.455 10.7949 9.82489 10.7096 9.5226C10.6011 9.14048 10.481 9.24977 10.1965 9.40325C9.93918 9.54199 9.38265 9.76132 9.12688 9.51949C8.86954 9.24665 9.12841 8.65217 9.27956 8.38554C9.46714 8.05534 9.40201 8.01348 9.05633 7.9166C8.76722 7.83522 8.18978 7.59725 8.17969 7.23295C8.19054 6.84696 8.82068 6.61134 9.1222 6.52917C9.51209 6.42297 9.39736 6.30362 9.24235 6.01683C9.10437 5.76027 8.88502 5.20221 9.12688 4.94641C9.40513 4.68366 10.0213 4.96038 10.2911 5.11539C10.5949 5.29056 10.6313 5.2208 10.7181 4.9069C10.7995 4.61312 11.0406 4.01087 11.4126 4.00001Z'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9575 8.35767C10.5133 8.17783 10.2002 7.74224 10.2002 7.23299C10.2002 6.56331 10.7435 6.01997 11.4132 6.01997C12.0837 6.01997 12.627 6.56331 12.627 7.23299C12.627 7.74224 12.3139 8.17783 11.8698 8.35767'
      stroke='white'
      strokeWidth='0.75'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default Spring;
