import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import CheckboxCombo from '@/components/ui/Checkbox';
import SelectInputCombo from '@/components/ui/Dropdown';
import TextInputCombo from '@/components/ui/Input';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EntertainmentServices = ({ values }: any) => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const { entertainmentMusicianOptions, entertainmentOptions } =
    useConstantData();

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };
  return (
    <div className='md:w-[440px]'>
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.options')}
      </h1>
      <SelectInputCombo
        name='entertainmentOptions'
        label=''
        options={entertainmentOptions}
        placeholder='Entertainment'
      />

      {values.entertainmentOptions === 'Musician' && (
        <>
          <h1 className='block text-lg mt-6 leading-6 text-gray-600 font-semibold'>
            {t('vendorSignUpQuestionnaire.entertainment.musiciansOptions')}
          </h1>
          <CheckboxCombo
            name='musiciansOptions'
            options={entertainmentMusicianOptions}
            label=''
          />

          {values.musiciansOptions.length > 0 && (
            <>
              <h1 className='block text-base mt-6 leading-6 text-gray-600 font-medium'>
                {t('vendorSignUpQuestionnaire.entertainment.label')}
              </h1>
              <div className='flex justify-between w-full gap-4'>
                <TextInputCombo
                  label=''
                  name='musiciansHourlyFromRate'
                  placeholder={t('vendorSignUpQuestionnaire.min')}
                  type='number'
                />
                <p className='mx-2.5 mt-5 md:mx-8'>
                  {t('vendorSignUpQuestionnaire.to')}
                </p>
                <TextInputCombo
                  label=''
                  name='musiciansHourlyToRate'
                  placeholder={t('vendorSignUpQuestionnaire.max')}
                  type='number'
                />
              </div>
            </>
          )}
        </>
      )}
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default EntertainmentServices;
