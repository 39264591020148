import React from 'react';

import Footer from '@/components/Footer';
import PlannerHeader from '@/components/PlannerHeader';

import PlannerSection from './modules/PlannerSection';

const PlannerDashboard = () => {
  return (
    <div className='w-full'>
      <PlannerHeader />
      <PlannerSection />
      <Footer />
    </div>
  );
};

export default PlannerDashboard;
