import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Search: React.FC<SVGComponentProps> = ({
  color = '#585C68',
  ...props
}) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.8221 16.7805L13.0933 12.0516C14.2875 10.5836 14.8729 8.71327 14.7289 6.82619C14.5847 4.93924 13.7221 3.17958 12.3188 1.90983C10.9156 0.640209 9.07856 -0.0426098 7.18668 0.00206135C5.29476 0.0469225 3.49209 0.815811 2.15044 2.15046C0.81585 3.49215 0.0469456 5.29488 0.00206365 7.18673C-0.0426325 9.07867 0.640164 10.9156 1.90982 12.3188C3.17961 13.7222 4.93925 14.5848 6.82615 14.729C8.71309 14.873 10.5834 14.2876 12.0515 13.0934L16.7804 17.8223C16.9707 17.9852 17.2305 18.0406 17.4706 17.9697C17.7108 17.8986 17.8987 17.7108 17.9697 17.4706C18.0406 17.2305 17.9852 16.9707 17.8223 16.7804L17.8221 16.7805ZM3.19223 11.5568C1.89209 10.2581 1.26999 8.42853 1.5088 6.60664C1.74745 4.78466 2.82017 3.17722 4.41088 2.25732C6.00181 1.33751 7.92983 1.20983 9.62825 1.91196C11.3267 2.61408 12.6016 4.06597 13.0784 5.84061C13.5552 7.61526 13.1797 9.51082 12.0621 10.9698C10.9446 12.4284 9.21215 13.2844 7.37465 13.2859C5.80569 13.2912 4.29971 12.6685 3.19252 11.5569L3.19223 11.5568ZM8.1132 2.94132C8.1132 3.13726 8.03535 3.32528 7.89681 3.46382C7.75827 3.60236 7.57025 3.68021 7.37432 3.68021C6.39447 3.68021 5.45486 4.06945 4.762 4.76233C4.06914 5.4552 3.67989 6.39481 3.67989 7.37466C3.67989 7.63871 3.53904 7.88264 3.31045 8.01458C3.08186 8.14653 2.80016 8.14653 2.57157 8.01458C2.34298 7.88264 2.20212 7.63871 2.20212 7.37466C2.20212 6.00294 2.74704 4.6873 3.71701 3.71732C4.68697 2.74735 6.00261 2.20243 7.37432 2.20243C7.57025 2.20243 7.75827 2.28028 7.89681 2.41882C8.03535 2.55736 8.1132 2.74539 8.1132 2.94132Z'
      fill={color}
    />
  </svg>
);
export default Search;
