import React from 'react';

import { SummaryCard } from '@/@types';
import ApplePay from '@/assets/svg/ApplePay';
import CreditCard from '@/assets/svg/CreditCard';
import GooglePay from '@/assets/svg/GooglePay';
import Paypal from '@/assets/svg/Paypal';

import SaveProgressButton from './SaveProgressButton';
import VendorCard from './SummaryCard';
import Toggle from './Toggle';
import { Typography } from './Typography';

import 'react-datepicker/dist/react-datepicker.css';

interface VendorSummaryProps {
  vendors: SummaryCard[];
}

const VendorSummary: React.FC<VendorSummaryProps> = ({ vendors }) => {
  const handleToggleChange = (checked: boolean) => {
    console.log('Toggle is now:', checked);
  };
  return (
    <div className='rounded-2xl'>
      <h2 className=' px-4 pb-4 text-2xl font-semibold mb-6'>Vendor Summary</h2>
      <div className='border-b my-5 mb-10'></div>
      {vendors.map((vendor, index) => (
        <VendorCard key={index} {...vendor} />
      ))}
      <div className='flex flex-col justify-end items-end md:items-start lg:items-end'>
        {[
          { title: 'Total Cost', value: '$3,300 Approx' },
          { title: 'Total Paid', value: '$950' },
          { title: 'Total Due', value: '$2350' },
        ].map((data, idx) => (
          <div
            key={idx}
            className='flex lg:w-3/5 xl:w-2/5 2xl:w-1/3 my-1.5 items-center justify-between'
          >
            <Typography className='text-sm text-gray-500'>
              {data.title}
            </Typography>
            <span>
              :{' '}
              <input
                type='text'
                value={data.value}
                className='text-sm font-medium ml-2 border border-gray-200 text-gray-600 rounded-xl px-2 py-2'
              />
            </span>
          </div>
        ))}
        <div className='md:w-full lg:w-3/5 xl:w-2/5 2xl:w-1/3 mt-1'>
          <SaveProgressButton
            text='Make Payment'
            className='w-36 md:w-52 py-2 px-4 ml-14 xs:ml-28 md:ml-0 md:py-2.5 md:px-6'
          />
          <div className='border border-gray-300 rounded-xl p-4 my-6'>
            <div className='flex gap-5'>
              <div className='flex my-1.5 items-center justify-between'>
                <Typography className='text-sm text-gray-500'>To</Typography>
                <span>
                  :{' '}
                  <input
                    type='text'
                    value={''}
                    className='text-sm font-medium w-20 md:w-32 ml-2 border border-gray-200 text-gray-600 rounded-xl px-2 py-2'
                  />
                </span>
              </div>
              <div className='flex my-1.5 items-center justify-between'>
                <Typography className='text-sm text-gray-500'>
                  Amount
                </Typography>
                <span>
                  :{' '}
                  <input
                    type='text'
                    value={''}
                    className='text-sm font-medium w-20 md:w-32 ml-2 border border-gray-200 text-gray-600 rounded-xl px-2 py-2'
                  />
                </span>
              </div>
            </div>
            <div className='flex justify-end mt-3'>
              <button className='px-8 py-2 rounded-3xl whitespace-nowrap bg-brandGreen text-white'>
                Add +
              </button>
            </div>
          </div>
          <div className='border border-gray-300 rounded-xl p-4 my-6'>
            <div className='flex justify-between gap-3'>
              <CreditCard className='w-32 xs:w-40 md:w-fit' />
              <Paypal className='w-32 xs:w-40 md:w-fit' />
            </div>
            <div className='flex justify-between  gap-3 mt-3'>
              <ApplePay className='w-32 xs:w-40 md:w-fit' />
              <GooglePay className='w-32 xs:w-40 md:w-fit' />
            </div>
          </div>
          <Toggle
            label='Reserve now, Pay later?'
            onChange={handleToggleChange}
          />

          <div className='flex gap-5 w-full items-center'>
            <button className='md:px-8 px-6 md:py-4 py-3 w-full text-sm rounded-3xl whitespace-nowrap bg-willowFlower'>
              Klarna
            </button>
            <button className='md:px-8 px-6 md:py-4 py-3 w-full text-sm rounded-3xl whitespace-nowrap bg-willowFlower'>
              Afterpay
            </button>{' '}
            <button className='md:px-8 px-6 md:py-4 py-3 w-full text-sm rounded-3xl whitespace-nowrap bg-willowFlower'>
              Stripe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorSummary;
