/* eslint-disable @typescript-eslint/no-explicit-any */
import { GuestListState } from '@/utils/types/storeStateInterface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: GuestListState = {
  guest: [],
  totalCount: 0,
};

export const guestListSlice = createSlice({
  name: 'guestList',
  initialState,
  reducers: {
    setGuestList: (state, action: PayloadAction<any>) => {
      state.guest = action.payload.guest;
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { setGuestList, setTotalCount } = guestListSlice.actions;
