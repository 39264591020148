import * as React from 'react';

import { SVGComponentProps } from './Venu';
const EquipmentRentalSVG: React.FC<SVGComponentProps> = ({
  color = '#0A0C2C',
  ...props
}) => (
  <svg
    width={50}
    height={51}
    viewBox='0 0 50 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M31.706 4.259L25.25 2.82425V2.375C25.25 2.168 25.082 2 24.875 2C24.668 2 24.5 2.168 24.5 2.375V9.125C24.5 9.332 24.668 9.5 24.875 9.5C25.082 9.5 25.25 9.332 25.25 9.125V7.14575L31.7435 4.98125C31.9033 4.928 32.0083 4.77425 31.9993 4.60625C31.9903 4.4375 31.871 4.29575 31.706 4.259Z'
      fill={color}
    />
    <path d='M43.9927 21.5L30.4102 11.879L40.0319 21.5H43.9927Z' fill={color} />
    <path
      d='M34.25 25.4233L36.9215 28.0947L39.5 25.5155V22.25H34.25V25.4233Z'
      fill={color}
    />
    <path d='M26.3145 9.5L34.08 21.5H38.9707L26.97 9.5H26.3145Z' fill={color} />
    <path d='M23.4355 9.5H22.78L10.7793 21.5H15.67L23.4355 9.5Z' fill={color} />
    <path
      d='M15.5 25.4705V22.25H10.25V25.4697L12.8758 28.0947L15.5 25.4705Z'
      fill={color}
    />
    <path d='M19.23 11.9893L5.80273 21.5H9.71848L19.23 11.9893Z' fill={color} />
    <path
      d='M24.328 9.5L16.5625 21.5H21.5763L24.4007 9.5H24.328Z'
      fill={color}
    />
    <path
      d='M16.25 25.469L18.8757 28.0947L21.5 25.4705V22.25H16.25V25.469Z'
      fill={color}
    />
    <path
      d='M24.9215 21.5H27.4032L24.8757 10.7607L22.3482 21.5H24.8757V22.25H22.25V25.4472L24.8982 28.0947L27.5 25.493V22.25H24.9215V21.5Z'
      fill={color}
    />
    <path d='M8 45.4999V27.1287L6.5 27.9859V45.4999H8Z' fill={color} />
    <path
      d='M9.125 46.25H5.375C5.16875 46.25 5 46.4187 5 46.625V48.125C5 48.3312 5.16875 48.5 5.375 48.5H9.125C9.33125 48.5 9.5 48.3312 9.5 48.125V46.625C9.5 46.4187 9.33125 46.25 9.125 46.25Z'
      fill={color}
    />
    <path d='M9.49925 22.25H5V27.9792L9.49925 25.4067V22.25Z' fill={color} />
    <path
      d='M13.1405 28.8905C12.9995 29.0315 12.7505 29.0315 12.6103 28.8905L9.81275 26.0938L8.75 26.6997V37.238C16.3212 37.0093 18.1993 29.9705 18.4678 28.7472L15.8743 26.1545L13.1405 28.8905Z'
      fill={color}
    />
    <path
      d='M40.25 25.3805L44.7965 27.9792V22.25H40.25V25.3805Z'
      fill={color}
    />
    <path d='M41.75 27.1025V45.5H43.25V27.9598L41.75 27.1025Z' fill={color} />
    <path
      d='M25.4204 9.5H25.3477L28.1722 21.5H33.1859L25.4204 9.5Z'
      fill={color}
    />
    <path
      d='M44.375 46.25H40.625C40.4187 46.25 40.25 46.4187 40.25 46.625V48.125C40.25 48.3312 40.4187 48.5 40.625 48.5H44.375C44.5813 48.5 44.75 48.3312 44.75 48.125V46.625C44.75 46.4187 44.5813 46.25 44.375 46.25Z'
      fill={color}
    />
    <path
      d='M33.5 22.25H28.25V25.4233L30.9215 28.0947L33.5 25.5155V22.25Z'
      fill={color}
    />
    <path
      d='M41.0001 26.6743L39.9838 26.093L37.1863 28.8898C37.1128 28.9633 37.0168 28.9993 36.9208 28.9993C36.8248 28.9993 36.7288 28.9625 36.6553 28.8898L33.9208 26.1553L31.2891 28.787C31.5771 30.1438 33.4153 37.0153 41.0001 37.2388V26.6743Z'
      fill={color}
    />
  </svg>
);
export default EquipmentRentalSVG;
