import React from 'react';
import { useTranslation } from 'react-i18next';

const AdvertisingCard = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col md:flex-row items-center text-center justify-between p-12 bg-white rounded-lg shadow-lg border w-[80%] mx-auto my-6 md:my-12 space-y-8 md:space-y-0'>
      <div className='text-center md:text-left'>
        <h2 className='text-2xl md:text-4xl font-bold text-darkHeadingColor font-sans ml-0 lg:ml-8'>
          {t('advertisingBanner.heading')}
        </h2>
        <p className='text-paragraphText font-sans text-xl md:text-2xl mt-6 ml-0 lg:ml-8'>
          {t('advertisingBanner.subHeading')}
        </p>
      </div>
      <button className='flex items-center text-base md:text-xl justify-center px-6 w-44 md:w-60 py-3 bg-voiletColor text-white font-semibold rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 transition duration-200 ease-in-out'>
        {t('advertisingBanner.btnTxt')}
        <span className='ml-2 text-base sm:text-lg md:text-xl'>&rarr;</span>
      </button>
    </div>
  );
};

export default AdvertisingCard;
