import { useTranslation } from 'react-i18next';

import FB_Icon from '@/assets/png/Fb_Footer.png';
import Insta_Icon from '@/assets/png/Insta_Footer.png';
import Tiktok_Icon from '@/assets/png/Tiktok_Footer.png';
import Twitter_Icon from '@/assets/png/Twitter_Footer.png';
import Youtube_Icon from '@/assets/png/Youtube_Footer.png';
import BakerySVG from '@/assets/svg/Bakery';
import BarTenderSvg from '@/assets/svg/BarTender';
import DecorSvg from '@/assets/svg/Decor';
import EntertainmentSVG from '@/assets/svg/Entertainment';
import EquipmentRentalSVG from '@/assets/svg/EquipmentRental';
import FoodServiceSVG from '@/assets/svg/FoodService';
import PartySVG from '@/assets/svg/Party';
import PhotographerSVG from '@/assets/svg/Photographer';
import VenuSvg from '@/assets/svg/Venu';

export const useConstantData = () => {
  const { t } = useTranslation();

  return {
    footerLinks: [
      {
        title: t('Constant.footer.company.title'),
        links: [
          {
            name: t('Constant.footer.company.about'),
            url: '#',
          },
          {
            name: t('Constant.footer.company.listYourCompany'),
            url: '#',
          },
          {
            name: t('Constant.footer.company.advertising'),
            url: '#',
          },
          {
            name: t('Constant.footer.company.feedback'),
            url: '#',
          },
        ],
      },
      {
        title: t('Constant.footer.policies.title'),
        links: [
          {
            name: t('Constant.footer.policies.privacy'),
            url: '#',
          },
          {
            name: t('Constant.footer.policies.termsOfUse'),
            url: '#',
          },
          {
            name: t('Constant.footer.policies.accessibility'),
            url: '#',
          },
          {
            name: t('Constant.footer.policies.yourPrivacyChoices'),
            url: '#',
          },
        ],
      },
      {
        title: t('Constant.footer.help.title'),
        links: [
          { name: t('Constant.footer.help.faq'), url: '#' },
          { name: t('Constant.footer.help.recommended'), url: '#' },
          { name: t('Constant.footer.help.timelines'), url: '#' },
          { name: t('Constant.footer.help.inspiration'), url: '#' },
          { name: t('Constant.footer.help.eventAssistance'), url: '#' },
        ],
      },
    ],
    socialIcons: [
      {
        src: FB_Icon,
        alt: t('Constant.social.facebookAlt'),
      },
      {
        src: Twitter_Icon,
        alt: t('Constant.social.twitterAlt'),
      },
      {
        src: Insta_Icon,
        alt: t('Constant.social.instagramAlt'),
      },
      {
        src: Tiktok_Icon,
        alt: t('Constant.social.tiktokAlt'),
      },
      {
        src: Youtube_Icon,
        alt: t('Constant.social.youtubeAlt'),
      },
    ],
    dashboardNavigationOptions: [
      {
        label: 'Profile',
        url: '#',
      },
      {
        label: 'Notifications',
        url: '#',
      },
      {
        label: 'Messages',
        url: '#',
      },
      {
        label: 'Calendar',
        url: '#',
      },
      {
        label: 'Listings',
        url: '#',
      },
      {
        label: 'Bookings',
        url: '#',
      },
      {
        label: 'Reviews',
        url: '#',
      },
      {
        label: 'Financials',
        url: '#',
      },
      {
        label: 'Settings',
        url: '#',
      },
    ],
    dashboardUpcomingBooking: [
      { date: '7/28/24', service: 'DJ Services - 3 hours', price: '$450' },
      { date: '7/31/24', service: 'DJ Services - 4 hours', price: '$600' },
      { date: '8/2/24', service: 'DJ Services - 6 hours', price: '$900' },
      { date: '8/5/24', service: 'DJ Services - 3 hours', price: '$450' },
    ],
    jobCards: [
      {
        id: 1,
        icon: (color: string) => <VenuSvg color={color} />,
        title: t('Constant.jobCards.venue.title'),
        label: 'Venue',
        description: t('Constant.jobCards.venue.description'),
      },
      {
        id: 2,
        icon: (color: string) => <DecorSvg color={color} />,
        title: t('Constant.jobCards.decor.title'),
        label: 'Decor',
        description: t('Constant.jobCards.decor.description'),
      },
      {
        id: 3,
        icon: (color: string) => <FoodServiceSVG color={color} />,
        title: t('Constant.jobCards.foodService.title'),
        label: 'Food Service',
        description: t('Constant.jobCards.foodService.description'),
      },
      {
        id: 4,
        icon: (color: string) => <BarTenderSvg color={color} />,
        title: t('Constant.jobCards.bartender.title'),
        label: 'Drink Services',
        description: t('Constant.jobCards.bartender.description'),
      },
      {
        id: 5,
        icon: (color: string) => <PhotographerSVG color={color} />,
        title: t('Constant.jobCards.photographer.title'),
        label: 'Photographer/Videographer',
        description: t('Constant.jobCards.photographer.description'),
      },
      {
        id: 6,
        icon: (color: string) => <BakerySVG color={color} />,
        title: t('Constant.jobCards.bakery.title'),
        label: 'Bakery/Treats',
        description: t('Constant.jobCards.bakery.description'),
      },
      {
        id: 7,
        icon: (color: string) => <EntertainmentSVG color={color} />,
        title: t('Constant.jobCards.entertainment.title'),
        lable: 'Entertainment',
        description: t('Constant.jobCards.entertainment.description'),
      },
      {
        id: 8,
        icon: (color: string) => <EquipmentRentalSVG color={color} />,
        title: t('Constant.jobCards.equipmentRental.title'),
        label: 'Equipment Rentals',
        description: t('Constant.jobCards.equipmentRental.description'),
      },
      {
        id: 9,
        icon: (color: string) => <PartySVG color={color} />,
        title: t('Constant.jobCards.partyFavor.title'),
        label: 'Party Favors',
        description: t('Constant.jobCards.partyFavor.description'),
      },
    ],
    radioInternetOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    radioOutsideCateringOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    radioOutsideAlcoholOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    radioSmokingAllowedOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    provideAlcoholOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    servingAlcoholOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    venuTypeOptions: [
      {
        label: t('Constant.venueTypeDropdownItems.ballroom'),
        value: 'ballroom',
      },
      { label: t('Constant.venueTypeDropdownItems.barn'), value: 'barn' },
      {
        label: t('Constant.venueTypeDropdownItems.conventionCenter'),
        value: 'conventionCenter',
      },
      {
        label: t('Constant.venueTypeDropdownItems.garden'),
        value: 'garden',
      },
      {
        label: t('Constant.venueTypeDropdownItems.houseEstate'),
        value: 'houseEstate',
      },
      { label: t('Constant.venueTypeDropdownItems.hotel'), value: 'hotel' },
      { label: t('Constant.venueTypeDropdownItems.museum'), value: 'museum' },
      { label: t('Constant.venueTypeDropdownItems.park'), value: 'park' },
      {
        label: t('Constant.venueTypeDropdownItems.restaurant'),
        value: 'restaurant',
      },
      { label: t('Constant.venueTypeDropdownItems.rooftop'), value: 'rooftop' },
      { label: t('Constant.venueTypeDropdownItems.other'), value: 'other' },
    ],
    socialMediaOptions: [
      { label: t('Constant.facebook'), value: 'facebook' },
      { label: t('Constant.instagram'), value: 'instagram' },
      { label: t('Constant.twitter'), value: 'twitter' },
      { label: t('Constant.linkedin'), value: 'linkedin' },
      { label: t('Constant.youtube'), value: 'youtube' },
      { label: t('Constant.pinterest'), value: 'pinterest' },
    ],
    fullServiceOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    ballonOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    lightingOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    partyFavorsOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    decorRentalOptions: [
      { value: 'yes', label: t('Constant.yes') },
      { value: 'no', label: t('Constant.no') },
    ],
    checkboxOptions: [
      { value: 'Cloth rental', label: t('Constant.clothRental') },
      { value: 'Chair rental', label: t('Constant.chairRental') },
    ],
    accentOptions: [
      { value: 'Backdrops', label: t('Constant.backdrops') },
      { value: 'Centerpieces', label: t('Constant.Centerpieces') },
      { value: 'Cake Stands', label: t('Constant.cakeStands') },
      { value: 'Theme Props', label: t('Constant.themeProps') },
    ],
    checkboxCuisineOptions: [
      { label: t('Constant.american'), value: 'American' },
      { label: t('Constant.african'), value: 'African' },
      { label: t('Constant.soulFood'), value: 'SoulFood' },
      { label: t('Constant.chinese'), value: 'Chinese' },
      { label: t('Constant.japanese'), value: 'Japanese' },
      { label: t('Constant.french'), value: 'French' },
      { label: t('Constant.easternEuropean'), value: 'Eastern European' },
      { label: t('Constant.fusion'), value: 'Fusion' },
      { label: t('Constant.greek'), value: 'Greek' },
      { label: t('Constant.hawaiian'), value: 'Hawaiian' },
      { label: t('Constant.indian'), value: 'Indian' },
      { label: t('Constant.italian'), value: 'Italian' },
      { label: t('Constant.mediterranean'), value: 'Mediterranean' },
    ],
    drinkServiceOptions: [
      { label: t('Constant.bartenders'), value: 'Bartenders' },
      { label: t('Constant.barista'), value: 'Barista' },
    ],
    photographerServiceOptions: [
      { label: t('Constant.photography'), value: 'photography' },
      { label: t('Constant.videography'), value: 'videography' },
      { label: t('Constant.photobooth'), value: 'photobooth' },
    ],
    additionalServiceOptions: [
      { label: t('Constant.drone'), value: 'Drone' },
      { label: t('Constant.photoRetouching'), value: 'Photo retouching' },
      { label: t('Constant.videoEditing'), value: 'Video Editing' },
      { label: t('Constant.physicalPrints'), value: 'Physical Proofs/Prints' },
    ],
    drinkTypeOptions: [
      { label: t('Constant.beer'), value: 'Beer' },
      { label: t('Constant.champagne'), value: 'Champagne' },
      { label: t('Constant.liquor'), value: 'Liquor' },
      { label: t('Constant.nonAlcoholic'), value: 'Non-alcoholic' },
      { label: t('Constant.signatureCocktails'), value: 'Signature Cocktails' },
      { label: t('Constant.wine'), value: 'Wine' },
    ],
    checkboxDietaryOptions: [
      { label: t('Constant.vegan'), value: 'Vegan' },
      { label: t('Constant.vegetarian'), value: 'Vegetarian' },
      { label: t('Constant.halal'), value: 'Halal' },
      { label: t('Constant.kosher'), value: 'Kosher' },
      { label: t('Constant.nutFree'), value: 'Nut-Free' },
      { label: t('Constant.organic'), value: 'Organic' },
      { label: t('Constant.glutenFree'), value: 'Gluten-Free' },
    ],
    servingStyleOptions: [
      { label: t('Constant.buffet'), value: 'buffet' },
      { label: t('Constant.platedDinner'), value: 'platedDinner' },
      { label: t('Constant.familyStyle'), value: 'familyStyle' },
      { label: t('Constant.passedAppetizers'), value: 'passedAppetizers' },
    ],
    cateringAmenities: [
      { label: t('Constant.setUp'), value: 'setUp' },
      { label: t('Constant.cleanUp'), value: 'cleanUp' },
      { label: t('Constant.foodServiceRentals'), value: 'foodServiceRentals' },
      { label: t('Constant.servers'), value: 'servers' },
      { label: t('Constant.chefStations'), value: 'chefStations' },
    ],
    deliveryOptionPickup: [
      { value: 'pickUp', label: t('Constant.pickUp') },
      { value: 'delivery', label: t('Constant.delivery') },
    ],
    otherBakeryOptions: [{}],
    bakeryDesertType: [
      { label: t('Constant.cake'), value: 'cake' },
      { label: t('Constant.cupCake'), value: 'cupCake' },
      { label: t('Constant.cookies'), value: 'cookies' },
    ],
    entertainmentOptions: [
      { label: t('Constant.animalRental'), value: 'Animal Rental' },
      { label: t('Constant.character'), value: 'Character' },
      { label: t('Constant.clown'), value: 'Clown' },
      { label: t('Constant.comedy'), value: 'Comedy' },
      { label: t('Constant.dancer'), value: 'Dancer' },
      { label: t('Constant.dj'), value: 'DJ' },
      { label: t('Constant.facePainter'), value: 'Face Painter' },
      { label: t('Constant.magician'), value: 'Magician' },
      { label: t('Constant.musician'), value: 'Musician' },
      { label: t('Constant.paintingInstructor'), value: 'Painting Instructor' },
      { label: t('Constant.pettingZoo'), value: 'Petting Zoo' },
      { label: t('Constant.poet'), value: 'Poet' },
      { label: t('Constant.ride'), value: 'Ride' },
    ],
    entertainmentMusicianOptions: [
      { label: t('Constant.band'), value: 'Band' },
      { label: t('Constant.instrumentPlayer'), value: 'Instrument Player' },
      { label: t('Constant.singer'), value: 'Singer' },
      { label: t('Constant.equipmentRentals'), value: 'Equipment Rentals' },
    ],
    timePeriodOptions: [
      { label: t('Constant.hourly'), value: 'hourly' },
      { label: t('Constant.daily'), value: 'daily' },
      { label: t('Constant.weekly'), value: 'weekly' },
      { label: t('Constant.monthly'), value: 'monthly' },
    ],
    environmentRadioOptions: [
      { label: t('Constant.outdoor'), value: 'outdoor' },
      { label: t('Constant.indoor'), value: 'indoor' },
      { label: t('Constant.combination'), value: 'combination' },
    ],
    vibeOptions: [
      { value: 'cozy', label: t('Constant.cozy') },
      { value: 'modern', label: t('Constant.modern') },
      { value: 'rustic', label: t('Constant.rustic') },
      { value: 'elegant', label: t('Constant.elegant') },
      { value: 'waterfront', label: t('Constant.waterfront') },
      { value: 'gardenview', label: t('Constant.gardenview') },
      { value: 'cityview', label: t('Constant.cityview') },
    ],
    equipmentRentalOptions: [
      { value: 'audioVisual', label: t('Constant.audioVisual') },
      { value: 'bounceHouse', label: t('Constant.bounceHouse') },
      { value: 'cottonCandyMachine', label: t('Constant.cottonCandyMachine') },
      { value: 'foodService', label: t('Constant.foodService') },
      { value: 'hotTub', label: t('Constant.hotTub') },
      { value: 'popcornMachine', label: t('Constant.popcornMachine') },
      { value: 'sauna', label: t('Constant.sauna') },
      { value: 'slushieMachine', label: t('Constant.slushieMachine') },
    ],
    headerDropdownItems: [
      {
        label: t('Constant.headerDropdownItems.switchToPlannerMode'),
        href: '/planner',
      },
      {
        label: t('Constant.headerDropdownItems.plannerDashboard'),
        href: '/dashboard',
      },
      { label: t('Constant.headerDropdownItems.messages'), href: '#' },
      { label: t('Constant.headerDropdownItems.calendar'), href: '#' },
      { label: t('Constant.headerDropdownItems.referaFriend'), href: '#' },
      { label: t('Constant.headerDropdownItems.account'), href: '#' },
      { label: t('Constant.headerDropdownItems.giftCards'), href: '#' },
      { label: t('Constant.headerDropdownItems.helpCenter'), href: '#' },
    ],
    headerMenuItems: [
      { label: t('Constant.headerMenuItems.inbox'), href: '#' },
      { label: t('Constant.headerMenuItems.booking'), href: '#' },
      { label: t('Constant.headerMenuItems.calendar'), href: '#' },
      { label: t('Constant.headerMenuItems.listings'), href: '#' },
    ],
    plannerHeaderMenuItems: [
      { label: t('Constant.plannerHeaderMenuItems.planning'), href: '#' },
      { label: t('Constant.plannerHeaderMenuItems.event'), href: '#' },
      { label: t('Constant.plannerHeaderMenuItems.vendors'), href: '#' },
      { label: t('Constant.plannerHeaderMenuItems.about'), href: '#' },
      { label: t('Constant.plannerHeaderMenuItems.company'), href: '#' },
      { label: t('Constant.plannerHeaderMenuItems.contact'), href: '#' },
    ],
  };
};
