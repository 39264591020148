import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import CheckboxCombo from '@/components/ui/Checkbox';
import TextInputCombo from '@/components/ui/Input';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BakeryAndTreat = ({ values }: any) => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const handleFilesUpdate = (files: string[]) => {
    console.log(files);
    setFieldValue('images', files);
  };

  const {
    bakeryDesertType,
    checkboxCuisineOptions,
    checkboxDietaryOptions,
    deliveryOptionPickup,
  } = useConstantData();
  return (
    <div className='md:w-[440px]'>
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.bakery.cuisine')}
      </h1>

      <CheckboxCombo
        name='selectedBakeryCuisineOptions'
        options={checkboxCuisineOptions}
        label=''
      />

      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.bakery.dietaryOptions')}
      </h1>

      <CheckboxCombo
        name='selectedBakeryDietaryOptions'
        options={checkboxDietaryOptions}
        label=''
      />

      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.bakery.desertTypeOffered')}
      </h1>
      {bakeryDesertType.map((service) => (
        <div key={service.value}>
          <CheckboxCombo
            name='bakeryDesertType'
            options={[{ value: service.value, label: service.label }]}
            label=''
          />
          {values.bakeryDesertType.includes(service.value) && (
            <div className='flex justify-between w-full gap-4'>
              <TextInputCombo
                label=''
                name={`${service.value}_min`}
                placeholder={t('vendorSignUpQuestionnaire.min')}
                type='number'
              />
              <p className='mx-2.5 mt-5 md:mx-8'>
                {t('vendorSignUpQuestionnaire.to')}
              </p>
              <TextInputCombo
                label=''
                name={`${service.value}_max`}
                placeholder={t('vendorSignUpQuestionnaire.max')}
                type='number'
              />
            </div>
          )}
        </div>
      ))}

      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.bakery.deliveryOptions')}
      </h1>

      {deliveryOptionPickup.map((style) => (
        <div key={style.value}>
          <CheckboxCombo
            name='bakeryDeliveryOptionPickup'
            options={[{ value: style.value, label: style.label }]}
            label=''
          />
          {style.value === 'delivery' &&
            values.bakeryDeliveryOptionPickup.includes(style.value) && (
              <div className='flex justify-between w-full gap-4'>
                <TextInputCombo
                  label=''
                  name={`bakery_${style.value}_min`}
                  placeholder={t('vendorSignUpQuestionnaire.min')}
                  type='number'
                  min={0}
                />
                <p className='mx-2.5 mt-5 md:mx-8'>
                  {t('vendorSignUpQuestionnaire.to')}
                </p>
                <TextInputCombo
                  label=''
                  name={`bakery_${style.value}_max`}
                  placeholder={t('vendorSignUpQuestionnaire.max')}
                  type='number'
                />
              </div>
            )}
        </div>
      ))}
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default BakeryAndTreat;
