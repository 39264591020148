import * as React from 'react';

import { SVGComponentProps } from '../Venu';

const Indoor: React.FC<SVGComponentProps> = (props) => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='22.163' rx='2.93333' fill='#6B759D' />
    <path
      d='M16.0758 10.0559C16.0758 9.89557 15.9763 9.75202 15.8262 9.69571L13.9026 8.97437C13.6511 8.88005 13.3828 9.06599 13.3828 9.3346V12.777L11.8439 12.1947V7.19331L17.9994 9.52243V14.5238L16.0758 13.7959V10.0559ZM15.3064 13.5048L14.1522 13.0681V9.88972L15.3064 10.3225V13.5048ZM11.0744 7.19331V12.1947L4.91895 14.5238V9.52243L11.0744 7.19331ZM6.26546 12.2681C6.26546 12.5367 6.53377 12.7226 6.78526 12.6283L9.86302 11.4741C10.0132 11.4178 10.1126 11.2743 10.1126 11.1139V9.19033C10.1126 8.92172 9.84435 8.73578 9.59285 8.83011L6.51509 9.98428C6.36492 10.0406 6.26546 10.1841 6.26546 10.3445V12.2681ZM17.5865 15.1902L11.5942 17.4373C11.5071 17.4699 11.4112 17.4699 11.3241 17.4373L5.33187 15.1902L11.4592 12.8718L17.5865 15.1902ZM11.0744 4.88416V6.42387L4.91895 8.75298V7.45906C4.91895 7.2987 5.01843 7.15514 5.16857 7.09884L11.0744 4.88416ZM11.8439 4.88416L17.7498 7.09884C17.8999 7.15514 17.9994 7.2987 17.9994 7.45906V8.75298L11.8439 6.42387V4.88416ZM7.03491 11.7129V10.6111L9.34323 9.74546V10.8473L7.03491 11.7129Z'
      fill='white'
    />
  </svg>
);
export default Indoor;
