import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import useMobileView from '@/hooks/useMobileView';

const data = [
  { name: 'Jan', uv: 1500 },
  { name: 'Feb', uv: 1000 },
  { name: 'Mar', uv: 3000 },
  { name: 'Apr', uv: 500 },
  { name: 'May', uv: 2500 },
  { name: 'Jun', uv: 1200 },
  { name: 'Jul', uv: 2900 },
];

const DashboardBar = () => {
  const isMobile = useMobileView();
  return (
    <div className='h-[200px] md:h-[289px] w-full md:w-[601px] bg-[#FBF9FF] drop-shadow-md rounded-xl'>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
          barCategoryGap='20%'
        >
          <CartesianGrid strokeDasharray='3 3' vertical={false} />
          <XAxis
            dataKey='name'
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: isMobile ? 10 : 12 }}
            interval={0}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            ticks={[0, 1500, 3000, 4000]}
            tickFormatter={(value) =>
              value >= 1000 ? `${value / 1000}k` : value
            }
          />
          <Tooltip />
          <Bar
            dataKey='uv'
            background={{ fill: '#E9E7EE' }}
            fill='#6B759D'
            radius={[10, 10, 0, 0]}
            barSize={isMobile ? 15 : 27}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DashboardBar;
