/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResponseType } from '@/@types';
import { API } from '@/api/ApiInstance';
import { apiEndpoints } from '@/constants/apiEndpoints';
import { getQueryParams } from '@/utils/common';
import { showErrorToast } from '@/utils/toast';

export const GetThemeList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_THEME_LIST}?${queryParams}`;
  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting theme list.');
    return 400;
  }
  return response.data;
};
export const GetVenueList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_VENUE_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting venue list.');
    return 400;
  }

  return response.data;
};
export const GetDecorList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_DECOR_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting decor list.');
    return 400;
  }

  return response.data;
};
export const GetFoodList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_FOOD_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting food list.');
    return 400;
  }

  return response.data;
};
export const GetDesertList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_DESERT_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting desert list.');
    return 400;
  }

  return response.data;
};
export const GetBarList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_BAR_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting bar list.');
    return 400;
  }

  return response.data;
};
export const GetPhotographerList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_PHOTO_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting photography list.');
    return 400;
  }

  return response.data;
};

export const GetEntertainmentList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_ENTERTAINMENT_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting entertainment list.');
    return 400;
  }

  return response.data;
};

export const GetPartyList = async (params: any) => {
  const queryParams = getQueryParams(params);
  const url = `${apiEndpoints.GET_PARTY_LIST}?${queryParams}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting party list.');
    return 400;
  }

  return response.data;
};
export const CreateEvent = async (payload: any) => {
  const response = await API.Post<any, ResponseType<{ data: any }>>(
    apiEndpoints.CREATE_EVENT,
    {
      ...payload,
    },
  );
  if (!response.status) {
    showErrorToast(response.message || 'Error creating event.');
    return 400;
  }

  return response.data;
};
export const handleUpdateEvent = async (
  eventId: string,
  route: string,
  payload: any,
) => {
  const response = await API.Put<any, ResponseType<{ data: any }>>(
    `event-planning/${eventId}/${route}`,
    {
      ...payload,
    },
  );
  if (!response.status) {
    showErrorToast(response.message || 'Error updating data.');
    return 400;
  }

  return response.data;
};
export const getEvents = async (id: string) => {
  const url = `${apiEndpoints.GET_EVENTS}/${id}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting events.');
    return 400;
  }

  return response?.data;
};
export const getAllEvents = async () => {
  const url = `${apiEndpoints.GET_ALL_EVENTS}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting events list.');
    return 400;
  }

  return response?.data;
};
export const getEventSummary = async (id: string) => {
  const url = `${apiEndpoints.GET_SUMMARY}/${id}`;

  const response = await API.Get<ResponseType<{ data: any }>>(url);

  if (!response?.status) {
    showErrorToast(response?.message || 'Error getting event summary.');
    return 400;
  }

  return response?.data;
};
