import * as React from 'react';

import { SVGComponentProps } from './Venu';
const ShareArrow: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.52111 20.3016L4.27847 16.059L9.93532 10.4021L6.51292 6.97972H17.6004V18.0672L14.178 14.6448L8.52111 20.3016Z'
      fill='black'
    />
  </svg>
);
export default ShareArrow;
