import { BusinessState } from '@/utils/types/storeStateInterface';
import { createSlice } from '@reduxjs/toolkit';

const initialState: BusinessState = {
  businesses: [],
};

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {},
  extraReducers: () => {},
});
