import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Youtube: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='57'
    height='57'
    viewBox='0 0 57 57'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g filter='url(#filter0_d_1941_161)'>
      <rect
        x='15.3164'
        y='15'
        width='26.5792'
        height='26.5792'
        rx='13.2896'
        fill='white'
      />
      <path
        d='M36.2052 24.246C36.5691 25.527 36.5691 28.2012 36.5691 28.2012C36.5691 28.2012 36.5691 30.8754 36.2052 32.1564C36.0029 32.8645 35.4113 33.4216 34.662 33.6099C33.3012 33.9521 28.6063 33.9521 28.6063 33.9521C28.6063 33.9521 23.9139 33.9521 22.5506 33.6099C21.7982 33.4187 21.2073 32.8623 21.0075 32.1564C20.6436 30.8754 20.6436 28.2012 20.6436 28.2012C20.6436 28.2012 20.6436 25.527 21.0075 24.246C21.2097 23.538 21.8013 22.9808 22.5506 22.7925C23.9139 22.4503 28.6063 22.4503 28.6063 22.4503C28.6063 22.4503 33.3012 22.4503 34.662 22.7925C35.4145 22.9837 36.0053 23.5401 36.2052 24.246ZM27.0138 30.7172L31.7914 28.2012L27.0138 25.6852V30.7172Z'
        fill='#B8050D'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_1941_161'
        x='0.452563'
        y='0.136157'
        width='56.3068'
        height='56.3069'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='7.43192' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1941_161'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1941_161'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
export default Youtube;
