import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { FaRegEnvelope } from 'react-icons/fa6';
import { LuArrowUpDown } from 'react-icons/lu';

interface Guest {
  name: string;
  email: string;
  phone: string;
  adults: number;
  children?: number;
}

interface GuestListProps {
  guests: Guest[];
}

const GuestList: React.FC<GuestListProps> = ({ guests }) => {
  return (
    <div className='p-1 md:p-6 rounded-md'>
      <div className='flex flex-col md:flex-row justify-between items-center mb-12'>
        <h1 className='text-xl font-semibold'>Guest List</h1>
        <div className='flex flex-col sm:flex-row gap-5 md:space-x-2 mt-6 md:mt-0 whitespace-nowrap'>
          <div className='flex border border-voiletColor text-voiletColor items-center rounded-full px-4 py-2 gap-2'>
            <FaRegEnvelope />
            <button className='hover:bg-voiletColor hover:text-white transition'>
              Send A Message
            </button>
          </div>
          <div className='flex border border-voiletColor text-voiletColor items-center rounded-full px-4 py-2 gap-2'>
            <FaRegEnvelope />
            <button className='hover:bg-voiletColor hover:text-white transition'>
              Add Guest
            </button>
          </div>
        </div>
      </div>

      <div className='flex space-x-1 md:space-x-6 border-b pb-2'>
        <Tab label='All' count={75} />
        <Tab label='Yes' count={10} />
        <Tab label='No' count={0} />
        <Tab label='No Reply' count={65} />
      </div>

      <div className='mt-4 flex items-center space-x-4'>
        <div className='relative w-full mt-4'>
          <input
            type='text'
            placeholder='Search Guests'
            className='w-full px-4 py-2 sm:py-4 border rounded-2xl border-borderGreenColor focus:outline-none'
          />
          <div className='absolute right-2 sm:right-4 top-1 sm:top-3 bg-borderGreenColor p-2 rounded-xl'>
            <FaSearch className='text-white' />
          </div>
        </div>
        <button className='border p-2 rounded-xl bg-gray-100 transition mt-2.5'>
          <LuArrowUpDown className='text-borderGreenColor h-5 w-5 sm:w-7 sm:h-7' />
        </button>
      </div>

      <div className='mt-6 space-y-4'>
        {guests.map((guest, index) => (
          <div
            key={index}
            className='p-4 border rounded-lg shadow-sm flex flex-col sm:flex-row justify-between items-center'
          >
            <div>
              <h2 className='text-lg font-medium'>{guest.name}</h2>
              <p className='text-gray-600'>{guest.email}</p>
              <p className='text-gray-600'>{guest.phone}</p>
            </div>
            <div className='text-right flex sm:flex-col space-x-2'>
              <p className='font-semibold'>{guest.adults} Adults</p>
              {guest.children && <p> {guest.children} Child</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Tab: React.FC<{ label: string; count: number }> = ({ label, count }) => (
  <button className='flex items-center space-x-1 text-gray-700 hover:text-blue-500'>
    <span className='font-medium'>{label}</span>
    <span className='text-sm'>({count})</span>
  </button>
);

export default GuestList;
