import * as React from 'react';

import { SVGComponentProps } from '../Venu';

const Summer: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='22.163' rx='2.93333' fill={color} />
    <g clipPath='url(#clip0_1970_2788)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0823 13.3871V11.5656H14.5605V13.1645C14.7386 13.2306 14.9127 13.305 15.0823 13.3871Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.4106 12.6393C8.80208 12.4795 8.34277 11.7708 8.34277 10.9219C8.34277 10.5419 8.43477 10.1899 8.59112 9.90279C8.72399 9.65879 8.77756 9.35514 8.74017 9.05914C8.73147 8.99392 8.7273 8.92696 8.7273 8.85879C8.7273 8.17201 9.15043 7.61444 9.67147 7.61444C10.1925 7.61444 10.6156 8.17201 10.6156 8.85879C10.6156 8.92696 10.6115 8.99392 10.6033 9.05914C10.5659 9.35514 10.6195 9.65861 10.7522 9.90227C10.908 10.1899 11.0002 10.5419 11.0002 10.9219C11.0002 11.7708 10.5409 12.4795 9.93234 12.6393V9.92748C9.93234 9.78348 9.81547 9.66661 9.67147 9.66661C9.52747 9.66661 9.4106 9.78348 9.4106 9.92748V12.6393Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.12772 12.9539C5.65033 12.7996 5.2959 12.2316 5.2959 11.5553C5.2959 11.2428 5.37155 10.9534 5.50025 10.7172C5.60946 10.5167 5.65346 10.2669 5.62268 10.0234C5.61555 9.96988 5.61207 9.91475 5.61207 9.85875C5.61207 9.29388 5.96007 8.83545 6.38859 8.83545C6.81694 8.83545 7.16494 9.29388 7.16494 9.85875C7.16494 9.91475 7.16146 9.96988 7.15486 10.0234C7.12407 10.2669 7.16807 10.5163 7.27729 10.7168C7.40546 10.9534 7.48112 11.2428 7.48112 11.5553C7.48112 12.2316 7.12668 12.7996 6.64946 12.9539V10.7375C6.64946 10.5935 6.53242 10.4767 6.38859 10.4767C6.24459 10.4767 6.12772 10.5935 6.12772 10.7375V12.9539Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.79999 15.2226H5.5332C6.15599 14.565 7.0372 14.1546 8.01355 14.1546C8.21633 14.1546 8.41494 14.1723 8.60781 14.2063C8.36277 14.4134 8.13494 14.6396 7.92607 14.8828C7.84312 14.9791 7.79999 15.1003 7.79999 15.2226Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.32129 15.2226C9.32546 14.0537 10.814 13.3129 12.4742 13.3129C14.1343 13.3129 15.6229 14.0537 16.6272 15.2226H8.32129Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 16.2661C19 15.9781 18.7664 15.7443 18.4783 15.7443C16.0583 15.7443 5.94174 15.7443 3.52174 15.7443C3.23357 15.7443 3 15.9781 3 16.2661V18.3099C3 18.5979 3.23357 18.8316 3.52174 18.8316H18.4783C18.7664 18.8316 19 18.5979 19 18.3099C19 17.7407 19 16.8353 19 16.2661ZM6.0313 18.0622H11.8454C11.9894 18.0622 12.1063 17.9454 12.1063 17.8014C12.1063 17.6574 11.9894 17.5405 11.8454 17.5405H6.0313C5.8873 17.5405 5.77043 17.6574 5.77043 17.8014C5.77043 17.9454 5.8873 18.0622 6.0313 18.0622ZM10.6901 17.0355H14.3221C14.4661 17.0355 14.583 16.9186 14.583 16.7746C14.583 16.6306 14.4661 16.5137 14.3221 16.5137H10.6901C10.5461 16.5137 10.4292 16.6306 10.4292 16.7746C10.4292 16.9186 10.5461 17.0355 10.6901 17.0355ZM8.93844 17.0355H9.63287C9.77687 17.0355 9.89374 16.9186 9.89374 16.7746C9.89374 16.6306 9.77687 16.5137 9.63287 16.5137H8.93844C8.79444 16.5137 8.67757 16.6306 8.67757 16.7746C8.67757 16.9186 8.79444 17.0355 8.93844 17.0355Z'
        fill='white'
      />
      <path
        d='M4.56191 6.29216C5.42453 6.29216 6.12383 5.59287 6.12383 4.73025C6.12383 3.86763 5.42453 3.16833 4.56191 3.16833C3.69929 3.16833 3 3.86763 3 4.73025C3 5.59287 3.69929 6.29216 4.56191 6.29216Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8212 8.40088L11.8613 11.0438H17.781L14.8212 8.40088Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_1970_2788'>
        <rect width='16' height='16' fill='white' transform='translate(3 3)' />
      </clipPath>
    </defs>
  </svg>
);
export default Summer;
