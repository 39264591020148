import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Event: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1857_3612)'>
      <path
        d='M6.16393 10.3009L5.91844 11.7589C5.88498 11.9583 5.96677 12.1592 6.12996 12.2781C6.2934 12.3968 6.51043 12.4127 6.689 12.3191L7.99989 11.6348L9.31078 12.3193C9.38833 12.3595 9.47333 12.3798 9.55742 12.3798C9.66754 12.3798 9.7774 12.3453 9.87008 12.2782C10.0334 12.1593 10.1154 11.9584 10.0817 11.7591L9.83649 10.301L10.892 9.26561C11.0364 9.12421 11.0878 8.91321 11.0259 8.72118C10.9637 8.52927 10.7973 8.38877 10.5976 8.35929L9.1349 8.14239L8.47612 6.8183C8.38677 6.63755 8.20166 6.52307 7.99976 6.52307C7.79824 6.52307 7.61326 6.63755 7.52314 6.8183L6.86501 8.14239L5.40234 8.35916C5.20223 8.38865 5.03661 8.52914 4.97431 8.72105C4.91201 8.91308 4.96328 9.12434 5.10788 9.26548L6.16393 10.3009Z'
        fill={color}
      />
      <path
        d='M14.2858 1.70098H12.611V0.771971C12.611 0.345605 12.2654 0 11.8389 0H11.7165C11.2901 0 10.9446 0.345605 10.9446 0.771971V1.70098H5.03539V0.771971C5.03539 0.345605 4.68978 0 4.26354 0H4.14099C3.71463 0 3.36915 0.345605 3.36915 0.771971V1.70098H1.71381C0.837874 1.70098 0.125 2.41359 0.125 3.28979V14.4113C0.125 15.287 0.837874 16.0001 1.71381 16.0001H14.286C15.1618 16.0001 15.8748 15.2871 15.8748 14.4113V3.28979C15.8746 2.41359 15.1616 1.70098 14.2858 1.70098ZM14.0785 14.204H1.92109V5.5466H14.0785V14.204Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1857_3612'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default Event;
