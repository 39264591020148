import React from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { getTokenFromLS } from '@/utils/common';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = getTokenFromLS();

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return children;
};

export default ProtectedRoute;
