import * as React from 'react';

import { SVGComponentProps } from '../Venu';

const Outdoor: React.FC<SVGComponentProps> = (props) => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='22.163' rx='2.93333' fill='#6B759D' />
    <path
      d='M16.8566 8.09899C16.9537 7.91149 17.0049 7.70369 17.0062 7.49258C17.0062 7.13326 16.8635 6.78866 16.6094 6.53459C16.3553 6.28051 16.0107 6.13777 15.6514 6.13777C15.4403 6.13905 15.2325 6.19031 15.045 6.28734C14.9623 6.0093 14.7921 5.7654 14.5596 5.59196C14.327 5.41853 14.0447 5.32483 13.7547 5.32483C13.4646 5.32483 13.1823 5.41853 12.9498 5.59196C12.7173 5.7654 12.547 6.0093 12.4644 6.28734C12.2769 6.19031 12.0691 6.13905 11.8579 6.13777C11.4986 6.13777 11.154 6.28051 10.9 6.53459C10.6459 6.78866 10.5031 7.13326 10.5031 7.49258C10.5044 7.70369 10.5557 7.91149 10.6527 8.09899C10.3163 8.19883 10.032 8.42567 9.86 8.73148C9.68796 9.03729 9.6417 9.39805 9.73101 9.73737C9.82032 10.0767 10.0382 10.3679 10.3385 10.5494C10.6388 10.7309 10.9979 10.7884 11.3399 10.7097C11.395 11.0227 11.5586 11.3064 11.802 11.5109C12.0454 11.7153 12.353 11.8276 12.6708 11.8279C12.8577 11.8276 13.0424 11.7877 13.2127 11.7109V10.4122L12.0945 9.547C11.9808 9.45907 11.9066 9.32957 11.8884 9.18699C11.8701 9.0444 11.9093 8.90041 11.9972 8.78668C12.0851 8.67296 12.2146 8.59882 12.3572 8.58058C12.4998 8.56234 12.6438 8.60148 12.7575 8.68941L13.7579 9.46327L14.7819 8.68643C14.8964 8.59951 15.0407 8.56164 15.1832 8.58115C15.3256 8.60067 15.4545 8.67596 15.5414 8.79048C15.6283 8.90499 15.6662 9.04934 15.6467 9.19178C15.6271 9.33421 15.5518 9.46306 15.4373 9.54998L14.2966 10.4154V11.7109C14.467 11.7877 14.6516 11.8276 14.8385 11.8279C15.1564 11.8276 15.464 11.7153 15.7074 11.5109C15.9507 11.3064 16.1143 11.0227 16.1695 10.7097C16.2662 10.7321 16.365 10.7436 16.4643 10.7441C16.789 10.7452 17.1032 10.6292 17.3495 10.4175C17.5957 10.2058 17.7574 9.91253 17.8051 9.59133C17.8527 9.27012 17.7831 8.9425 17.6089 8.66844C17.4347 8.39438 17.1677 8.19223 16.8566 8.09899Z'
      fill='white'
    />
    <path
      d='M15.1095 16.9762H14.0256V10.2807L15.272 9.33509C15.3295 9.29197 15.3675 9.22778 15.3777 9.15664C15.3879 9.0855 15.3694 9.01324 15.3262 8.95575C15.2831 8.89826 15.2189 8.86025 15.1478 8.85009C15.0766 8.83992 15.0044 8.85844 14.9469 8.90156L13.7574 9.80385L12.5922 8.90318C12.5354 8.85916 12.4634 8.83953 12.3921 8.8486C12.3208 8.85767 12.256 8.8947 12.212 8.95155C12.1679 9.00839 12.1483 9.08039 12.1574 9.15171C12.1664 9.22303 12.2035 9.28782 12.2603 9.33184L13.4837 10.2791V16.9762H12.3999C12.328 16.9762 12.2591 17.0047 12.2083 17.0555C12.1575 17.1064 12.1289 17.1753 12.1289 17.2471C12.1289 17.319 12.1575 17.3879 12.2083 17.4387C12.2591 17.4895 12.328 17.5181 12.3999 17.5181H15.1095C15.1813 17.5181 15.2503 17.4895 15.3011 17.4387C15.3519 17.3879 15.3804 17.319 15.3804 17.2471C15.3804 17.1753 15.3519 17.1064 15.3011 17.0555C15.2503 17.0047 15.1813 16.9762 15.1095 16.9762Z'
      fill='white'
    />
    <path
      d='M10.503 14.5376H4.54192C4.24263 14.5376 4 14.7802 4 15.0795C4 15.3788 4.24263 15.6214 4.54192 15.6214H10.503C10.8023 15.6214 11.045 15.3788 11.045 15.0795C11.045 14.7802 10.8023 14.5376 10.503 14.5376Z'
      fill='white'
    />
    <path
      d='M9.9612 11.8279H5.08391C4.78462 11.8279 4.54199 12.0705 4.54199 12.3698V12.9117C4.54199 13.211 4.78462 13.4536 5.08391 13.4536H9.9612C10.2605 13.4536 10.5031 13.211 10.5031 12.9117V12.3698C10.5031 12.0705 10.2605 11.8279 9.9612 11.8279Z'
      fill='white'
    />
    <path d='M5.35449 13.7246H6.16737V14.2665H5.35449V13.7246Z' fill='white' />
    <path d='M8.87695 13.7246H9.68983V14.2665H8.87695V13.7246Z' fill='white' />
    <path
      d='M5.35449 15.8923H6.16737V16.8407C6.16737 16.9485 6.12455 17.0519 6.04833 17.1281C5.97211 17.2043 5.86873 17.2471 5.76093 17.2471C5.65314 17.2471 5.54976 17.2043 5.47354 17.1281C5.39731 17.0519 5.35449 16.9485 5.35449 16.8407V15.8923Z'
      fill='white'
    />
    <path
      d='M8.87695 15.8923H9.68983V16.8407C9.68983 16.9485 9.64701 17.0519 9.57079 17.1281C9.49457 17.2043 9.39119 17.2471 9.28339 17.2471C9.1756 17.2471 9.07222 17.2043 8.996 17.1281C8.91977 17.0519 8.87695 16.9485 8.87695 16.8407V15.8923Z'
      fill='white'
    />
    <path
      d='M6.1557 6.65702C6.21442 6.69726 6.26463 6.74868 6.30347 6.80833C6.34231 6.86798 6.36902 6.93469 6.38208 7.00466C6.39514 7.07464 6.39429 7.1465 6.37958 7.21614C6.36486 7.28578 6.33658 7.35185 6.29633 7.41056C6.25609 7.46927 6.20467 7.51948 6.14502 7.55832C6.08537 7.59717 6.01866 7.62388 5.94869 7.63694C5.87871 7.65 5.80685 7.64914 5.73721 7.63443C5.66757 7.61972 5.6015 7.59143 5.54279 7.55119C5.29595 7.38211 4.95508 6.49146 4.95508 6.49146C4.95508 6.49146 5.90859 6.48767 6.1557 6.65702Z'
      fill='white'
    />
    <path
      d='M9.09085 6.16296C8.99241 6.2654 8.85767 6.32507 8.71566 6.32915C8.57365 6.33322 8.43572 6.28136 8.33156 6.18474C8.22741 6.08812 8.16536 5.95446 8.15878 5.81254C8.15221 5.67062 8.20163 5.53179 8.2964 5.42595C8.49989 5.20647 9.43064 5 9.43064 5C9.43064 5 9.29434 5.94376 9.09085 6.16296Z'
      fill='white'
    />
    <path
      d='M7.2371 8.87666C7.22944 8.73311 7.27913 8.5924 7.37522 8.48549C7.47131 8.37857 7.60594 8.31421 7.74948 8.30656C7.89303 8.2989 8.03374 8.34859 8.14065 8.44468C8.24757 8.54077 8.31193 8.6754 8.31958 8.81894C8.33476 9.11835 7.83619 9.93123 7.83619 9.93123C7.83619 9.93123 7.25308 9.17553 7.2371 8.87666Z'
      fill='white'
    />
    <path
      d='M16.9833 12.8603C16.9279 12.8133 16.8825 12.7556 16.8499 12.6907C16.8172 12.6259 16.7979 12.5551 16.7932 12.4826C16.7884 12.4101 16.7983 12.3374 16.8222 12.2688C16.8461 12.2002 16.8835 12.1371 16.9323 12.0833C16.981 12.0294 17.0401 11.9859 17.106 11.9554C17.172 11.9248 17.2433 11.9079 17.3159 11.9054C17.3885 11.903 17.4609 11.9153 17.5287 11.9414C17.5965 11.9675 17.6583 12.0069 17.7105 12.0574C17.9325 12.2582 18.1498 13.1868 18.1498 13.1868C18.1498 13.1868 17.2049 13.0613 16.9833 12.8603Z'
      fill='white'
    />
    <path
      d='M15.4124 14.2518C15.5056 14.1434 15.6379 14.0763 15.7803 14.065C15.9228 14.0538 16.064 14.0992 16.1731 14.1915C16.2822 14.2838 16.3504 14.4155 16.363 14.5578C16.3755 14.7002 16.3312 14.8417 16.2399 14.9516C16.0464 15.1803 15.126 15.4291 15.126 15.4291C15.126 15.4291 15.2192 14.4802 15.4124 14.2518Z'
      fill='white'
    />
  </svg>
);
export default Outdoor;
