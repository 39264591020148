/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import Attachment from '@/assets/svg/Attachment';
import Facebook from '@/assets/svg/Facebook';
import Instagram from '@/assets/svg/Instagram';
import Pinterest from '@/assets/svg/Pinterest';
import ShareArrow from '@/assets/svg/ShareArrow';
import Tiktok from '@/assets/svg/Tiktok';
import Twitter from '@/assets/svg/Twitter';
import Footer from '@/components/Footer';
import PlannerHeader from '@/components/PlannerHeader';
import PlannerHero from '@/components/PlannerHero';
import SaveProgressButton from '@/components/SaveProgressButton';
import Tabs from '@/components/Tabs';
import { Typography } from '@/components/Typography';
import Container from '@/components/ui/Container';
import { ROUTES } from '@/constants';

const PlannerInvitation = () => {
  const [uploadedImage, setUploadedImage] = useState('');

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // @ts-ignore
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='w-full'>
      <PlannerHeader />
      <div className='mb-20'>
        <PlannerHero
          length={11}
          route={`${ROUTES.PLANNER_SUMMARY}`}
          apiRoute='invitation'
          selectedValue={{ image: uploadedImage }}
          setSelectedValue={setUploadedImage}
          invitation
        />
        <Container className='md:w-[95%] lg:w-4/5'>
          <Tabs sort={false} length={10} />
          <hr />
          <div className='lg:w-3/4 xl:w-1/2 mx-auto flex flex-col justify-center items-center border border-borderColor rounded-[32px] mt-20 p-5'>
            <Typography className='text-xl md:!text-2xl font-semibold'>
              Options
            </Typography>
            <div className='flex gap-3 my-10'>
              <label className='bg-[#6B759D0F] py-2 px-3 md:py-4 md:px-8 text-sm md:text-base border-[#6B759DE5] border border-dashed  rounded-[100px] cursor-pointer'>
                <input
                  type='file'
                  accept='image/*'
                  className='hidden'
                  onChange={handleFileUpload}
                />
                Upload invite
              </label>
              <button className='bg-[#6B759D4D] py-2 px-3 md:py-4 md:px-8 text-sm md:text-base rounded-[100px]'>
                Create invite using A.I
              </button>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center mt-10 md:mt-20'>
            <Typography className='text-xl md:!text-[34px] font-semibold mb-10'>
              Invitation Preview
            </Typography>
            {uploadedImage ? (
              <img
                src={uploadedImage}
                alt='Uploaded Invite'
                className='rounded-xl lg:w-3/4 xl:w-1/2 h-[75vh] md:h-[60vh] xl:h-[80vh]'
              />
            ) : (
              <p className='text-gray-500'>No preview available</p>
            )}
          </div>
          <div className='lg:w-3/4 xl:w-1/2 mx-auto  bg-[#F9F9F9] rounded-3xl p-5 mt-20'>
            <div className='w-full mx-auto bg-white rounded-3xl md:p-7 p-3'>
              <Typography className='text-sm md:!text-2xl font-semibold border-b border-[#EBEBEB] w-full text-center'>
                Send Invites
              </Typography>
              <p className='mt-7 md:mb-4 font-semibold text-lg text-eveningEast'>
                To:
              </p>
              <div className='flex gap-5 text-xs md:text-base text-eveningEast md:my-5 my-2'>
                <div className='flex items-center gap-1'>
                  <input type='radio' name='' id='' />{' '}
                  <span>Entire Guest List</span>
                </div>
                <div className='flex items-center gap-1'>
                  <input type='radio' name='' id='' /> <span>Custom</span>
                </div>
              </div>
              <div className='my-5'>
                <span className='text-sm'>Subject:</span>{' '}
                <input
                  type='text'
                  className='rounded-[10px] border p-2 md:ml-2 focus:outline-none'
                />
              </div>
              <div>
                <p className='text-sm'>Message:</p>
                <textarea
                  name=''
                  id=''
                  className='w-full focus:outline-none rounded-[10px] border p-2 h-52 mt-3'
                />
              </div>
              <div className='flex justify-between items-center mt-5'>
                <button className='md:py-4 p-2 text-[10px] md:px-4 pr-3 flex items-center gap-1 md:text-sm text-voiletColor border-[#6B759DE5] border border-dashed rounded-[100px]'>
                  <Attachment className='mr-1 h-5 md:h-[30px]' />{' '}
                  <span className='text-brandGreen'>Drag & Drop or </span>{' '}
                  Choose file
                </button>
                <SaveProgressButton text='Send' className='w-20 md:w-48' />
              </div>
            </div>
          </div>
          <div className='mt-20 md:w-4/5 mx-auto '>
            <Typography className='!text-2xl flex items-center justify-center xl:justify-start gap-1 font-semibold'>
              Share to social media
              <ShareArrow className='hidden md:block' />
            </Typography>
            <div className='flex justify-center flex-wrap xl:flex-nowrap gap-5 mt-5 t text-[#0A0C2C]'>
              <div className='flex items-center text-lg md:pr-8 h-10 md:h-12 bg-[#F6F8FF] rounded-2xl '>
                <Facebook fill='a' className='-ml-2' />
                <p className='md:mr-5 mr-3 -ml-3 md:ml-0 text-xs md:text-base font-medium'>
                  Facebook
                </p>
              </div>
              <div className='flex items-center text-lg md:pr-8 h-10 md:h-12 bg-[#E7F8FF] rounded-2xl'>
                <Twitter fill='a' className='-ml-2' />
                <p className='md:mr-5 mr-3 -ml-3 md:ml-0 text-xs md:text-base font-medium'>
                  Twitter
                </p>
              </div>
              <div className='flex items-center text-lg md:pr-8 h-10 md:h-12 bg-[#F7F2FF] rounded-2xl'>
                <Instagram fill='a' className='-ml-2' />
                <p className='md:mr-5 mr-3 -ml-3 md:ml-0 text-xs md:text-base font-medium'>
                  Instagram
                </p>
              </div>
              <div className='flex items-center text-lg md:pr-8 h-10 md:h-12 bg-[#F0F0F0] rounded-2xl'>
                <Tiktok fill='a' className='-ml-2' />
                <p className='md:mr-5 mr-3 -ml-3 md:ml-0 text-xs md:text-base font-medium'>
                  Tiktok
                </p>
              </div>
              <div className='flex items-center text-lg md:pr-8 h-10 md:h-12 pl-3 bg-[#FFEDED] rounded-2xl'>
                <Pinterest fill='a' />
                <p className='mx-5 ml-2 md:ml-5 text-xs md:text-base font-medium'>
                  Pinterest
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PlannerInvitation;
