import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { ErrorMessage, Field, useField, useFormikContext } from 'formik';

import { cn } from '@/lib/utils';

type SelectInputComboProps = {
  name: string;
  label: string;
  options: { label: string; value: string }[];
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  inputStyle?: string;
  containerStyle?: string;
  labelStyle?: string;
};

const SelectInputCombo = (props: SelectInputComboProps) => {
  const [field, meta] = useField(props.name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(props.name, e.target.value);
  };

  return (
    <div className={cn('flex-1', props.containerStyle)}>
      <label
        htmlFor={props.name}
        className={cn(
          'block text-sm leading-6 text-gray-600 font-semibold',
          props.labelStyle,
        )}
      >
        {props.label}
        {props.required && <span className='text-red-600'>*</span>}
      </label>
      <div className='mt-2 relative'>
        <Field
          as='select'
          {...field}
          name={props.name}
          onChange={handleChange}
          className={cn(
            'block dropdown-select w-full pl-2 h-[50px] rounded-md border-0 focus:text-black py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6',
            props.inputStyle,
            {
              'text-black': field.value,
              'text-gray-400': !field.value,
              'bg-gray-200 text-gray-500': props.disabled,
            },
          )}
          required={props.required}
          disabled={props.disabled}
          aria-invalid={meta.touched && meta.error ? 'true' : 'false'}
        >
          <option value='' disabled>
            {props.placeholder || 'Please select'}
          </option>

          {props.options.map((option, index) => (
            <option key={`${option.value}${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
        <div className='absolute sm:text-sm text-lg right-3 top-1/2 transform -translate-y-1/2 '>
          <FaChevronDown
            className={cn({
              'text-black': field.value,
              'text-gray-400': !field.value,
              'bg-gray-200 text-gray-500': props.disabled,
            })}
          />
        </div>
      </div>
      <ErrorMessage name={props.name}>
        {(msg) => <div className='text-red-600 text-sm mt-1'>{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default SelectInputCombo;
