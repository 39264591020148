/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Required from '@/assets/svg/Required';
import PlannerHero from '@/components/PlannerHero';
import Tabs from '@/components/Tabs';
import { Typography } from '@/components/Typography';
import Container from '@/components/ui/Container';
import { ROUTES } from '@/constants';

import AddGuest from './AddGuest';

const GuestSection = () => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [guestCount, setGuestCount] = useState<string>('');
  const [apiData, setApiData] = useState<{
    serviceIds: string[];
    approxGuestCount: number;
  }>({
    serviceIds: selectedValue,
    approxGuestCount: Number(guestCount),
  });

  useEffect(() => {
    setApiData({
      serviceIds: selectedValue,
      approxGuestCount: Number(guestCount),
    });
  }, [selectedValue, guestCount]);

  return (
    <div className='mb-20'>
      <PlannerHero
        length={0}
        route={ROUTES.PLANNER_THEME}
        apiRoute='guest'
        selectedValue={apiData}
        setSelectedValue={setSelectedValue}
        setGuestCount={setGuestCount}
        guest
      />
      <Container className='md:w-[95%] lg:w-4/5'>
        <Tabs length={1} />
        <div className='flex p-1 md:p-3 pl-3 pr-7 mt-12 items-center w-fit gap-3 border rounded-2xl'>
          <Typography className='md:text-xl text-sm'>
            Approximate Guest Count:
          </Typography>
          <div className='relative'>
            <p className='md:px-3 px-0.5 py-0.5 md:py-[10px] rounded-xl text-sm md:text-2xl bg-lightGray'>
              <input
                onChange={(e) => setGuestCount(e.target.value)}
                type='number'
                value={guestCount}
                className='w-10 h-10 text-center bg-lightGray font-semibold focus:outline-none'
              />
            </p>
            <Required className='absolute top-1 -right-3' />
          </div>
        </div>
        <AddGuest
          setSelectedValue={setSelectedValue}
          // selectedValue={selectedValue}
        />
      </Container>
    </div>
  );
};

export default GuestSection;
