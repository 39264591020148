import React from 'react';
import clsx from 'clsx';

import { cn } from '@/lib/utils';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  variant?: 'filled' | 'outline';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  iconLeft,
  type = 'submit',
  iconRight,
  variant = 'filled',
  size = 'medium',
  disabled = false,
  className = '',
  iconClassName = '',
}) => {
  const baseStyles =
    'flex items-center justify-center rounded-[10px] font-medium transition ease-in-out duration-300 text-inputTxtColor';
  const variantStyles =
    variant === 'filled' ? 'text-white' : 'border-2 border-borderColor';

  const sizeStyles = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-4 py-2 text-base',
    large: 'px-6 py-3 text-lg',
  };

  const disabledStyles = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={clsx(
        baseStyles,
        variantStyles,
        sizeStyles[size],
        disabledStyles,
        className,
      )}
    >
      {iconLeft && (
        <span className={cn('mr-2', iconClassName)}>{iconLeft}</span>
      )}
      {children}
      {iconRight && (
        <span className={cn('ml-2', iconClassName)}>{iconRight}</span>
      )}
    </button>
  );
};

export default Button;
