import React from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '@/assets/png/Logo.png';
import CopyRightFooter from '@/components/CopyRightFooter';
import { ROUTES } from '@/constants';

import RegisterForm from './modules/register-form';

const Register = () => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex w-full flex-col justify-center items-center border-b border-solid border-gray-100 p-3.5 mb-4'>
        <img
          onClick={() => navigate(ROUTES.LOGIN)}
          className='cursor-pointer'
          src={Logo}
          alt='Main Logo'
          width={157}
          height={144}
        />
      </div>
      <div className='flex-grow mb-10'>
        <RegisterForm />
      </div>
      <CopyRightFooter />
    </div>
  );
};

export default Register;
