import Footer from '@/components/Footer';
import GuestList from '@/components/PlannerGuestList';
import PlannerHeader from '@/components/PlannerHeader';
import PlannerHero from '@/components/PlannerHero';
import Tabs from '@/components/Tabs';
import Container from '@/components/ui/Container';
import { ROUTES } from '@/constants';
import { guests } from '@/constants/common';

const PlannerRsvp = () => {
  return (
    <div className='w-full'>
      <PlannerHeader />
      <div className='mb-20'>
        <PlannerHero length={10} route={ROUTES.PLANNER_SUMMARY} apiRoute='' />
        <Container>
          <Tabs sort={false} length={11} />
          <hr />
          <div className='w-full border rounded-[32px] mt-10 p-2 sm:p-10 pb-14 '>
            <GuestList guests={guests} />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PlannerRsvp;
