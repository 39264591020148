/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import GuestListUpload from '@/assets/svg/GuestListUpload';
import { Typography } from '@/components/Typography';
import { UploadCSVFile } from '@/services/guest';
import { showErrorToast } from '@/utils/toast';

const FileUploader = ({
  fetchGuests,
  setLoading,
}: {
  fetchGuests: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      handleUpload(file);
    }
    event.target.value = '';
  };

  const handleUpload = async (file: File) => {
    setLoading(true);
    try {
      const { status }: any = await UploadCSVFile(file);
      if (status === 200) {
        fetchGuests();
      } else {
        showErrorToast('Error uploading file');
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <label
      htmlFor='file'
      className='py-5 mt-3 rounded-2xl flex flex-col justify-center items-center bg-lightGray cursor-pointer'
    >
      <GuestListUpload className='mb-2.5' />
      <Typography
        variant='subheading'
        className='text-astrogranite md:text-lg text-xs'
      >
        Upload File (csv, xls)
      </Typography>
      <input
        id='file'
        type='file'
        accept='.csv, .xls, .xlsx'
        onChange={handleFileChange}
        className='hidden'
      />
    </label>
  );
};

export default FileUploader;
