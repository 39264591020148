/* eslint-disable @typescript-eslint/no-explicit-any */
import { AUTH_TOKEN } from '@/constants/common';

import { JSONParse } from './jsonparse';

export const getTokenFromLS = () => {
  if (typeof localStorage !== 'undefined') {
    return JSONParse(localStorage.getItem(AUTH_TOKEN) as string);
  }
  return null;
};

export const setTokenToLS = (token: string) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
  }
};

export const clearStorage = () => {
  localStorage.clear();
};
export const getQueryParams = (params: Record<string, any>) => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (
      value === undefined ||
      value === null ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return;
    }

    if (Array.isArray(value)) {
      queryParams.append(key, value.join('|'));
    } else {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString();
};
