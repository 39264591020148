export const AUTH_TOKEN = '@authToken';

export const guests = [
  {
    name: 'Kimberly Carpenter',
    email: 'Kimmy0889@gmail.com',
    phone: '(810) 879-8293',
    adults: 2,
  },
  {
    name: 'Adam Wade',
    email: 'wadea1992@hotmail.com',
    phone: '313 678 9502',
    adults: 1,
    children: 1,
  },
  {
    name: 'Amy Krutel',
    email: 'amykte@gmail.com',
    phone: '(248) 6599336',
    adults: 2,
  },
  {
    name: 'Kelvin James',
    email: 'kelsjames22@gmail.com',
    phone: '(313) 776-2030',
    adults: 2,
  },
];
