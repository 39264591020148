import { TFunction } from 'i18next';
import * as Yup from 'yup';

const getValidationSchema = (category: string, t: TFunction) => {
  const commonValidation = Yup.object({
    firstName: Yup.string().required(
      t('vendorSignUpQuestionnaire.validation.firstNameRequired'),
    ),
    lastName: Yup.string().required(
      t('vendorSignUpQuestionnaire.validation.lastNameRequired'),
    ),
    title: Yup.string().required(
      t('vendorSignUpQuestionnaire.validation.titleRequired'),
    ),
    email: Yup.string()
      .email(t('vendorSignUpQuestionnaire.validation.emailInvalid'))
      .required(t('vendorSignUpQuestionnaire.validation.emailRequired')),
    phone: Yup.string().required(
      t('vendorSignUpQuestionnaire.validation.phoneRequired'),
    ),
    business: Yup.string().required(
      t('vendorSignUpQuestionnaire.validation.businessNameRequired'),
    ),
    location: Yup.string().required(
      t('vendorSignUpQuestionnaire.validation.locationRequired'),
    ),
    website: Yup.string()
      .required(t('vendorSignUpQuestionnaire.validation.websiteRequired'))
      .url(t('vendorSignUpQuestionnaire.validation.urlInvalid')),

    socialMedia: Yup.array().of(
      Yup.object()
        .shape({
          url: Yup.string()
            .required(t('vendorSignUpQuestionnaire.validation.urlRequired'))
            .url(t('vendorSignUpQuestionnaire.validation.urlInvalid')),
          platform: Yup.string().required(
            t(
              'vendorSignUpQuestionnaire.validation.socialMediaPlatformRequired',
            ),
          ),
        })
        .required('Social Media is required'),
    ),
    description: Yup.string().required(
      t('vendorSignUpQuestionnaire.validation.descriptionRequired'),
    ),
    password: Yup.string()
      .min(8, t('register.validation.passwordMinLength'))
      .required(t('register.validation.passwordRequired')),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        t('register.validation.confirmPasswordMatch'),
      )
      .required(t('register.validation.confirmPasswordRequired')),
    category: Yup.string()
      .required(t('vendorSignUpQuestionnaire.validation.categoryRequired'))
      .notOneOf(
        [''],
        t('vendorSignUpQuestionnaire.validation.selectValidOption'),
      ),
  });

  switch (category) {
    case 'Venue':
      return commonValidation.shape({
        venuType: Yup.string()
          .required(t('vendorSignUpQuestionnaire.validation.venuRequired'))
          .notOneOf(
            [''],
            t('vendorSignUpQuestionnaire.validation.selectValidOption'),
          ),

        wirelessInternet: Yup.string().required(
          'Please select an option for Wireless Internet',
        ),
        outsideCatering: Yup.string().required(
          'Please select an option for Wireless Internet',
        ),
        smokingAllowed: Yup.string().required(
          'Please select an option for Wireless Internet',
        ),

        spaces: Yup.array()
          .of(
            Yup.object()
              .shape({
                capacitySeated: Yup.string().required('Field is required'),
                capacityStanding: Yup.string().required('Field is required'),
                rate: Yup.string().required('Field is required'),
                hourlyDropdown: Yup.string().required(
                  'Please select an option',
                ),
                vibeDropdown: Yup.string().required('Please select an option'),
                environmentOptions: Yup.string().required(
                  'Please select an option for environment',
                ),
              })
              .required('Space details are required'),
          )
          .min(1),
      });

    case 'Decor':
      return commonValidation.shape({
        selectedServiceOption: Yup.string().required('Please select an option'),
        selectedLightingOption: Yup.string().required(
          'Please select an option',
        ),
        averagePricePerPerson: Yup.string().required('Field is required'),
        selectedBallonOption: Yup.string().required('Please select an option'),
        selectedPartyOption: Yup.string().required('Please select an option'),
        selectedDecorOption: Yup.string().required('Please select an option'),
        selectedAccentsOptions: Yup.array()
          .min(1, 'You must select at least one option')
          .required('Selection is required'),
      });

    case 'Food Service':
      return commonValidation.shape({
        selectedCuisineOptions: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        selectedDietaryOptions: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        servingStyleOptions: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        cateringAmenities: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        deliveryOptionPickup: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        buffet_min: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('buffet')
              ? schema.required('Min value is required')
              : schema,
        ),
        buffet_max: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('buffet')
              ? schema.required('Max value is required')
              : schema,
        ),
        platedDinner_min: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('platedDinner')
              ? schema.required('Min value is required')
              : schema,
        ),
        platedDinner_max: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('platedDinner')
              ? schema.required('Max value is required')
              : schema,
        ),
        familyStyle_min: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('familyStyle')
              ? schema.required('Min value is required')
              : schema,
        ),
        familyStyle_max: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('familyStyle')
              ? schema.required('Max value is required')
              : schema,
        ),

        passedAppetizers_min: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('passedAppetizers')
              ? schema.required('Min value is required')
              : schema,
        ),
        passedAppetizers_max: Yup.string().when(
          'servingStyleOptions',
          (servingStyleOptions: string[], schema) =>
            servingStyleOptions?.[0]?.includes('passedAppetizers')
              ? schema.required('Max value is required')
              : schema,
        ),
        setUp_min: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('setUp')
              ? schema.required('Min value is required')
              : schema,
        ),
        setUp_max: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('setUp')
              ? schema.required('Max value is required')
              : schema,
        ),
        cleanUp_min: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('cleanUp')
              ? schema.required('Min value is required')
              : schema,
        ),
        cleanUp_max: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('cleanUp')
              ? schema.required('Max value is required')
              : schema,
        ),
        foodServiceRentals_min: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('foodServiceRentals')
              ? schema.required('Min value is required')
              : schema,
        ),
        foodServiceRentals_max: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('foodServiceRentals')
              ? schema.required('Max value is required')
              : schema,
        ),
        servers_per_hour: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('servers')
              ? schema.required('Value is required')
              : schema,
        ),
        chefStations_per_hour: Yup.string().when(
          'cateringAmenities',
          (cateringAmenities: string[], schema) =>
            cateringAmenities?.[0]?.includes('chefStations')
              ? schema.required('Value is required')
              : schema,
        ),
        delivery_min: Yup.string().when(
          'deliveryOptionPickup',
          (deliveryOptionPickup: string[], schema) =>
            deliveryOptionPickup?.[0]?.includes('delivery')
              ? schema.required('Min value is required')
              : schema,
        ),
        delivery_max: Yup.string().when(
          'deliveryOptionPickup',
          (deliveryOptionPickup: string[], schema) =>
            deliveryOptionPickup?.[0]?.includes('delivery')
              ? schema.required('Max value is required')
              : schema,
        ),
      });

    case 'Drink Services':
      return commonValidation.shape({
        attendantPrice: Yup.string().required('Price is required'),
        drinkPriceRange_min: Yup.string().required('Min value is required'),
        drinkPriceRange_max: Yup.string().required('Max value is required'),
        selectedDrinkServices: Yup.array()
          .min(1, 'Atleast one drink must be selected')
          .required('Selection is required'),
        provideAlcohol: Yup.string().required('Please select an option'),
        serveAlcohol: Yup.string().required('Please select an option'),
        selectedDrinkTypes: Yup.array()
          .min(1, 'Atleast one drink type must be selected')
          .required('Selection is required'),
      });

    case 'Photographer/Videographer':
      return commonValidation.shape({
        photographyServiceOptions: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        photography_min: Yup.string().when(
          'photographerServiceOptions',
          (photographerServiceOptions: string[], schema) =>
            photographerServiceOptions?.[0]?.includes('photography')
              ? schema.required('Min value is required')
              : schema,
        ),
        photography_max: Yup.string().when(
          'photographerServiceOptions',
          (photographerServiceOptions: string[], schema) =>
            photographerServiceOptions?.[0]?.includes('photography')
              ? schema.required('Max value is required')
              : schema,
        ),
        videography_min: Yup.string().when(
          'photographerServiceOptions',
          (photographerServiceOptions: string[], schema) =>
            photographerServiceOptions?.[0]?.includes('videography')
              ? schema.required('Min value is required')
              : schema,
        ),
        videography_max: Yup.string().when(
          'photographerServiceOptions',
          (photographerServiceOptions: string[], schema) =>
            photographerServiceOptions?.[0]?.includes('videography')
              ? schema.required('Max value is required')
              : schema,
        ),
        photobooth_min: Yup.string().when(
          'photographerServiceOptions',
          (photographerServiceOptions: string[], schema) =>
            photographerServiceOptions?.[0]?.includes('photobooth')
              ? schema.required('Min value is required')
              : schema,
        ),
        photobooth_max: Yup.string().when(
          'photographerServiceOptions',
          (photographerServiceOptions: string[], schema) =>
            photographerServiceOptions?.[0]?.includes('photobooth')
              ? schema.required('Max value is required')
              : schema,
        ),
        selectedAdditionalServices: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
      });

    case 'Bakery/Treats':
      return commonValidation.shape({
        selectedBakeryCuisineOptions: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        selectedBakeryDietaryOptions: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        bakeryDesertType: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        cake_min: Yup.string().when(
          'bakeryDesertType',
          (bakeryDesertType: string[], schema) =>
            bakeryDesertType?.[0]?.includes('cake')
              ? schema.required('Min value is required')
              : schema,
        ),
        cake_max: Yup.string().when(
          'bakeryDesertType',
          (bakeryDesertType: string[], schema) =>
            bakeryDesertType?.[0]?.includes('cake')
              ? schema.required('Max value is required')
              : schema,
        ),
        cupCake_min: Yup.string().when(
          'bakeryDesertType',
          (bakeryDesertType: string[], schema) =>
            bakeryDesertType?.[0]?.includes('cupCake')
              ? schema.required('Min value is required')
              : schema,
        ),
        cupCake_max: Yup.string().when(
          'bakeryDesertType',
          (bakeryDesertType: string[], schema) =>
            bakeryDesertType?.[0]?.includes('cupCake')
              ? schema.required('Max value is required')
              : schema,
        ),
        cookies_min: Yup.string().when(
          'bakeryDesertType',
          (bakeryDesertType: string[], schema) =>
            bakeryDesertType?.[0]?.includes('cookies')
              ? schema.required('Min value is required')
              : schema,
        ),
        cookies_max: Yup.string().when(
          'bakeryDesertType',
          (bakeryDesertType: string[], schema) =>
            bakeryDesertType?.[0]?.includes('cookies')
              ? schema.required('Max value is required')
              : schema,
        ),
        bakeryDeliveryOptionPickup: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        bakery_delivery_min: Yup.string().when(
          'bakeryDeliveryOptionPickup',
          (bakeryDeliveryOptionPickup: string[], schema) =>
            bakeryDeliveryOptionPickup?.[0]?.includes('delivery')
              ? schema.required('Min value is required')
              : schema,
        ),
        bakery_delivery_max: Yup.string().when(
          'bakeryDeliveryOptionPickup',
          (bakeryDeliveryOptionPickup: string[], schema) =>
            bakeryDeliveryOptionPickup?.[0]?.includes('delivery')
              ? schema.required('Max value is required')
              : schema,
        ),
      });

    case 'Entertainment':
      return commonValidation.shape({
        entertainmentOptions: Yup.string().required('Please select an option'),

        // Use Yup.array() for musiciansOptions
        musiciansOptions: Yup.array().when(
          'entertainmentOptions',
          (entertainmentOptions: string[], schema) =>
            entertainmentOptions?.[0] === 'Musician'
              ? schema
                  .min(1, 'Please select at least one option') // Ensure at least one selection
                  .required('Selection is required')
              : schema,
        ),

        musiciansHourlyFromRate: Yup.string().when(
          'entertainmentOptions',
          (entertainmentOptions: string[], schema) =>
            entertainmentOptions?.[0] === 'Musician'
              ? schema.required('Min value is required')
              : schema,
        ),

        musiciansHourlyToRate: Yup.string().when(
          'entertainmentOptions',
          (entertainmentOptions: string[], schema) =>
            entertainmentOptions?.[0] === 'Musician'
              ? schema.required('Max Rate is required')
              : schema,
        ),
      });

    case 'Party Favors':
      return commonValidation.shape({
        partyFavorToRate: Yup.string().required('Max value is required'),
        partyFavorFromRate: Yup.string().required('Min value is required'),
      });

    case 'Equipment Rentals':
      return commonValidation.shape({
        rentalMusiciansOptions: Yup.array()
          .min(1, 'Checkbox must be selected')
          .required('Selection is required'),
        equipmentRentalToRate: Yup.string().required('Max value is required'),
        equipmentRentalFromRate: Yup.string().required('Min value is required'),
      });

    default:
      return commonValidation;
  }
};

export default getValidationSchema;
