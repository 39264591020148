import React from 'react';

import BabyShower from '@/assets/png/Baby-shower.png';
import BirthdayParty from '@/assets/png/Birthday-party.png';
import BridalShower from '@/assets/png/Bridal-shower.png';
import FamilyReunion from '@/assets/png/Family-reunion.png';
import { Typography } from '@/components/Typography';

const EventSection = () => {
  return (
    <div className='my-20 flex flex-col justify-center items-center'>
      <Typography
        variant='heading'
        className='text-[26px] md:!text-[46px] md:mb-6'
      >
        {' '}
        Event inspiration
      </Typography>
      <div className='flex justify-center flex-wrap gap-5 xl:gap-10 mt-10 items-center'>
        <img
          src={BirthdayParty}
          alt='Ease plan Icon'
          className='w-40 md:w-[22%]'
        />
        <img
          src={BabyShower}
          alt='Save money Icon'
          className='w-40 md:w-[22%]'
        />
        <img
          src={BridalShower}
          alt='Save time Icon'
          className='w-40 md:w-[22%]'
        />
        <img src={FamilyReunion} alt='Place Icon' className='w-40 md:w-[22%]' />
      </div>
    </div>
  );
};

export default EventSection;
