import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Footer from '@/components/Footer';
import PlannerHeader from '@/components/PlannerHeader';
import PlannerHero from '@/components/PlannerHero';
import VendorSummary from '@/components/PlannerVendorSummary';
import Tabs from '@/components/Tabs';
import Container from '@/components/ui/Container';
import { ROUTES } from '@/constants';
import { getEventSummary } from '@/services/planner';

const PlannerSummary = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('eventId');
  const [eventSummary, setEventSummary] = useState([]);

  const getSummary = async () => {
    try {
      const { data, status }: any = await getEventSummary(id || '');
      if (status) {
        setEventSummary(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const data = eventSummary?.map((summary: any) => {
    return {
      ...summary,
      name: `${summary.category}: ${summary.businessName}`,
      price: '$8000',
      paid: '$4000',
      dueDate: '12/3/24',
      followUpDate: '11/20/24',
      status: 'Confirmed',
    };
  });

  useEffect(() => {
    getSummary();
  }, []);

  return (
    <div className='w-full'>
      <PlannerHeader />
      <div className='mb-20'>
        <PlannerHero
          length={10}
          route={`${ROUTES.PLANNER_HOME}?eventId=${id}`}
          apiRoute=''
        />
        <Container className='md:w-[95%] lg:w-4/5'>
          <Tabs sort={false} length={11} />
          <hr />

          <div className='w-full border rounded-[32px] p-2 mt-10 sm:p-8 pb-14 '>
            <VendorSummary vendors={data} />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PlannerSummary;
