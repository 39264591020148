import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import TextInputCombo from '@/components/ui/Input';

const PartyFavors = () => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };
  return (
    <div>
      <h1 className='block text-lg mt-6 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.PartyFavors.priceRangePerItem')}
      </h1>

      <div className='flex justify-between w-full gap-4 mb-3'>
        <TextInputCombo
          label=''
          name='partyFavorFromRate'
          placeholder={t('vendorSignUpQuestionnaire.min')}
          type='number'
        />
        <p className='mx-2.5 mt-5 md:mx-8'>
          {t('vendorSignUpQuestionnaire.to')}
        </p>
        <TextInputCombo
          label=''
          name='partyFavorToRate'
          placeholder={t('vendorSignUpQuestionnaire.max')}
          type='number'
        />
      </div>
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default PartyFavors;
