import React from 'react';

import PlanMeBackground from '@/assets/png/PlanMe-background.png';
import SaveProgressButton from '@/components/SaveProgressButton';
import { Typography } from '@/components/Typography';

const CounterSection = () => {
  return (
    <div>
      {' '}
      <div className='bg-white flex items-center xs:flex-row flex-col lg:gap-8 xl:gap-20 gap-5 ShadowBox rounded-lg p-3 lg:px-10 2xl:px-24 md:py-14 md:mt-16'>
        <div className='flex md:space-x-4 space-x-2 text-center text-gray-700'>
          <div>
            <span className='block text-3xl xs:text-xl md:text-4xl xl:text-6xl font-bold text-pink-600'>
              26
            </span>
            <span className='text-[10px] xs:text-[8px] md:text-xs lg:text-lg'>
              Days
            </span>
          </div>
          <span className='text-3xl xs:text-xl md:text-4xl xl:text-6xl text-[#EAEAEA] mt-2 md:mt-5'>
            :
          </span>
          <div>
            <span className='block  text-3xl xs:text-xl md:text-4xl xl:text-6xl font-bold text-red-500'>
              10
            </span>
            <span className='text-[10px] xs:text-[8px] md:text-xs lg:text-lg'>
              Hours
            </span>
          </div>
          <span className='text-3xl xs:text-xl md:text-4xl xl:text-6xl text-[#EAEAEA] mt-2 md:mt-5'>
            :
          </span>
          <div>
            <span className='block text-3xl xs:text-xl md:text-4xl xl:text-6xl font-bold text-orange-500'>
              36
            </span>
            <span className='text-[10px] xs:text-[8px] md:text-xs lg:text-lg'>
              Minutes
            </span>
          </div>
          <span className='text-3xl xs:text-xl md:text-4xl xl:text-6xl text-[#EAEAEA] mt-2 md:mt-5'>
            :
          </span>
          <div>
            <span className='block text-3xl xs:text-xl md:text-4xl xl:text-6xl font-bold text-yellow-500'>
              56
            </span>
            <span className='text-[10px] xs:text-[8px] md:text-xs lg:text-lg'>
              Seconds
            </span>
          </div>
        </div>
        <div className='xs:border-l xs:pl-5 lg:pl-10 2xl:pl-20'>
          <Typography
            variant='subheading'
            className='xs:text-[10px] text-center xs:text-start md:text-xl lg:text-2xl xl:!text-4xl block mb-2 md:mb-5'
          >
            Hire an event planning pro for 20% off
          </Typography>
          <SaveProgressButton
            text='Hire Now'
            smallText
            className='xs:w-fit w-full'
          />
        </div>
      </div>
      <img
        src={PlanMeBackground}
        alt='Hero Background'
        className='-mt-4 xl:-mt-16 lg:-mt-10 relative -z-10 '
      />
    </div>
  );
};

export default CounterSection;
