import React from 'react';
import { RouteObject } from 'react-router-dom';

import ProtectedRoute from './components/routerConfigs/ProtectedRoute';
import PublicRoute from './components/routerConfigs/PublicRoute';
import ForgotPassword from './pages/ForgotPassword';
import Guest from './pages/Guest';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import PlannerBar from './pages/PlannerBar';
import PlannerDashboard from './pages/PlannerDashboard';
import PlannerDecor from './pages/PlannerDecor';
import PlannerDesert from './pages/PlannerDesert';
import PlannerEntertainment from './pages/PlannerEntertainment';
import PlannerFood from './pages/PlannerFood';
import PlannerHome from './pages/PlannerHome';
import PlannerInvitation from './pages/PlannerInvitation';
import PlannerParty from './pages/PlannerParty';
import PlannerPhotography from './pages/PlannerPhotography';
import PlannerRegistry from './pages/PlannerRegistry';
import PlannerRsvp from './pages/PlannerRsvp';
import PlannerSummary from './pages/PlannerSummary';
import PlannerTheme from './pages/PlannerTheme';
import PlannerVenue from './pages/PlannerVenue';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';
import VendorDashboard from './pages/VendorDashboard';
import VendorRegister from './pages/VendorRegister';
import VendorSignupQuestionnaire from './pages/VendorSignupQuestionnaire';
import VerifyEmail from './pages/VerifyEmail';
import { ROUTES } from './constants';

const routes: RouteObject[] = [
  {
    path: ROUTES.LOGIN,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: ROUTES.REGISTER,
    element: (
      <PublicRoute>
        <Register />
      </PublicRoute>
    ),
  },
  {
    path: ROUTES.VERIFY_EMAIL,
    element: (
      // <PublicRoute>
      <VerifyEmail />
      // </PublicRoute>
    ),
  },
  {
    path: ROUTES.VENDOR_REGISTER,
    element: (
      // <ProtectedRoute>
      <VendorRegister />
      // </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.VENDOR_SIGNUP_QUESTIONNAIRE,
    element: (
      // <ProtectedRoute>
      <VendorSignupQuestionnaire />
      // </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    ),
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    path: ROUTES.VENDOR_DASHBOARD,
    element: (
      <ProtectedRoute>
        <VendorDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_HOME,
    element: (
      <ProtectedRoute>
        <PlannerHome />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_DASHBOARD,
    element: (
      <ProtectedRoute>
        <PlannerDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_GUEST,
    element: (
      <ProtectedRoute>
        <Guest />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_THEME,
    element: (
      <ProtectedRoute>
        <PlannerTheme />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_VENUE,
    element: (
      <ProtectedRoute>
        <PlannerVenue />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_DECOR,
    element: (
      <ProtectedRoute>
        <PlannerDecor />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_FOOD,
    element: (
      <ProtectedRoute>
        <PlannerFood />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_DESERT,
    element: (
      <ProtectedRoute>
        <PlannerDesert />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_BAR,
    element: (
      <ProtectedRoute>
        <PlannerBar />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_PHOTOGRAPHY,
    element: (
      <ProtectedRoute>
        <PlannerPhotography />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_ENTERTAINMENT,
    element: (
      <ProtectedRoute>
        <PlannerEntertainment />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_PARTY,
    element: (
      <ProtectedRoute>
        <PlannerParty />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_REGISTRY,
    element: (
      <ProtectedRoute>
        <PlannerRegistry />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_INVITATION,
    element: (
      <ProtectedRoute>
        <PlannerInvitation />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_RSVP,
    element: (
      <ProtectedRoute>
        <PlannerRsvp />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PLANNER_SUMMARY,
    element: (
      <ProtectedRoute>
        <PlannerSummary />
      </ProtectedRoute>
    ),
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
