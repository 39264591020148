import React, { useState } from 'react';
import { BsDot } from 'react-icons/bs';
import { FaRegImage } from 'react-icons/fa6';
import { FiX } from 'react-icons/fi';
import Viewer from 'react-viewer';

interface FileData {
  name: string;
  size: string;
  url: string;
}

interface FileUploaderProps {
  onFilesUpdate: (files: string[]) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({ onFilesUpdate }) => {
  const [files, setFiles] = useState<string[]>([]);
  const [fileDetails, setFileDetails] = useState<FileData[]>([]);
  const [previewIndex, setPreviewIndex] = useState<number | null>(null);

  const handleRemove = (index: number) => {
    const newFiles = [...files];
    const newFileDetails = [...fileDetails];
    newFiles.splice(index, 1);
    newFileDetails.splice(index, 1);
    setFiles(newFiles);
    setFileDetails(newFileDetails);
    onFilesUpdate(newFiles);
  };

  const truncateFileName = (name: string, maxLength = 20) => {
    if (name.length <= maxLength) return name;
    const splitValue = name.split('.');
    const last = splitValue[splitValue?.length - 1];
    const truncated = `${name.slice(0, 15)}...${last}`;
    return truncated;
  };

  const handleFiles = (fileList: File[]) => {
    const filePromises = Array.from(fileList).map((file) => {
      return new Promise<{ base64: string; details: FileData }>(
        (resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              base64: reader.result as string,
              details: {
                name: file.name,
                size: `${(file.size / 1024 / 1024).toFixed(1)}MB`,
                url: URL.createObjectURL(file),
              },
            });
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        },
      );
    });

    Promise.all(filePromises).then((uploadedFiles) => {
      const newFiles = [...files, ...uploadedFiles.map((file) => file.base64)];
      const newFileDetails = [
        ...fileDetails,
        ...uploadedFiles.map((file) => file.details),
      ];
      setFiles(newFiles);
      setFileDetails(newFileDetails);
      onFilesUpdate(newFiles);
    });
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.from(event.target.files || []);
    handleFiles(fileList);
    event.target.value = '';
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    const fileList = Array.from(event.dataTransfer.files);
    handleFiles(fileList);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  return (
    <div className='w-full'>
      <h2 className='font-semibold text-gray-800 mb-2'>Attachments</h2>
      <div className='space-y-2'>
        {fileDetails.map((file, index) => (
          <div
            key={index}
            className='flex items-center justify-between px-4 py-3 border border-gray-300 rounded-md'
          >
            <div className='flex items-center space-x-2'>
              <FaRegImage className='text-blue-500 w-5 h-5 font-bold' />
              <span className='text-gray-700 pl-2'>
                {truncateFileName(file.name)}
              </span>
              <div className='flex items-center'>
                <BsDot />
                <span
                  onClick={() => setPreviewIndex(index)}
                  className='text-blue-500 underline pl-1 cursor-pointer'
                >
                  Preview
                </span>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <span className='text-gray-500 text-sm'>{file.size}</span>
              <button
                onClick={() => handleRemove(index)}
                className='text-gray-400 hover:text-red-500'
              >
                <FiX />
              </button>
            </div>
          </div>
        ))}
      </div>
      <label
        htmlFor='file-upload'
        className='mt-4 block text-center py-20 bg-[#FBFBFB] border border-gray-300 rounded-md cursor-pointer'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <span className='text-blue-500 '>Click here</span> to upload or drop
        files here
      </label>
      <span className='text-gray-300 text-sm'>Accept jpg, jpeg, and png</span>
      <input
        id='file-upload'
        type='file'
        accept='.jpg, .jpeg, .png'
        multiple
        className='hidden'
        onChange={handleFileUpload}
      />

      {/* Image Viewer */}
      {previewIndex !== null && (
        <Viewer
          visible={true}
          onClose={() => setPreviewIndex(null)}
          images={fileDetails.map((file) => ({
            src: file.url,
            alt: file.name,
          }))}
          activeIndex={previewIndex}
        />
      )}
    </div>
  );
};

export default FileUploader;
