import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronUp, Menu } from 'lucide-react';

import Logo from '@/assets/png/Logo.png';
import SuitcaseSVG from '@/assets/svg/Suitcase';
import { useConstantData } from '@/hooks/useLocalizedDummyData';
import { clearStorage } from '@/utils/common';
import { JSONParse } from '@/utils/jsonparse';

const PlannerHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const data = localStorage?.getItem('user');
  const user: any = data ? JSONParse(data) : null;

  const { headerDropdownItems, plannerHeaderMenuItems } = useConstantData();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    clearStorage();
    navigate(0);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className='w-full bg-white'>
      <nav className='container mx-auto flex justify-between items-center p-4'>
        <div onClick={() => navigate('/planner')}>
          <img src={Logo} alt='logo-img' className='cursor-pointer' />
        </div>

        <ul className='hidden lg:flex xl:space-x-8 space-x-5 text-lg'>
          {plannerHeaderMenuItems.map((item, index) => (
            <li key={index}>
              <p
                onClick={() => navigate(item.href)}
                className='font-medium text-sm xl:text-base text-darkGray font-sans'
              >
                {item.label}
              </p>
            </li>
          ))}
        </ul>

        <div className='relative hidden lg:flex' ref={dropdownRef}>
          <button
            className='flex items-center gap-3 focus:outline-none'
            onClick={toggleDropdown}
          >
            <SuitcaseSVG />
            <p className='font-medium text-sm xl:text-base  text-headingColor font-sans'>
              {user?.firstName} {user?.lastName}
            </p>
            {isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
          </button>

          {isDropdownOpen && (
            <div className='absolute right-0 mt-7 w-48 bg-white border rounded-md shadow-lg z-10'>
              <ul className='py-1'>
                {headerDropdownItems
                  ?.slice(1, headerDropdownItems?.length)
                  .map((item, index) => (
                    <li key={index}>
                      <p
                        onClick={() => navigate(item.href)}
                        className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer'
                      >
                        {item.label}
                      </p>
                    </li>
                  ))}
                <li>
                  <button
                    onClick={handleLogout}
                    className='block px-4 py-2 text-sm text-red hover:bg-gray-100 w-full text-start'
                  >
                    {t('Constant.headerDropdownItems.logout')}
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className='lg:hidden bg-[#656DA3] border text-cream border-[#656DA3] rounded-xl p-2'>
          <Menu onClick={toggleMobileMenu} />
        </div>
      </nav>

      {isMobileMenuOpen && (
        <div
          className='fixed inset-0 bg-white z-20 flex flex-col items-start justify-start p-10 space-y-8'
          ref={dropdownRef}
        >
          <button className='absolute top-4 right-4' onClick={toggleMobileMenu}>
            <svg
              className='w-6 h-6'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
          <div className='relative flex md:hidden' ref={dropdownRef}>
            <button
              className='flex items-center gap-3 focus:outline-none'
              onClick={toggleDropdown}
            >
              <SuitcaseSVG />
              <p className='font-medium text-base text-headingColor font-sans'>
                {'Russel Cole'}
              </p>
              {isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
            </button>

            {isDropdownOpen && (
              <div className='absolute right-0 mt-8 w-48 bg-white border rounded-md shadow-lg z-10'>
                <ul className='py-1'>
                  {headerDropdownItems.map((item, index) => (
                    <li key={index}>
                      <p
                        onClick={() => navigate(item.href)}
                        className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer'
                      >
                        {item.label}
                      </p>
                    </li>
                  ))}
                  <li>
                    <button
                      onClick={handleLogout}
                      className='block px-4 py-2 text-sm text-red hover:bg-gray-100 w-full text-start'
                    >
                      {t('Constant.headerDropdownItems.logout')}
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <ul className='text-lg flex flex-col gap-3'>
            {plannerHeaderMenuItems.map((item, index) => (
              <li key={index}>
                <p
                  onClick={() => navigate(item.href)}
                  className='font-medium text-base text-darkGray font-sans cursor-pointer'
                >
                  {item.label}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default PlannerHeader;
