export const apiEndpoints = {
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  VERIFY_EMAIL: '/auth/verify-email',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CHANGE_PASSWORD: 'auth/change-password',
  CHECK_AUTH: '/auth/check-auth',
  BUSINESS_VENDOR: '/business',
  GET_GUEST_LIST: '/event-planning/guest',
  ADD_GUEST_LIST: '/event-planning/guest',
  UPLOAD_CSV: '/event-planning/guest-list-file',
  DELETE_GUEST: '/event-planning/guest',
  DELETE_ALL_GUEST: '/event-planning/guest-all',
  GET_THEME_LIST: '/event-planning/theme',
  GET_VENUE_LIST: '/business/venue',
  GET_DECOR_LIST: '/business/decor',
  GET_FOOD_LIST: '/business/food',
  GET_DESERT_LIST: '/business/bakery',
  GET_BAR_LIST: '/business/drink-service',
  GET_PHOTO_LIST: '/business/photographer',
  GET_ENTERTAINMENT_LIST: '/business/entertainment',
  GET_PARTY_LIST: '/business/party-favors',
  CREATE_EVENT: '/event-planning/create',
  GET_EVENTS: '/event-planning/event',
  GET_SUMMARY: '/event-planning/summery',
  GET_ALL_EVENTS: '/event-planning/events',
};
