import React from 'react';
// import SignupQuestionnaireForm from "./modules/signup-form";
import { useTranslation } from 'react-i18next';

import Logo from '@/assets/png/Logo.png';
import CopyRightFooter from '@/components/CopyRightFooter';

import SignupQuestionnaireForm from './modules/signup-form';

const VendorSignupQuestionnaire = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col min-h-screen'>
      <div className='border-b border-solid border-gray-100 h-16' />
      <div className='flex w-full flex-col justify-center items-center p-3.5 mt-4'>
        <img src={Logo} alt='Main Logo' width={157} height={144} />
        <p className='text-base text-paragraphText my-4'>
          {t('vendorSignUpQuestionnaire.tellUsMoreAboutBusiness')}
        </p>
      </div>
      <div className='flex-grow mb-10'>
        <SignupQuestionnaireForm />
      </div>
      <CopyRightFooter />
    </div>
  );
};

export default VendorSignupQuestionnaire;
