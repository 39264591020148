/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';

const Modal = ({
  open,
  onClose,
  children,
}: {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}) => {
  if (!open) return null;

  const handleBackdropClick = (event: any) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-5 flex justify-center items-center z-50'
      onClick={handleBackdropClick}
    >
      <div
        className='bg-white rounded-2xl relative w-4/5 h-[90vh] overflow-auto overflow-x-hidden shadow-lg'
        onClick={(e) => e.stopPropagation()}
      >
        {/* <div className='flex justify-between px-6 py-1'>
          <button
            onClick={onClose}
            className='absolute top-0 right-4 text-xl font-bold text-gray-600'
          >
            &times;
          </button>
        </div> */}
        <div className='p-6'>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
