import React from 'react';

import AboutBackground from '@/assets/png/About-background.png';
import Business from '@/assets/svg/Business';
import Guidance from '@/assets/svg/Guidance';
import Planning from '@/assets/svg/Planning';
import { Typography } from '@/components/Typography';

const About = () => {
  return (
    <div className='md:my-24 my-10 lg:flex gap-10 xl:gap-20'>
      <img
        src={AboutBackground}
        alt='Hero Background'
        className='hidden lg:block w-2/5 xl:w-fit'
      />
      <div className='max-w-[528px]'>
        <Typography
          variant='heading'
          className='block text-2xl md:!text-5xl font-semibold !leading-[56px]'
        >
          What we’re about
        </Typography>
        <Typography className='text-eveningEast text-xs md:text-base md:my-3 block'>
          GoPlanMe is on a mission to make event planning easier for everyone
          involved by:
        </Typography>
        <div className='flex gap-2 items-start py-2 md:my-5'>
          <Guidance />
          <div className='mt-4'>
            <Typography variant='subheading' className='block'>
              Providing guidance
            </Typography>
            <Typography className='text-eveningEast text-xs md:text-base md:my-1 block'>
              Providing guidance and support to users throughout the event
              planning process.
            </Typography>
          </div>
        </div>
        <div className='flex gap-2 items-start py-2 md:my-5'>
          <Business />
          <div className='mt-4'>
            <Typography variant='subheading' className='block'>
              Industry Business Support
            </Typography>
            <Typography className='text-eveningEast text-xs md:text-base md:my-1 block'>
              Creating more visibility for event industry business owners,
              connecting them with a wider range of customers.
            </Typography>
          </div>
        </div>
        <div className='flex gap-2 items-start py-2 md:my-5'>
          <Planning />
          <div className='mt-4'>
            <Typography variant='subheading' className='block'>
              Providing affordable event planning
            </Typography>
            <Typography className='text-eveningEast text-xs md:text-base md:my-1 block'>
              Providing affordable event planning pros as an option for users
              who don’t want to plan their own events
            </Typography>
          </div>
        </div>
      </div>
      <img
        src={AboutBackground}
        alt='Hero Background'
        className='lg:hidden w-3/4 mx-auto'
      />
    </div>
  );
};

export default About;
