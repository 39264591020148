import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'lucide-react';

import InfoSVG from '@/assets/svg/Info';
import { Typography } from '@/components/Typography';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

import DashboardBar from './Bar';

const StatsSection = () => {
  const { dashboardUpcomingBooking } = useConstantData();
  const { t } = useTranslation();
  return (
    <div>
      <div className='grid gap-6 grid-cols-1'>
        <div className='bg-light-bg p-5 md:p-10 rounded-3xl'>
          <div className='bg-white px-10 drop-shadow-md rounded-3xl p-6'>
            <div className='mb-5'>
              <Typography variant='heading' className='text-xl md:text-[32px]'>
                {`${t('dashboard.needAttention')} (4)`}
              </Typography>
            </div>
            <div className='space-y-3'>
              <div className='flex justify-between items-center border p-4 rounded-lg shadow-sm bg-gray-100 hover:bg-gray-200'>
                <span>{`1 ${t('dashboard.unreadMsg')}`}</span>
                <a href='#'>
                  <ChevronRight color='#D2D3D6' />
                </a>
              </div>
              <div className='flex justify-between items-center border p-4 rounded-lg shadow-sm bg-gray-100 hover:bg-gray-200'>
                <span>{`3 ${t('dashboard.BookingsRequests')}`}</span>
                <a href='#'>
                  <ChevronRight color='#D2D3D6' />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-light-bg p-5 md:p-7 lg:p-10 rounded-3xl'>
          <div className='drop-shadow-md bg-white px-5 md:px-6 lg:px-10 py-6 rounded-3xl '>
            <div className='flex flex-col sm:flex-row sm:items-center justify-between mb-4'>
              <Typography variant='heading'>
                {t('dashboard.Financials')}
              </Typography>
              <a
                href='#'
                className='text-textDarkGray mx-auto sm:ml-2 sm:mr-0 md:text-nowrap font-medium text-sm lg:text-base underline flex items-center gap-1'
              >
                {t('dashboard.viewAccountActivity')}
                <ChevronRight size={18} />
              </a>
            </div>

            <div className='mb-4 rounded-lg flex items-center lg:px-12 justify-center'>
              <DashboardBar />
            </div>

            <div className='flex flex-wrap gap-4 md:gap-10 mt-6 mb-3 md:ml-5'>
              <div className='flex items-center gap-4 mb-2'>
                <span className='text-paragraphText font-sans font-medium  text-base sm:text-xl'>
                  {t('dashboard.incomingCash')}
                </span>
                <span className='border border-lightPurple bg-cream font-bold text-base md:text-[22px] text-textBlack border-dashed py-1 px-1 sm:px-2 rounded-lg'>
                  $600
                </span>
              </div>
              <div className='flex items-center gap-4 mb-2'>
                <span className='text-paragraphText font-sans font-medium text-base sm:text-xl'>
                  {`July ${t('dashboard.monthEarning')}`}
                </span>
                <span className='border border-lightPurple bg-cream font-bold text-base md:text-[22px] text-textBlack border-dashed py-1 px-1 sm:px-2 rounded-lg'>
                  $3,865.85
                </span>
              </div>
            </div>
            <div className='flex items-center gap-4 mb-2 md:ml-5'>
              <span className='text-paragraphText font-sans font-medium text-base sm:text-xl'>
                {t('dashboard.YTDEarnings')}
              </span>
              <span className='border border-lightPurple bg-cream font-bold text-base md:text-[22px] text-textBlack border-dashed py-1 px-1 sm:px-2 rounded-lg'>
                $12,926.53
              </span>
            </div>
            <p className='text-headingColor text-base font-semibold mt-4 mb-3'>
              {t('dashboard.incomingCashProcessing')}
            </p>
            <p className='text-xs flex items-center gap-2 text-headingColor'>
              <InfoSVG />
              {t('dashboard.info')}
            </p>
          </div>
        </div>
      </div>

      <div className='bg-light-bg my-6 p-5 md:p-10 rounded-3xl'>
        <div className='drop-shadow-md bg-white px-5 md:px-10 py-6 rounded-3xl '>
          <Typography variant='heading' className='text-xl md:text-[32px]'>
            {t('dashboard.upcomingBooking')}
          </Typography>
          <div className='mt-6'>
            {dashboardUpcomingBooking.map((item, index) => (
              <div
                key={index}
                className='flex justify-between  border p-2 md:p-4 my-2 rounded-lg shadow-sm hover:bg-gray-100'
              >
                <Typography className='font-normal md:font-semibold text-astrogranite text-xs md:text-base'>
                  {item.date}
                </Typography>
                <Typography className='font-normal md:font-semibold text-astrogranite text-xs md:text-base'>
                  {item.service}
                </Typography>
                <Typography className='font-normal md:font-semibold text-astrogranite text-xs md:text-base'>
                  {item.price}
                </Typography>
                <ChevronRight size={20} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
