/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosResponse } from 'axios';

import { APIResponse } from '@/@types';
import { COMMON_ERROR } from '@/constants/feedback';
import { getTokenFromLS } from '@/utils/common';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

export class API {
  static SerializeResponse<T>(
    response: AxiosResponse<T> | null,
    message: string,
  ): APIResponse<T> {
    const data = response as any;
    if (data.error) {
      throw new Error(message);
    }
    return data;
  }

  static async Post<RQ, RES>(
    url: string,
    payload: RQ,
    isFormData?: boolean,
  ): Promise<APIResponse<RES>> {
    try {
      const headers = await this.getHeaders();
      if (isFormData) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const res = await axios.post<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Get<RES>(url: string) {
    try {
      const headers = await this.getHeaders();
      const res = await axios.get<RES>(url, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Put<RES, REQ = undefined>(
    url: string,
    payload?: REQ,
    isImage?: boolean,
  ) {
    try {
      const headers = await this.getHeaders();
      if (isImage) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const res = await axios.put<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Patch<RES, REQ = undefined>(url: string, payload?: REQ) {
    try {
      const headers = await this.getHeaders();
      const res = await axios.patch<RES>(url, payload, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async Delete<RES>(url: string) {
    try {
      const headers = await this.getHeaders();
      const res = await axios.delete<RES>(url, {
        headers,
      });
      const response = this.SerializeResponse<RES>(res, '');
      return {
        data: response?.data,
        status: true,
        message: '',
      };
    } catch (error: any) {
      return {
        data: null,
        status: false,
        message: error.response?.data?.message || COMMON_ERROR,
      };
    }
  }

  static async getHeaders(): Promise<Record<string, string>> {
    const authToken = await this.getAuthToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': authToken,
    };
  }

  static async getAuthToken(): Promise<string> {
    const token = await getTokenFromLS();
    return `Bearer ${token}`;
  }
}
