/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { venueFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setVenueFilters } from '@/redux/slices/filters';
import { GetVenueList } from '@/services/planner';

const PlannerVenue = () => {
  const dispatch = useAppDispatch();
  const { venueFilters, venueNameSearch } = useAppSelector(
    (state) => state.filters,
  );
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  const [venueLists, setVenueLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getVenues = async () => {
    setLoading(true);
    const { data }: any = await GetVenueList({
      page,
      ...venueFilters,
      title: venueNameSearch,
    });
    setVenueLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getVenues();
  }, [page, venueFilters]);

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      getVenues();
    }, 1000);

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [venueNameSearch]);

  const setFilters = (value: any) => {
    dispatch(setVenueFilters(value));
  };

  const data = venueLists?.map((list: any) => {
    return {
      ...list,
      title: list?.businessName,
      imgUrl: list?.images?.length ? list?.images[0] : '',
      tags: [list?.spaces[0].environment],
    };
  });

  return (
    <PlannerLayout
      tabLength={3}
      heroRoute={ROUTES.PLANNER_DECOR}
      heroLength={2}
      skipText='Venue already secured?'
      cardData={data}
      sections={venueFilterSections}
      setFilters={setFilters}
      filters={venueFilters}
      cardType='venue'
      search
      setPage={setPage}
      totalTablePages={totalPages}
      apiRoute='venue'
      loading={loading}
    />
  );
};

export default PlannerVenue;
