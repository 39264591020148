import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, useRoutes } from 'react-router-dom';

import { store } from './redux/store';
import ToastContainer from './utils/toast';
import routes from './Route';

import './assets/styles/App.css';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer />
        <RoutesWrapper />
      </BrowserRouter>
    </Provider>
  );
};

const RoutesWrapper = () => {
  const routing = useRoutes(routes);
  return routing;
};

export default App;
