import React, { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  return (
    <div className={`md:w-4/5 mx-3 md:mx-auto mt-10 ${className}`}>
      {children}
    </div>
  );
};

export default Container;
