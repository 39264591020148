import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Amazon from '@/assets/png/Amazon.png';
import Macy from '@/assets/png/Macy.png';
import Target from '@/assets/png/Target.png';
import Wallmart from '@/assets/png/Wallmart.png';
import Footer from '@/components/Footer';
import PlannerHeader from '@/components/PlannerHeader';
import PlannerHero from '@/components/PlannerHero';
import Tabs from '@/components/Tabs';
import { Typography } from '@/components/Typography';
import Container from '@/components/ui/Container';
import { ROUTES } from '@/constants';

const PlannerRegistry = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('eventId');
  return (
    <div className='w-full'>
      <PlannerHeader />
      <div className='mb-20'>
        <PlannerHero
          length={10}
          route={ROUTES.PLANNER_INVITATION}
          apiRoute=''
        />
        <Container>
          <Tabs sort={false} length={11} />
          <Typography
            variant='subheading'
            className='text-center font-medium text-textDarkGray mb-4 block'
          >
            Registry not needed?
            <span
              className='cursor-pointer'
              onClick={() => {
                window.location.href = ROUTES.PLANNER_INVITATION;
                navigate(`${ROUTES.PLANNER_INVITATION}?eventId=${id}`);
              }}
            >
              {' '}
              Skip this step
            </span>
          </Typography>

          <div className='w-full border border-borderColor rounded-[32px] p-10 pb-14 '>
            <div className='flex gap-8 justify-center'>
              <img src={Target} alt='' />
              <img src={Amazon} alt='' />
              <img src={Wallmart} alt='' />
              <img src={Macy} alt='' />
            </div>
            <div className='flex justify-center mt-16'>
              <button className='bg-borderColor p-4 text-lg w-48 rounded-xl text-darkHeadingColor font-semibold'>
                Other
              </button>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PlannerRegistry;
