import * as React from 'react';

const SuitcaseSVG = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={23}
    height={24}
    viewBox='0 0 23 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.4498 13.1016H10.5513C10.4239 13.1016 10.3203 13.2052 10.3203 13.3326V15.2396C10.3203 15.367 10.4239 15.4707 10.5513 15.4707H12.4498C12.5772 15.4707 12.6809 15.367 12.6809 15.2396V13.3326C12.6809 13.2052 12.5772 13.1016 12.4498 13.1016Z'
      fill='#45445C'
    />
    <path
      d='M19.4518 5.30635H15.5361V3.66791C15.5361 2.81175 14.8329 2.11523 13.9685 2.11523H9.03145C8.16707 2.11523 7.46386 2.81175 7.46386 3.66791V5.30639H3.54824C1.59176 5.30635 0 6.89811 0 8.85464V10.9813C0.131711 11.6226 0.594766 12.1505 1.23558 12.3556L8.96798 14.2453V13.334C8.96798 12.4613 9.67801 11.7512 10.5508 11.7512H12.4492C13.322 11.7512 14.032 12.4613 14.032 13.334V14.2453L21.7644 12.3556C22.4052 12.1505 22.8683 11.6226 23 10.9813V8.85464C23 6.89811 21.4082 5.30635 19.4518 5.30635ZM14.1844 5.30639H8.81565V3.66791C8.81565 3.55336 8.90841 3.46698 9.03145 3.46698H13.9685C14.0916 3.46698 14.1844 3.55336 14.1844 3.66791V5.30639Z'
      fill='#45445C'
    />
    <path
      d='M22.1456 13.6525C22.1327 13.6565 22.1197 13.6601 22.1065 13.6633L13.9782 15.6499C13.7974 16.325 13.1806 16.8236 12.4492 16.8236H10.5508C9.81943 16.8236 9.20261 16.325 9.02184 15.6499L0.893451 13.6633C0.880334 13.6601 0.867307 13.6565 0.854369 13.6525C0.544004 13.5566 0.257582 13.417 0 13.2422V18.9874C0 20.5855 1.30017 21.8857 2.89836 21.8857H20.1016C21.6998 21.8857 23 20.5856 23 18.9874V13.2422C22.7424 13.417 22.456 13.5566 22.1456 13.6525Z'
      fill='#45445C'
    />
  </svg>
);
export default SuitcaseSVG;
