import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import CheckboxCombo from '@/components/ui/Checkbox';
import TextInputCombo from '@/components/ui/Input';
import InputRadioCombo from '@/components/ui/TextRadio';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DecorForm = ({ values }: any) => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const {
    accentOptions,
    ballonOptions,
    checkboxOptions,
    decorRentalOptions,
    fullServiceOptions,
    lightingOptions,
    partyFavorsOptions,
  } = useConstantData();

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };
  return (
    <div className='md:w-[440px]'>
      <h1 className='block text-lg my-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.decor.serviceOffered')}
      </h1>
      <InputRadioCombo
        name='selectedServiceOption'
        options={fullServiceOptions}
        label={t('vendorSignUpQuestionnaire.decor.fullServiceDecor')}
      />
      {values.selectedServiceOption === 'yes' && (
        <div className='my-3'>
          <TextInputCombo
            label='Average price per person'
            inputStyle='w-full sm:w-[200px]'
            name='averagePricePerPerson'
            placeholder={t('vendorSignUpQuestionnaire.min')}
            type='number'
            min={0}
          />
        </div>
      )}
      <InputRadioCombo
        name='selectedBallonOption'
        containerStyle='mt-2'
        options={ballonOptions}
        label={t('vendorSignUpQuestionnaire.decor.ballonOptions')}
      />
      <InputRadioCombo
        name='selectedLightingOption'
        containerStyle='mt-2'
        options={lightingOptions}
        label={t('vendorSignUpQuestionnaire.decor.lightingOptions')}
      />
      <InputRadioCombo
        name='selectedPartyOption'
        containerStyle='mt-2'
        options={partyFavorsOptions}
        label={t('vendorSignUpQuestionnaire.decor.partyFavors')}
      />
      <InputRadioCombo
        name='selectedDecorOption'
        containerStyle='mt-2'
        options={decorRentalOptions}
        label={t('vendorSignUpQuestionnaire.decor.decorRentals')}
      />

      {values.selectedDecorOption === 'yes' && (
        <CheckboxCombo
          name='selectedDecorsubOption'
          containerStyle='mt-3'
          options={checkboxOptions}
          label={t('vendorSignUpQuestionnaire.decor.selectOptions')}
        />
      )}

      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.decor.accentsDecorPieces')}
      </h1>

      <CheckboxCombo
        name='selectedAccentsOptions'
        options={accentOptions}
        label=''
        typeOther
      />
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default DecorForm;
