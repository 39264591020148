import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingSpinner from '@/components/LoadingSpinner';
import { ROUTES } from '@/constants';
import { useAppSelector } from '@/hooks/redux';
import {
  filterSignupDataForVendor,
  filterValuesByCategory,
} from '@/services/mapData';
import { vendorSignup } from '@/services/vendorService';

import { VENDOR_SIGNUP_INITIAL_VALUES } from '../initial-values';

import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';

const SignupQuestionnaireForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(VENDOR_SIGNUP_INITIAL_VALUES);
  const { user } = useAppSelector((state) => state.auth);

  const location = useLocation();
  const { title } = location.state || {};

  const initialValues = {
    category: title!,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    business: '',
    images: [],
    title: '',
    location: '',
    website: '',
    description: '',
    socialMedia: [{ url: '', platform: '' }],
    phone: '',
    password: '',
    confirmPassword: '',
  };

  // Handle next step transition by saving data from step 1
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNextStep = (values: any) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setCurrentStep(() => 2);
  };

  const navigate = useNavigate();
  const handleApiCategoryKey = (category: string, data: any) => {
    switch (category) {
      case 'Food Service':
        return {
          ...data,
          food: data['food service'],
        };
      case 'Drink Services':
        return {
          ...data,
          drinkServices: data['drink services'],
        };
      case 'Photographer/Videographer':
        return {
          ...data,
          photographer: data['photographer/videographer'],
        };
      case 'Bakery/Treats':
        return {
          ...data,
          bakery: data['bakery/treats'],
        };

      default:
        return data;
    }
  };

  // Handle form submission for the final step
  const handleFormSubmit = async (
    values: typeof VENDOR_SIGNUP_INITIAL_VALUES,
  ) => {
    setIsLoading(true);
    // Combine step 1 and step 2 data
    const finalData = {
      ...formData,
      ...values,
      phone: formData?.phone?.toString(),
    };

    const filteredValues = filterValuesByCategory(
      finalData,
      finalData.category,
    );
    const apiData = handleApiCategoryKey(finalData.category, filteredValues);
    delete apiData['food service'];
    delete apiData['bakery/treats'];
    delete apiData['photographer/videographer'];
    const signupData = await filterSignupDataForVendor(apiData);

    const response = await vendorSignup({
      signupData,
      createBusinessData: apiData,
    });

    if (response) navigate(ROUTES.LOGIN);

    setIsLoading(false);
  };

  return (
    <div className='flex items-center justify-center w-full px-3 md:flex-col'>
      {isLoading && <LoadingSpinner />}
      <div className='flex w-11/12 md:w-auto items-center self-center justify-center md:flex-col'>
        <div className='mt-1.5 flex flex-col items-center self-center w-full'>
          {currentStep === 1 ? (
            <StepOneForm
              initialValues={initialValues}
              onNext={handleNextStep}
            />
          ) : (
            <StepTwoForm initialValues={formData} onSubmit={handleFormSubmit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupQuestionnaireForm;
