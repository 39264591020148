/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { drinkFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setBarFilters } from '@/redux/slices/filters';
import { GetBarList } from '@/services/planner';

const PlannerBar = () => {
  const dispatch = useAppDispatch();
  const { barFilters } = useAppSelector((state) => state.filters);
  const [desertLists, setDesertLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);

  const getVenues = async () => {
    setLoading(true);
    const { data }: any = await GetBarList({ page, ...barFilters });
    setDesertLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getVenues();
  }, [barFilters, page]);

  const setFilters = (value: any) => {
    dispatch(setBarFilters(value));
  };

  const data = desertLists?.map((list: any) => {
    return {
      ...list,
      title: list?.businessName,
      imgUrl: list?.images?.length ? list?.images[0] : '',
      cardData: { subtitle: list?.description },
    };
  });
  return (
    <PlannerLayout
      tabLength={7}
      heroRoute={ROUTES.PLANNER_PHOTOGRAPHY}
      heroLength={7}
      skipText='Drinks already secured?'
      cardData={data}
      sections={drinkFilterSections}
      cardType='decor'
      distance
      setPage={setPage}
      totalTablePages={totalPages}
      setFilters={setFilters}
      filters={barFilters}
      apiRoute='drink'
      multiSelect
      map={false}
      loading={loading}
    />
  );
};

export default PlannerBar;
