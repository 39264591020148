import React from 'react';

import Logo from '@/assets/png/Logo.png';
import CopyRightFooter from '@/components/CopyRightFooter';

import LoginForm from './modules/login-form';

const Login = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex w-full flex-col justify-center items-center border-b border-solid border-gray-100 p-3.5 mb-4'>
        <img src={Logo} alt='Main Logo' width={157} height={144} />
      </div>
      <div className='flex-grow mb-10'>
        <LoginForm />
      </div>
      <CopyRightFooter />
    </div>
  );
};

export default Login;
