import React from 'react';

import Dashboard_Bg from '@/assets/images/dashboard-bg.webp';
import PageTitle from '@/components/PageTitle';

import MenuSection from './menu';
import StatsSection from './Stats';

const Dashboard = () => {
  return (
    <div>
      <img src={Dashboard_Bg} alt='Dashboard BG' />
      <div className='-mt-6 md:-mt-14'>
        <PageTitle title='Vendor Dashboard' />
      </div>
      <div className='flex justify-center flex-col md:flex-row gap-4 lg:gap-14 my-20 mx-5'>
        <MenuSection />
        <StatsSection />
      </div>
    </div>
  );
};

export default Dashboard;
