import Bin from '@/assets/svg/Bin';
import GuestEdit from '@/assets/svg/GuestEdit';
import Loader from '@/assets/svg/loader';
import Search from '@/assets/svg/Search';
import Sorting from '@/assets/svg/Sorting';
import { Typography } from '@/components/Typography';
import Button from '@/components/ui/button';
import { GuestState } from '@/utils/types/storeStateInterface';

const GuestTable: React.FC<{
  // guests: GuestState[];
  filteredGuests: GuestState[];
  onDeleteGuest: (id: string) => void;
  onDeleteAll: () => void;
  onSort: () => void;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
  loading: boolean;
  deleteLoading: string;
  onViewAll: () => void;
  totalCount: number;
  loader: boolean;
  // handleSelect: (id: string | undefined) => void;
  // selectedValue: string[];
}> = ({
  filteredGuests,
  onDeleteGuest,
  onDeleteAll,
  onSort,
  onSearchChange,
  searchTerm,
  loading,
  deleteLoading,
  onViewAll,
  totalCount,
  loader,
  // handleSelect,
  // selectedValue,
}) => (
  <div className='md:w-3/5'>
    <div className='flex justify-between'>
      <Typography variant='subheading'>Added Guests</Typography>
      <Typography variant='subheading'>Total: {totalCount}</Typography>
    </div>
    <div className='mt-4 flex md:h-11 h-9 gap-2 md:gap-5'>
      <div className='rounded-lg w-full h-full flex px-2 md:px-4 items-center focus:outline-none border mb-3 border-[#D2D3D6]'>
        <input
          type='text'
          placeholder='Search Guests'
          className='py-2 md:py-3 text-xs md:text-base w-full h-full rounded-lg focus:outline-none'
          value={searchTerm}
          onChange={onSearchChange}
        />
        <Search />
      </div>
      <div
        onClick={onSort}
        className='py-2 md:py-3 rounded-lg h-full cursor-pointer px-2 md:px-3 border border-[#D2D3D6]'
      >
        <Sorting />
      </div>
      <div
        onClick={onDeleteAll}
        className='py-2 md:py-3 flex gap-[10px] text-xs md:text-base rounded-lg h-full justify-center cursor-pointer items-center border px-3 md:px-6 border-[#D2D3D6]'
      >
        <Bin />
        <p>All</p>
      </div>
    </div>
    {loader ? (
      <div className='flex justify-center mt-80'>
        <Loader />
      </div>
    ) : (
      <div className='py-3 rounded-lg h-[84%] px-3 border border-[#D2D3D6] mt-4'>
        <div className='max-h-[450px] overflow-y-auto'>
          <table className='w-full table-fixed'>
            <thead className='sticky top-0 bg-white border-b border-[#F5F5F6] text-end rounded-lg text-iconColor'>
              <tr>
                <th className='text-left md:p-3 p-1.5 text-[9px] md:text-sm font-medium w-full md:w-1/4 cursor-pointer'>
                  Guest Name
                </th>
                <th className='text-left md:p-3 p-1.5 text-[9px] md:text-sm font-medium w-full md:w-2/5'>
                  Email
                </th>
                <th className='text-left md:p-3 p-1.5 text-[9px] md:text-sm font-medium w-full md:w-1/4'>
                  Phone Number
                </th>
                <th className='md:p-3 p-1.5 text-[9px] md:text-sm font-medium w-full md:w-1/4'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredGuests?.length ? (
                filteredGuests?.map((data) => (
                  <tr
                    key={data._id}
                    className={'text-headingColor cursor-pointer'}
                  >
                    <td className='md:p-3 p-1.5 md:py-4 text-[9px] truncate md:text-sm'>
                      {data.name}
                    </td>
                    <td className='md:p-3 p-1.5 md:py-4 text-[9px] truncate md:text-sm'>
                      {data.email}
                    </td>
                    <td className='md:p-3 p-1.5 md:py-4 text-[9px] md:text-sm'>
                      {data.phone}
                    </td>
                    <td className='md:p-3 p-1.5 md:py-4 text-[9px] md:text-sm'>
                      <div className='flex justify-end gap-2'>
                        <GuestEdit />
                        {deleteLoading === data._id ? (
                          <Loader />
                        ) : (
                          <Bin
                            onClick={() =>
                              data._id ? onDeleteGuest(data._id) : null
                            }
                            className='cursor-pointer'
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='flex justify-center items-center h-60'>
                      No data found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {filteredGuests?.length !== totalCount && (
          <Button
            onClick={onViewAll}
            variant='outline'
            className='mx-auto w-28 mt-10 text-xs md:text-base'
          >
            {loading ? <Loader /> : 'View all'}
          </Button>
        )}
      </div>
    )}
  </div>
);
export default GuestTable;
