import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Attachment: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15 3C8.3726 3 3 8.3726 3 15C3 21.6274 8.3726 27 15 27C21.6274 27 27 21.6274 27 15C26.9976 8.3736 21.6264 3.0024 15 3ZM21.152 15.92L16.416 20.648C14.4396 22.615 11.243 22.6073 9.276 20.6309C7.3142 18.6597 7.31595 15.4731 9.28 13.504L14.712 8.072C14.9781 7.8025 15.4122 7.79965 15.6817 8.0657C15.6838 8.0678 15.6859 8.0699 15.688 8.072C15.952 8.3438 15.952 8.7762 15.688 9.048L10.256 14.48C8.824 15.9145 8.824 18.2376 10.256 19.672C11.6809 21.1058 13.9982 21.1129 15.4319 19.6881C15.4373 19.6828 15.4427 19.6774 15.448 19.672L20.176 14.944C21.042 14.078 21.042 12.674 20.176 11.808C19.31 10.942 17.906 10.942 17.04 11.808L12.824 16.024C12.5258 16.3223 12.5258 16.8057 12.824 17.104C13.1222 17.4022 13.6058 17.4022 13.904 17.104L17.488 13.512C17.7598 13.248 18.1923 13.248 18.464 13.512C18.7335 13.7781 18.7364 14.2122 18.4703 14.4817C18.4682 14.4838 18.4662 14.4859 18.464 14.488L14.88 18.08C14.0428 18.9173 12.6853 18.9173 11.848 18.08C11.0108 17.2428 11.0108 15.8853 11.848 15.048L16.064 10.832C17.469 9.427 19.747 9.427 21.152 10.832C22.557 12.237 22.557 14.515 21.152 15.92Z'
      fill='black'
    />
  </svg>
);
export default Attachment;
