import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

import JobCard from './job-card';

const VendorRegisterForm = () => {
  const { jobCards } = useConstantData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSelectCard = (title: string) => {
    navigate(ROUTES.VENDOR_SIGNUP_QUESTIONNAIRE, {
      state: { title },
    });
  };

  return (
    <div className='flex items-center w-full justify-center md:flex-col'>
      <div className='flex items-center justify-center self-center md:flex-col'>
        <div className='mt-1.5 flex flex-col items-center self-center w-full'>
          <div className='flex flex-col items-center gap-3.5 w-full'>
            <h1 className='sm:text-[36px] text-[23px] font-semibold mt-10 text-headingColor font-sans'>
              {t('vendorSignUp.heading')}
            </h1>
            <div className='flex justify-center gap-1 mb-7'>
              <p className='text-[18px] font-normal text-center text-paragraphText font-sans'>
                {t('vendorSignUp.bringMoreCustomerTxt')} <br />
                {t('vendorSignUp.growYourBusinessTxt')}
              </p>
            </div>
          </div>
          <hr className='border-t border-borderColor w-full sm:w-[450px] md:w-[550px] lg:w-[648px]' />
          <div className='mt-10 mx-5'>
            <h1 className='sm:text-[28px] text-[23px] font-medium text-darkVoiletColor text-center font-sans mb-5'>
              {t('vendorSignUp.whatWouldYouLikeTxt')}
            </h1>
            <div className='flex flex-col gap-4'>
              {jobCards.map((job) => (
                <JobCard
                  key={job.id}
                  icon={job.icon}
                  title={job.title}
                  description={job.description}
                  onClick={() => handleSelectCard(job.title)}
                />
              ))}
            </div>
            <div className='flex justify-center gap-1 mt-10'>
              <p className='text-[16px] font-normal text-paragraphText font-sans'>
                {t('vendorSignUp.dontQuiteFitTxt')}
              </p>
              <Link
                to={ROUTES.VENDOR_REGISTER}
                className='text-[16px] font-semibold text-brandGreen font-sans'
              >
                {t('vendorSignUp.tellUsMoreTxt')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorRegisterForm;
