import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from 'lucide-react';

// import { useAppSelector } from '@/hooks/redux';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

const MenuSection = () => {
  const { dashboardNavigationOptions } = useConstantData();
  // const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  return (
    <div>
      <div className='flex flex-col gap-5 bg-light-brand-bg border border-dashed px-6 py-6 rounded-xl  md:w-[274px] h-[211px] mx-4'>
        <h1 className='font-semibold font-sans text-2xl text-textDarkGray'>
          {t('dashboard.hello')}, Russel Cole
        </h1>
        <p className='font-medium text-lg text-paragraphText'>
          {t('dashboard.moneyIn')}
          <span className='border border-lightPurple border-dashed py-2 px-3 rounded-lg'>
            $2,052.57
          </span>
        </p>
        <div>
          <button className='flex items-center gap-4 font-sans font-medium text-base bg-white py-[10px] px-[18px] rounded-lg'>
            {t('dashboard.setupAutoTransfer')}
            <ChevronRight color='#737780' />
          </button>
        </div>
      </div>
      <div>
        {dashboardNavigationOptions.map((option, index) => (
          <div key={index} className='my-5'>
            <div className='flex justify-between items-center border p-4 mx-10 md:mx-0 rounded-xl h-[52px] shadow-sm hover:bg-gray-200'>
              <span>{option.label}</span>
              <a href={option.url}>
                <ChevronRight className='text-eveningEast/60' />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuSection;
