import * as React from 'react';

import { SVGComponentProps } from './Venu';
const FoodServiceSVG: React.FC<SVGComponentProps> = ({
  color = '#0A0C2C',
  ...props
}) => (
  <svg
    width={50}
    height={51}
    viewBox='0 0 50 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1969_2076)'>
      <path
        d='M18.2697 26.0774C18.2992 25.5961 18.3288 25.1141 18.3919 24.6273C18.5085 23.473 18.7592 22.3139 19.0154 21.1865C19.1713 20.6241 19.3402 20.0686 19.5174 19.5254C19.7439 18.9953 19.9657 18.4681 20.1868 17.9531C20.6874 16.9478 21.247 16.0085 21.9151 15.2223C22.0737 15.0197 22.2309 14.8234 22.3848 14.6263C22.5606 14.4506 22.7364 14.2802 22.9059 14.1099C23.2637 13.7865 23.574 13.4363 23.9312 13.1795C24.6431 12.6522 25.2729 12.1509 25.9142 11.8405C26.5322 11.4958 27.0622 11.1965 27.5401 11.0276C28.4767 10.6507 29.0082 10.4612 29.0082 10.4612C29.0082 10.4612 28.5549 10.821 27.7626 11.4046C27.3533 11.6718 26.9194 12.0934 26.403 12.5253C25.8646 12.9372 25.3772 13.5277 24.7977 14.0936C24.5079 14.3827 24.2828 14.7459 24.0087 15.0803C23.8755 15.2506 23.7389 15.4223 23.598 15.5967C23.484 15.789 23.366 15.9819 23.2465 16.179C22.7349 16.9371 22.3525 17.827 21.9541 18.7299C21.7906 19.2023 21.6253 19.6816 21.4563 20.1656C21.3409 20.6689 21.2132 21.1776 21.0739 21.6844C20.8644 22.726 20.6591 23.7883 20.5705 24.8807C20.5088 25.2769 20.4792 25.6765 20.4421 26.0789C29.122 26.0803 35.8401 26.0857 45.2404 26.0857C45.272 25.6737 45.2898 25.2562 45.2898 24.8367C45.2898 16.9198 39.5205 10.3994 32.0966 9.5356C32.5759 9.08728 32.8794 8.45138 32.8794 7.7441C32.8821 6.3846 31.7807 5.28455 30.424 5.28455C29.0658 5.28455 27.9631 6.3846 27.9631 7.7441C27.9631 8.4528 28.2652 9.08716 28.7458 9.5356C21.3225 10.3994 15.5547 16.9198 15.5547 24.8367C15.5547 25.2549 15.5697 25.6668 15.6007 26.0776C16.5174 26.0774 17.4025 26.0774 18.2697 26.0774Z'
        fill={color}
      />
      <path
        d='M48.0004 26.9933H12.8496V30.0943H48.0004V26.9933Z'
        fill={color}
      />
      <path
        d='M33.5697 32.2957C31.7832 33.1636 25.5539 35.4103 25.5539 35.4103L18.1903 34.949C18.1903 34.949 22.4023 33.7775 24.035 33.4109C25.6706 33.0455 24.9592 31.2013 24.0549 31.2013C23.1507 31.2013 15.27 31.5267 15.27 31.5267L10.0371 33.4109L11.422 42.1765C11.422 42.1765 12.4994 40.3322 13.574 40.3322C14.6527 40.3322 23.9959 40.5808 25.2642 40.3322C26.5331 40.0808 34.262 34.7936 35.1876 34.177C36.1091 33.5632 35.3633 31.4265 33.5697 32.2957Z'
        fill={color}
      />
      <path
        d='M3 34.3529V45.7155L11.0173 44.2144L9.01161 33.2953L3 34.3529Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1969_2076'>
        <rect width={45} height={45} fill='white' transform='translate(3 3)' />
      </clipPath>
    </defs>
  </svg>
);
export default FoodServiceSVG;
