import React from 'react';

import { Typography } from '@/components/Typography';
import Button from '@/components/ui/button';

const SignupToday = () => {
  return (
    <div className='bg-brandGreen text-white rounded-[20px] text-center pb-5 pt-2 px-3 md:px-24 md:py-14 md:my-20'>
      <Typography variant='heading' className='mb-3 block'>
        Sign up Today!
      </Typography>
      <Typography className='block mb-5 md:text-base text-xs'>
        By Clicking “Sign Up”, You agree to go plan me’s Privacy Policy and
        Terms of Use
      </Typography>
      <div className='flex justify-center sm:mt-10'>
        <input
          type='email'
          placeholder='Email'
          className='text-black focus:outline-none md:p-5 p-1 border-r rounded-tl-[10px] rounded-bl-[10px] text-xs md:text-base w-1/3 md:w-fit'
        />
        <input
          type='password'
          placeholder='Password'
          className='text-black focus:outline-none md:p-5 p-3 text-xs md:text-base w-1/3 md:w-fit'
        />
        <Button className='!text-black bg-willowFlower text-xs md:text-base w-20 md:w-fit md:min-w-24 rounded-l-none'>
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export default SignupToday;
