import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Bin: React.FC<SVGComponentProps> = ({ color = '#585C69', ...props }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.7899 14.2526V6.47655H15.9258V5.51649L12.9178 5.5166V5.0686C12.9178 4.2686 12.2779 3.62865 11.4779 3.62865L8.53388 3.62854C7.73388 3.62854 7.09394 4.26848 7.09394 5.06849V5.51649L4.08594 5.5166V6.47666H5.22199V14.2687C5.22199 15.4207 6.14999 16.3487 7.30202 16.3487H12.71C13.8459 16.3326 14.7899 15.4046 14.7899 14.2526L14.7899 14.2526ZM8.05388 5.06854C8.05388 4.79656 8.26191 4.58851 8.53391 4.58851H11.4619C11.7339 4.58851 11.9419 4.79655 11.9419 5.06854V5.51654L8.05394 5.51666L8.05388 5.06854ZM6.18188 14.2526V6.47655H13.8299V14.2686C13.8299 14.8926 13.3339 15.3885 12.7099 15.3885L7.30191 15.3887C6.69388 15.3726 6.18194 14.8766 6.18194 14.2526L6.18188 14.2526Z'
      fill={color}
    />
    <path d='M9.52637 7.99658H10.4864V13.7406H9.52637V7.99658Z' fill={color} />
    <path d='M11.2861 7.99658H12.2462V13.7406H11.2861V7.99658Z' fill={color} />
    <path d='M7.76562 7.99658H8.72568V13.7406H7.76562V7.99658Z' fill={color} />
  </svg>
);
export default Bin;
