export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_EMAIL: '/verify-email',
  VENDOR_REGISTER: '/vendor-register',
  VENDOR_SIGNUP_QUESTIONNAIRE: '/vendor-signup-questionnaire',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VENDOR_DASHBOARD: '/',
  PLANNER_DASHBOARD: '/planner',
  PLANNER_HOME: '/dashboard',
  PLANNER_GUEST: '/guest',
  PLANNER_THEME: '/theme',
  PLANNER_VENUE: '/venue',
  PLANNER_DECOR: '/decor',
  PLANNER_FOOD: '/food',
  PLANNER_DESERT: '/desert',
  PLANNER_BAR: '/bar',
  PLANNER_PHOTOGRAPHY: '/photography',
  PLANNER_ENTERTAINMENT: '/entertainment',
  PLANNER_REGISTRY: '/registry',
  PLANNER_SUMMARY: '/summary',
  PLANNER_INVITATION: '/invitation',
  PLANNER_RSVP: '/rsvp',
  PLANNER_PARTY: '/party',
};

export enum UserRoles {
  PLANNER = 'planner',
  VENDOR = 'vendor',
}

export enum SignupType {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}
export const TIME = [
  '12:00 am',
  '01:00 am',
  '02:00 am',
  '03:00 am',
  '04:00 am',
  '05:00 am',
  '06:00 am',
  '07:00 am',
  '08:00 am',
  '09:00 am',
  '10:00 am',
  '11:00 am',
  '12:00 pm',
  '01:00 pm',
  '02:00 pm',
  '03:00 pm',
  '04:00 pm',
  '05:00 pm',
  '06:00 pm',
  '07:00 pm',
  '08:00 pm',
  '09:00 pm',
  '10:00 pm',
  '11:00 pm',
];
