import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Paypal: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='194'
    height='55'
    viewBox='0 0 194 55'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect y='0.719971' width='194' height='54' rx='27' fill='white' />
    <rect
      x='0.5'
      y='1.21997'
      width='193'
      height='53'
      rx='26.5'
      stroke='#2A6AC0'
      strokeOpacity='0.09'
    />
    <path
      d='M75.9683 18.8589C75.0571 17.7914 73.3651 17.2576 71.0222 17.2576H64.5143C64.1238 17.2576 63.7333 17.6579 63.6032 18.0582L61 35.5398C61 35.9401 61.2603 36.207 61.5206 36.207H65.5556L66.5968 29.6681V29.935C66.727 29.5347 67.1175 29.1343 67.5079 29.1343H69.4603C73.2349 29.1343 76.0984 27.533 77.0095 23.1292C77.0095 22.9958 77.0095 22.8623 77.0095 22.7289C76.8794 22.7289 76.8794 22.7289 77.0095 22.7289C77.1397 20.9941 76.8794 19.9265 75.9683 18.8589Z'
      fill='#263B80'
    />
    <path
      d='M76.8793 22.7289C76.8793 22.8623 76.8793 22.9958 76.8793 23.1292C75.9682 27.6664 73.1047 29.1343 69.3301 29.1343H67.3777C66.9873 29.1343 66.5968 29.5347 66.4666 29.935L65.165 38.0753C65.165 38.3421 65.2952 38.609 65.6857 38.609H69.0698C69.4603 38.609 69.8508 38.3421 69.8508 37.9418V37.8084L70.5015 33.6715V33.4046C70.5015 33.0043 70.892 32.7374 71.2825 32.7374H71.8031C75.0571 32.7374 77.6603 31.4029 78.3111 27.3995C78.5714 25.7982 78.4412 24.3302 77.6603 23.3961C77.5301 23.1292 77.2698 22.8623 76.8793 22.7289Z'
      fill='#139AD6'
    />
    <path
      d='M75.9681 22.3286C75.8379 22.3286 75.7078 22.1951 75.5776 22.1951C75.4475 22.1951 75.3173 22.1951 75.1872 22.0617C74.6665 21.9282 74.1459 21.9282 73.4951 21.9282H68.4189C68.2887 21.9282 68.1586 21.9282 68.0284 22.0617C67.7681 22.1951 67.6379 22.462 67.6379 22.7289L66.5967 29.6681V29.935C66.7268 29.5347 67.1173 29.1343 67.5078 29.1343H69.4602C73.2348 29.1343 76.0983 27.533 77.0094 23.1292C77.0094 22.9958 77.0094 22.8623 77.1395 22.7289C76.8792 22.5955 76.7491 22.462 76.4887 22.462C76.0983 22.3286 76.0983 22.3286 75.9681 22.3286Z'
      fill='#232C65'
    />
    <path
      d='M121.408 22.3751H117.939C117.735 22.3751 117.531 22.5844 117.429 22.7936L116 31.9999C116 32.2092 116.102 32.3138 116.306 32.3138H118.143C118.347 32.3138 118.449 32.2092 118.449 31.9999L118.857 29.3845C118.857 29.1753 119.061 28.966 119.367 28.966H120.49C122.837 28.966 124.163 27.8152 124.469 25.5136C124.673 24.5721 124.469 23.7352 124.061 23.2121C123.449 22.689 122.531 22.3751 121.408 22.3751ZM121.816 25.8275C121.612 27.0829 120.694 27.0829 119.775 27.0829H119.163L119.571 24.6767C119.571 24.5721 119.673 24.4675 119.878 24.4675H120.082C120.694 24.4675 121.306 24.4675 121.612 24.8859C121.816 24.9906 121.816 25.3044 121.816 25.8275Z'
      fill='#139AD6'
    />
    <path
      d='M96.4081 22.3751H92.9388C92.7347 22.3751 92.5306 22.5844 92.4286 22.7936L91 31.9999C91 32.2092 91.102 32.3138 91.3061 32.3138H92.9388C93.1428 32.3138 93.3469 32.1046 93.449 31.8953L93.8571 29.3845C93.8571 29.1753 94.0612 28.966 94.3673 28.966H95.4898C97.8367 28.966 99.1632 27.8152 99.4693 25.5136C99.6734 24.5721 99.4693 23.7352 99.0611 23.2121C98.4489 22.689 97.6326 22.3751 96.4081 22.3751ZM96.8163 25.8275C96.6122 27.0829 95.6938 27.0829 94.7755 27.0829H94.2653L94.6734 24.6767C94.6734 24.5721 94.7755 24.4675 94.9796 24.4675H95.1836C95.7959 24.4675 96.4081 24.4675 96.7142 24.8859C96.8163 24.9906 96.9183 25.3044 96.8163 25.8275Z'
      fill='#263B80'
    />
    <path
      d='M106.918 25.7229H105.285C105.183 25.7229 104.979 25.8275 104.979 25.9321L104.877 26.4552L104.775 26.246C104.367 25.7229 103.653 25.5137 102.836 25.5137C101 25.5137 99.3671 26.9783 99.061 28.9661C98.8569 30.0122 99.163 30.9538 99.6732 31.5815C100.183 32.2092 100.898 32.4184 101.816 32.4184C103.347 32.4184 104.163 31.4769 104.163 31.4769L104.061 32C104.061 32.2092 104.163 32.3138 104.367 32.3138H105.898C106.102 32.3138 106.306 32.1046 106.408 31.8953L107.326 26.0368C107.224 25.9321 107.02 25.7229 106.918 25.7229ZM104.571 29.0707C104.367 30.0122 103.653 30.7445 102.632 30.7445C102.122 30.7445 101.714 30.5353 101.51 30.3261C101.306 30.0122 101.204 29.5938 101.204 29.0707C101.306 28.1291 102.122 27.3968 103.041 27.3968C103.551 27.3968 103.857 27.606 104.163 27.8153C104.469 28.1291 104.571 28.6522 104.571 29.0707Z'
      fill='#263B80'
    />
    <path
      d='M131.816 25.7229H130.184C130.082 25.7229 129.878 25.8275 129.878 25.9321L129.776 26.4552L129.674 26.246C129.266 25.7229 128.551 25.5137 127.735 25.5137C125.898 25.5137 124.266 26.9783 123.959 28.9661C123.755 30.0122 124.061 30.9538 124.572 31.5815C125.082 32.2092 125.796 32.4184 126.715 32.4184C128.245 32.4184 129.061 31.4769 129.061 31.4769L128.959 32C128.959 32.2092 129.061 32.3138 129.266 32.3138H130.796C131 32.3138 131.204 32.1046 131.306 31.8953L132.225 26.0368C132.123 25.9321 132.021 25.7229 131.816 25.7229ZM129.47 29.0707C129.266 30.0122 128.551 30.7445 127.531 30.7445C127.021 30.7445 126.612 30.5353 126.408 30.3261C126.204 30.0122 126.102 29.5938 126.102 29.0707C126.204 28.1291 127.021 27.3968 127.939 27.3968C128.449 27.3968 128.755 27.606 129.061 27.8153C129.47 28.1291 129.572 28.6522 129.47 29.0707Z'
      fill='#139AD6'
    />
    <path
      d='M115.796 25.7229H114.061C113.857 25.7229 113.755 25.8275 113.653 25.9321L111.408 29.4891L110.387 26.1414C110.285 25.9321 110.183 25.8275 109.877 25.8275H108.245C108.041 25.8275 107.938 26.0368 107.938 26.246L109.775 31.7907L108.041 34.3015C107.938 34.5108 108.041 34.8246 108.245 34.8246H109.877C110.081 34.8246 110.183 34.72 110.285 34.6154L115.898 26.3506C116.204 26.0368 116 25.7229 115.796 25.7229Z'
      fill='#263B80'
    />
    <path
      d='M133.755 22.689L132.326 32.1046C132.326 32.3138 132.428 32.4184 132.632 32.4184H134.061C134.265 32.4184 134.469 32.2092 134.571 32L136 22.7936C136 22.5844 135.898 22.4798 135.693 22.4798H134.061C133.959 22.3751 133.857 22.4798 133.755 22.689Z'
      fill='#139AD6'
    />
  </svg>
);
export default Paypal;
