import * as React from 'react';

import { SVGComponentProps } from './Venu';
const StartedArrow: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='19'
    height='12'
    viewBox='0 0 19 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.3805 0.5V4.625H0V7.375H13.3805V11.5L18.9805 6L13.3805 0.5Z'
      fill='white'
    />
  </svg>
);
export default StartedArrow;
