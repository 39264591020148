import React from 'react';
import { ErrorMessage, Field } from 'formik';

type TextInputComboProps = {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  containerStyle?: string;
  rows?: number;
};
export const TextAreaCombo = (props: TextInputComboProps) => {
  return (
    <div className={`${props.containerStyle}`}>
      <label
        htmlFor={props.name}
        className='block text-sm leading-6 text-gray-600 font-semibold'
      >
        {props.label}
        {props.required && <span className='text-red-600'>*</span>}{' '}
      </label>
      <div className='mt-2 col'>
        <Field
          name={props.name}
          placeholder={props.placeholder}
          as={'textarea'}
          className={
            'pl-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6'
          }
          required={props.required}
          rows={props.rows}
        />
        <ErrorMessage name={props.name}>
          {(msg) => <div className='text-red-600 text-sm mt-1'>{msg}</div>}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default TextAreaCombo;
