import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import StarIcon from '@/assets/png/Star.png';
import UnionIcon from '@/assets/png/Union.png';
import Button from '@/components/ui/button';
import TextInputCombo from '@/components/ui/Input';
import { ROUTES } from '@/constants';
import { resetPassword } from '@/services/authServices';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams(); // Hook to get URL query parameters

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t('resetPasswordScreen.errors.newPassRequired'))
      .min(8, t('resetPasswordScreen.errors.newValidPass')),
    confirmNewPassword: Yup.string()
      .required(t('resetPasswordScreen.errors.confirmPassRequired'))
      .oneOf(
        [Yup.ref('newPassword')],
        t('resetPasswordScreen.errors.confirmMatchPassword'),
      ),
  });

  const INITIAL_VALUES = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const handleSubmit = async (values: typeof INITIAL_VALUES) => {
    const token = searchParams.get('token') ?? '';
    const response = await resetPassword({
      token,
      newPassword: values.newPassword,
    });
    if (response) navigate(ROUTES.LOGIN);
  };

  return (
    <div className='flex items-center justify-center w-full md:flex-col'>
      <img
        src={UnionIcon}
        width={31}
        height={26}
        alt='Union Icon'
        className='absolute hidden left-10 top-24 lg:block'
      />
      <div className='flex items-center self-center justify-center mt-5 md:flex-col sm:mt-14'>
        <div className='mt-1.5 flex flex-col items-center self-center w-full'>
          <div className='flex flex-col items-center gap-3.5 w-full'>
            <h1 className='sm:text-[36px] text-[23px] font-semibold text-headingColor font-sans'>
              {t('resetPasswordScreen.heading')}
            </h1>
            <img
              width={28}
              height={28}
              src={StarIcon}
              alt='Star Icon'
              className='absolute md:right-[400px] lg:block hidden top-40'
            />
          </div>

          <p className='my-6 font-sans text-base font-medium text-center text-inputTxtColor'>
            {t('resetPasswordScreen.subHeading')}
          </p>

          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className='flex flex-col gap-2.5 w-full px-2'>
                <TextInputCombo
                  name='newPassword'
                  type='password'
                  placeholder={t('resetPasswordScreen.newPasswordPlaceholder')}
                  label={t('resetPasswordScreen.newPasswordLabel')}
                  inputStyle='w-full sm:w-[500px]'
                />
                <TextInputCombo
                  name='confirmNewPassword'
                  type='password'
                  placeholder={t(
                    'resetPasswordScreen.confirmPasswordPlaceholder',
                  )}
                  label={t('resetPasswordScreen.confirmPasswordLabel')}
                />

                <Button
                  variant='filled'
                  size='medium'
                  type='submit'
                  disabled={isSubmitting}
                  className='w-full mt-5 text-white bg-brandGreen'
                >
                  {isSubmitting
                    ? t('resetPasswordScreen.btnLoadingTxt')
                    : t('resetPasswordScreen.btnTxt')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
