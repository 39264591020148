import React, { useState } from 'react';

import { cn } from '@/lib/utils';

interface JobCardProps {
  icon: (color: string) => React.ReactNode;
  title: string;
  description: string;
  onClick: () => void;
}

const JobCard: React.FC<JobCardProps> = ({
  icon,
  title,
  description,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className='bg-white border cursor-pointer rounded-lg p-4 shadow-sm w-full sm:w-[450px] md:w-[550px] lg:w-[648px] hover:bg-brandGreen/20'
    >
      <div
        className={cn(
          'flex items-center w-11/12 md:w-8/12 mx-auto text-black/50',
          {
            'text-brandGreen': isHovered,
          },
        )}
      >
        <div className='flex items-center justify-center h-12 w-12'>
          {icon(isHovered ? '#89b9a5' : 'rgba(0, 0, 0, 0.5)')}
        </div>
        <div className='ml-4'>
          <h3 className='text-lg font-semibold'>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
