import { Middleware } from 'redux';
import logger from 'redux-logger';

import { configureStore } from '@reduxjs/toolkit';

import rootReducer, { RootState } from './rootReducer';

const additionalMiddleware: Middleware[] = [];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...additionalMiddleware, logger),
});

export type AppDispatch = typeof store.dispatch;
export type { RootState };
export { store };
