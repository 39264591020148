/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { partyFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setPartyFilters } from '@/redux/slices/filters';
import { GetPartyList } from '@/services/planner';

const PlannerParty = () => {
  const dispatch = useAppDispatch();
  const { partyFilters } = useAppSelector((state) => state.filters);
  const [photoLists, setPartyLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getPartyServices = async () => {
    setLoading(true);
    const { data }: any = await GetPartyList({
      page,
      ...partyFilters,
    });
    setPartyLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getPartyServices();
  }, [partyFilters, page]);

  const setFilters = (value: any) => {
    dispatch(setPartyFilters(value));
  };

  const data = photoLists?.map((list: any) => {
    return {
      ...list,
      title: list?.businessName,
      imgUrl: list?.images?.length ? list?.images[0] : '',
      cardData: { subtitle: list?.description },
    };
  });
  return (
    <PlannerLayout
      tabLength={10}
      heroRoute={ROUTES.PLANNER_INVITATION}
      heroLength={9}
      skipText='Party favours already secured?'
      cardData={data}
      sections={partyFilterSections}
      cardType='decor'
      distance
      setPage={setPage}
      setFilters={setFilters}
      filters={partyFilters}
      totalTablePages={totalPages}
      apiRoute='party-favors'
      multiSelect
      loading={loading}
    />
  );
};

export default PlannerParty;
