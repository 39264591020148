import * as React from 'react';

import { SVGComponentProps } from './Venu';
const GuestList1: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='36' height='36' rx='5' fill={color} fillOpacity='0.1' />
    <path
      d='M26.125 7.70833H21.7231C21.5031 6.85645 20.7765 6.20608 19.8853 6.09888C19.5055 5.42927 18.7881 5 18 5C17.2119 5 16.4945 5.42927 16.1147 6.09888C15.2235 6.20608 14.497 6.85645 14.2769 7.70833H9.875C8.97897 7.70833 8.25 8.43731 8.25 9.33333V29.375C8.25 30.271 8.97897 31 9.875 31H26.125C27.021 31 27.75 30.271 27.75 29.375V9.33333C27.75 8.43731 27.021 7.70833 26.125 7.70833ZM16.375 7.16667H16.4678C16.6975 7.16667 16.902 7.02199 16.9785 6.80554C17.1313 6.37356 17.5417 6.08333 18 6.08333C18.4583 6.08333 18.8687 6.37356 19.0215 6.80554C19.098 7.02199 19.3026 7.16667 19.5322 7.16667H19.625C20.2224 7.16667 20.7083 7.65265 20.7083 8.25C20.7083 8.84735 20.2224 9.33333 19.625 9.33333H16.375C15.7776 9.33333 15.2917 8.84735 15.2917 8.25C15.2917 7.65265 15.7776 7.16667 16.375 7.16667ZM12.0417 8.79167H14.2769C14.518 9.72512 15.3673 10.4167 16.375 10.4167H19.625C20.6327 10.4167 21.482 9.72512 21.7231 8.79167H23.9583V27.2083H12.0417V8.79167ZM26.6667 29.375C26.6667 29.6737 26.4237 29.9167 26.125 29.9167H9.875C9.57633 29.9167 9.33333 29.6737 9.33333 29.375V9.33333C9.33333 9.03466 9.57633 8.79167 9.875 8.79167H10.9583V27.2083C10.9583 27.8057 11.4443 28.2917 12.0417 28.2917H23.9583C24.5557 28.2917 25.0417 27.8057 25.0417 27.2083V8.79167H26.125C26.4237 8.79167 26.6667 9.03466 26.6667 9.33333V29.375Z'
      fill={color}
    />
    <path
      d='M15.8337 13.6666H20.167C20.4661 13.6666 20.7087 13.424 20.7087 13.1249C20.7087 12.8258 20.4661 12.5833 20.167 12.5833H15.8337C15.5346 12.5833 15.292 12.8258 15.292 13.1249C15.292 13.424 15.5346 13.6666 15.8337 13.6666Z'
      fill={color}
    />
    <path
      d='M20.167 18H15.8337C15.5346 18 15.292 18.2426 15.292 18.5417C15.292 18.8408 15.5346 19.0833 15.8337 19.0833H20.167C20.4661 19.0833 20.7087 18.8408 20.7087 18.5417C20.7087 18.2426 20.4661 18 20.167 18Z'
      fill={color}
    />
    <path
      d='M18.9749 21.25C18.6218 21.25 18.281 21.3635 17.9999 21.5637C17.7187 21.3635 17.3779 21.25 17.0249 21.25C16.0989 21.25 15.3457 22.0032 15.3457 22.9292C15.3457 23.4002 15.5533 23.8433 15.9152 24.1449L17.3657 25.3537C17.5434 25.5018 17.7686 25.5833 17.9999 25.5833C18.2312 25.5833 18.4563 25.5018 18.6339 25.3537L20.0846 24.1449C20.4465 23.8433 20.654 23.4002 20.654 22.9292C20.654 22.0032 19.9008 21.25 18.9749 21.25ZM19.3911 23.3126L17.9999 24.4719L16.6086 23.3126C16.4945 23.2175 16.429 23.0777 16.429 22.9292C16.429 22.6006 16.6963 22.3333 17.0249 22.3333C17.2357 22.3333 17.4268 22.4415 17.5359 22.6226C17.6339 22.7854 17.81 22.8848 17.9999 22.8848C18.1897 22.8848 18.3658 22.7854 18.4638 22.6226C18.573 22.4415 18.764 22.3333 18.9749 22.3333C19.3034 22.3333 19.5707 22.6006 19.5707 22.9292C19.5707 23.0777 19.5052 23.2175 19.3911 23.3126Z'
      fill={color}
    />
    <path
      d='M21.792 15.2917H14.2087C13.9096 15.2917 13.667 15.5343 13.667 15.8334C13.667 16.1325 13.9096 16.3751 14.2087 16.3751H21.792C22.0911 16.3751 22.3337 16.1325 22.3337 15.8334C22.3337 15.5343 22.0911 15.2917 21.792 15.2917Z'
      fill={color}
    />
  </svg>
);
export default GuestList1;
