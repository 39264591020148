import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, useFormikContext } from 'formik';

import Button from '@/components/ui/button';
import SelectInputCombo from '@/components/ui/Dropdown';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

import BakeryAndTreat from '../Bakery';
import DecorForm from '../Decor-Form';
import DrinkServices from '../DrinkServices';
import EntertainmentServices from '../Entertainment';
import EquipmentRental from '../EquipmentRental';
import FoodService from '../Food-Service';
import PartyFavors from '../PartyFavors';
import PhotographerServices from '../Photographer-Form';
import getValidationSchema from '../validation';
import VenuForm from '../Venu-Form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StepTwoForm = ({ initialValues, onSubmit }: any) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState('');

  const { jobCards } = useConstantData();

  const categoryOptions = jobCards.map((card) => ({
    value: card.label!,
    label: card.title,
  }));

  const WatchField = () => {
    const { values } = useFormikContext<{ category: string }>();

    useEffect(() => {
      setCategory(values?.category);
    }, [values]);

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(category, t)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className='flex flex-col gap-2.5 w-full'>
          <SelectInputCombo
            name='category'
            label='Category'
            options={categoryOptions}
            placeholder='Select Category'
          />

          {/* Render specific forms based on selected category */}
          {values.category === 'Venue' && <VenuForm values={values} />}
          {values.category === 'Decor' && <DecorForm values={values} />}
          {values.category === 'Food Service' && (
            <FoodService values={values} />
          )}
          {values.category === 'Drink Services' && <DrinkServices />}
          {values.category === 'Photographer/Videographer' && (
            <PhotographerServices values={values} />
          )}
          {values.category === 'Bakery/Treats' && (
            <BakeryAndTreat values={values} />
          )}
          {values.category === 'Entertainment' && (
            <EntertainmentServices values={values} />
          )}
          {values.category === 'Party Favors' && <PartyFavors />}
          {values.category === 'Equipment Rentals' && <EquipmentRental />}

          <Button
            variant='filled'
            size='medium'
            type='submit'
            className='w-full mt-4 text-white bg-brandGreen'
          >
            {isSubmitting
              ? t('register.signUpLoadingBtn')
              : t('register.signUpBtn')}
          </Button>
          <WatchField />
        </Form>
      )}
    </Formik>
  );
};

export default StepTwoForm;
