import React from 'react';

// import { FaSearch } from 'react-icons/fa';
import Search from '@/assets/svg/Search';

const SearchInput = ({
  background = '#F8F8F8',
  placeHolder = '',
  value = '',
  setValue = (e: { target: { value: string } }) => {
    e;
  },
}) => {
  return (
    <div
      style={{
        backgroundColor: background,
      }}
      className={`${background === 'white' ? 'border' : 'rounded-[10px]'} border-[#D2D3D6] p-2 rounded-md items-center gap-2 flex mt-1`}
    >
      <Search width={16} />
      <input
        style={{
          backgroundColor: background,
        }}
        value={value}
        onChange={setValue}
        type='text'
        className='focus:outline-none md:w-20 lg:w-full w-full text-xs lg:text-sm'
        placeholder={placeHolder}
      />
    </div>
  );
};

export default SearchInput;
