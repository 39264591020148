import React, { useState } from 'react';

interface ToggleProps {
  label: string;
  onChange: (checked: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({ label, onChange }) => {
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked((prev) => {
      const newState = !prev;
      onChange(newState);
      return newState;
    });
  };

  return (
    <div className='flex items-center space-x-3 my-3'>
      <button
        onClick={handleToggle}
        className={
          'relative w-10 h-6 rounded-full border transition-colors duration-300 '
        }
      >
        <span
          className={`absolute top-[3px] left-[3px] w-4 h-4 rounded-full transition-transform duration-300 ${
            checked ? 'translate-x-4 bg-[#6B759D]' : 'bg-[#6B759D]'
          }`}
        />
      </button>
      <span className='text-gray-700 font-medium'>{label}</span>
    </div>
  );
};

export default Toggle;
