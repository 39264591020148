import React, { Fragment } from 'react';
import { ErrorMessage, Field } from 'formik';

import { cn } from '@/lib/utils';

interface Option {
  value: string;
  label: string;
}

interface InputRadioComboProps {
  name: string;
  options?: Option[];
  label?: string;
  containerStyle?: string;
  labelClassName?: string;
}

const InputRadioCombo: React.FC<InputRadioComboProps> = ({
  name,
  options = [],
  label,
  containerStyle,
  labelClassName,
}) => {
  return (
    <Fragment>
      <div className={cn('flex md:flex-row gap-3', containerStyle)}>
        <div className='w-full'>
          {label && (
            <span className='text-sm leading-6 text-gray-600 font-semibold'>
              {label}
            </span>
          )}
        </div>
        <div className='flex gap-8 flex-row w-full mt-0.5 justify-start'>
          <Field name={name}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {({ field }: any) =>
              options.map((option) => (
                <label
                  key={option.value}
                  className={cn(
                    'flex items-center mb-1 text-sm text-black/80 font-semibold cursor-pointer',
                    labelClassName,
                  )}
                >
                  <input
                    type='radio'
                    id={option.value}
                    className='mr-2 radio-input'
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                    onChange={field.onChange}
                  />
                  {option.label}
                </label>
              ))
            }
          </Field>
        </div>
      </div>
      <ErrorMessage name={name}>
        {(msg) => <div className='text-red-600 text-sm mb-2'>{msg}</div>}
      </ErrorMessage>
    </Fragment>
  );
};

export default InputRadioCombo;
