import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Tiktok: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='58'
    height='57'
    viewBox='0 0 58 57'
    {...props}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_d_1941_155)'>
      <rect
        x='15.7373'
        y='15'
        width='26.5792'
        height='26.5792'
        rx='13.2896'
        fill={props.fill ? '' : 'white'}
      />
      <g clipPath='url(#clip0_1941_155)'>
        <path
          d='M32.3533 26.5373C33.3603 27.2632 34.594 27.6903 35.9263 27.6903V25.105C35.6742 25.105 35.4227 25.0785 35.176 25.0258V27.0608C33.8437 27.0608 32.6102 26.6337 31.6029 25.9079V31.1838C31.6029 33.8231 29.4811 35.9625 26.8638 35.9625C25.8873 35.9625 24.9796 35.6648 24.2256 35.1542C25.0862 36.0415 26.2863 36.5919 27.614 36.5919C30.2315 36.5919 32.3534 34.4525 32.3534 31.8131V26.5373H32.3533ZM33.279 23.9291C32.7643 23.3621 32.4264 22.6295 32.3533 21.8194V21.4869H31.6422C31.8212 22.5164 32.4318 23.396 33.279 23.9291ZM25.881 33.1289C25.5934 32.7488 25.438 32.2837 25.4387 31.8056C25.4387 30.5985 26.4092 29.6197 27.6066 29.6197C27.8297 29.6196 28.0515 29.6542 28.2642 29.7222V27.079C28.0157 27.0447 27.7649 27.0301 27.5142 27.0355V29.0927C27.3014 29.0247 27.0795 28.9901 26.8562 28.9904C25.6589 28.9904 24.6884 29.969 24.6884 31.1762C24.6884 32.0299 25.1735 32.7689 25.881 33.1289Z'
          fill='#FF004F'
        />
        <path
          d='M31.604 25.9079C32.6113 26.6337 33.8447 27.0607 35.177 27.0607V25.0257C34.4333 24.866 33.775 24.4741 33.28 23.9291C32.4327 23.3959 31.8222 22.5164 31.6432 21.4869H29.7755V31.813C29.7712 33.0168 28.8024 33.9915 27.6076 33.9915C26.9036 33.9915 26.278 33.6532 25.8819 33.1289C25.1746 32.7689 24.6894 32.0298 24.6894 31.1763C24.6894 29.9691 25.6599 28.9904 26.8572 28.9904C27.0867 28.9904 27.3078 29.0264 27.5152 29.0928V27.0355C24.9439 27.0891 22.876 29.2076 22.876 31.8131C22.876 33.1137 23.3909 34.2928 24.2267 35.1543C24.9807 35.6648 25.8883 35.9626 26.865 35.9626C29.4822 35.9626 31.604 33.823 31.604 31.1838V25.9079L31.604 25.9079Z'
          fill='black'
        />
        <path
          d='M35.1774 25.0258V24.4756C34.5067 24.4767 33.8493 24.2873 33.2803 23.9292C33.784 24.4852 34.4472 24.8686 35.1774 25.0259V25.0258ZM31.6435 21.487C31.6265 21.3886 31.6134 21.2896 31.6043 21.1902V20.8577H29.0253V31.1838C29.0212 32.3876 28.0524 33.3623 26.8575 33.3623C26.5188 33.3628 26.1847 33.2829 25.8822 33.1291C26.2783 33.6533 26.9038 33.9916 27.6079 33.9916C28.8026 33.9916 29.7716 33.017 29.7758 31.8132V21.487H31.6435V21.487ZM27.5156 27.0356V26.4498C27.3001 26.4201 27.0828 26.4052 26.8653 26.4053C24.2478 26.4053 22.126 28.5448 22.126 31.1838C22.126 32.8384 22.9599 34.2966 24.2271 35.1543C23.3913 34.2928 22.8764 33.1137 22.8764 31.8131C22.8764 29.2077 24.9442 27.0892 27.5156 27.0356Z'
          fill='#00F2EA'
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_1941_155'
        x='0.873462'
        y='0.136157'
        width='56.3068'
        height='56.3069'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='7.43192' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1941_155'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1941_155'
          result='shape'
        />
      </filter>
      <clipPath id='clip0_1941_155'>
        <rect
          width='13.8021'
          height='15.7739'
          fill='white'
          transform='translate(22.126 20.8577)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default Tiktok;
