import React from 'react';

import Footer from '@/components/Footer';
import PlannerHeader from '@/components/PlannerHeader';

import GuestSection from './modules/GuestSection';

const Guest = () => {
  return (
    <div className='w-full'>
      <PlannerHeader />
      <GuestSection />
      <Footer />
    </div>
  );
};

export default Guest;
