import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Facebook: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='57'
    height='57'
    viewBox='0 0 57 57'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
    fill='none'
  >
    <g filter='url(#filter0_d_1941_142)'>
      <rect
        x='15'
        y='15'
        width='26.5792'
        height='26.5792'
        rx='13.2896'
        fill={props.fill ? '' : 'white'}
      />
      <path
        d='M26.0116 36.8852V28.6173H23.5127V25.6405H26.0116V23.0979C26.0116 21.0999 27.4877 19.2651 30.8889 19.2651C32.266 19.2651 33.2843 19.3806 33.2843 19.3806L33.2041 22.1605C33.2041 22.1605 32.1656 22.1516 31.0323 22.1516C29.8058 22.1516 29.6093 22.6461 29.6093 23.4669V25.6405H33.3016L33.1409 28.6173H29.6093V36.8852H26.0116Z'
        fill='#3D5A99'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_1941_142'
        x='0.136157'
        y='0.136157'
        width='56.3068'
        height='56.3069'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='7.43192' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1941_142'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1941_142'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
export default Facebook;
