import * as React from 'react';

import { SVGComponentProps } from './Venu';
const GuestEdit: React.FC<SVGComponentProps> = ({
  color = '#585C69',
  ...props
}) => (
  <svg
    width='19'
    height='20'
    viewBox='0 0 19 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.7825 5.65568L14.1704 6.93426L8.79948 11.8823L6.99709 12.3141L7.41164 10.6038L12.7825 5.65576V5.65568ZM4.50974 4.80884H10.9081C11.3046 4.80884 11.6651 4.95825 11.9355 5.20734C11.9896 5.25717 12.0437 5.30701 12.0977 5.37343L11.3406 6.07086C11.3226 5.98786 11.2685 5.92145 11.2145 5.87161C11.1424 5.78861 11.0162 5.73877 10.9081 5.73877H4.50975C4.38356 5.73877 4.27548 5.78861 4.2034 5.87161C4.11331 5.93802 4.05921 6.0377 4.05921 6.15385V13.9748C4.05921 14.091 4.11331 14.1906 4.2034 14.2737C4.27549 14.3401 4.38368 14.3899 4.50975 14.3899H10.9081C11.0163 14.3899 11.1424 14.3401 11.2145 14.2737C11.3045 14.1907 11.3406 14.091 11.3406 13.9748V10.4547L12.368 9.5082V13.9748C12.368 14.3567 12.2059 14.6888 11.9355 14.9379C11.6651 15.187 11.3047 15.3364 10.9081 15.3364H4.50975C4.11319 15.3364 3.73473 15.187 3.48235 14.9379C3.21198 14.6888 3.0498 14.3567 3.0498 13.9748V6.1539C3.0498 5.78855 3.21198 5.43989 3.48235 5.20738C3.73472 4.95829 4.11319 4.80888 4.50975 4.80888L4.50974 4.80884ZM14.7652 4.87526L15.0176 5.10776C15.3239 5.39 15.3239 5.85502 15.0176 6.13726L14.4588 6.65201L13.0891 5.37343L13.6478 4.87525C13.9542 4.59301 14.4589 4.59301 14.7653 4.87525L14.7652 4.87526Z'
      fill={color}
    />
  </svg>
);
export default GuestEdit;
