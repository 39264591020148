import React, { useState } from 'react';
import { ErrorMessage, Field, useField } from 'formik';
import { Eye, EyeOff } from 'lucide-react';

import { cn } from '@/lib/utils';

type TextInputComboProps = {
  name: string;
  type: 'email' | 'text' | 'password' | 'number';
  label: string;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  min?: number;
  inputStyle?: string;
  required?: boolean;
  containerStyle?: string;
  isPhone?: boolean;
};

export const TextInputCombo = (props: TextInputComboProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [field] = useField(props.name);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.type === 'number') {
      if (props?.isPhone) {
        if (['e', 'E', '-'].includes(e.key)) {
          e.preventDefault();
        }
      } else {
        if (['e', 'E', '+', '-'].includes(e.key)) {
          e.preventDefault();
        }
      }
    }
  };

  return (
    <div className={`flex-1 ${props.containerStyle}`}>
      <label
        htmlFor={props.name}
        className='block text-sm leading-6 text-gray-600 font-semibold'
      >
        {props.label}
        {props.required && <span className='text-red-600'>*</span>}{' '}
      </label>
      <div className='mt-2 col relative'>
        <Field
          {...field}
          disabled={props.disabled}
          placeholder={props.placeholder}
          name={props.name}
          min={props.min}
          type={showPassword && props.type === 'password' ? 'text' : props.type}
          className={cn(
            'px-2 block w-full rounded-md border-0 py-1.5 h-[50px] shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6',
            props.disabled && 'bg-gray-200 text-gray-500',
            props.inputStyle,
            props.type === 'password' && 'pr-10',
          )}
          required={props.required}
          onKeyDown={handleKeyDown}
        />
        {props.type === 'password' && (
          <button
            type='button'
            onClick={togglePasswordVisibility}
            className='absolute inset-y-0 right-0 pr-3 flex top-3.5 text-sm leading-5'
          >
            {showPassword ? (
              <Eye className='text-iconColor size-5' />
            ) : (
              <EyeOff className='text-iconColor size-5' />
            )}
          </button>
        )}
        <ErrorMessage name={props.name}>
          {(msg) => <div className='text-red-600 text-sm mt-1'>{msg}</div>}
        </ErrorMessage>
        {props.helperText && (
          <p className={'mt-2 text-xs leading-6 text-gray-500'}>
            {props.helperText}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextInputCombo;
