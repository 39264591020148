import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import CheckboxCombo from '@/components/ui/Checkbox';
import TextInputCombo from '@/components/ui/Input';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

interface PhotographerServicesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
}

const PhotographerServices: React.FC<PhotographerServicesProps> = ({
  values,
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { additionalServiceOptions, photographerServiceOptions } =
    useConstantData();

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };
  return (
    <div className='md:w-[440px]'>
      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.PhotographerServices.services')}
      </h1>
      {photographerServiceOptions.map((service) => (
        <div key={service.value}>
          <CheckboxCombo
            name='photographyServiceOptions'
            options={[{ value: service.value, label: service.label }]}
            label=''
          />
          {values.photographyServiceOptions.includes(service.value) && (
            <div className='flex justify-between w-full gap-4'>
              <TextInputCombo
                label=''
                name={`${service.value}_min`}
                placeholder={t('vendorSignUpQuestionnaire.min')}
                type='number'
                min={0}
              />
              <p className='mx-2.5 mt-5 md:mx-8'>
                {t('vendorSignUpQuestionnaire.to')}
              </p>
              <TextInputCombo
                label=''
                name={`${service.value}_max`}
                placeholder={t('vendorSignUpQuestionnaire.max')}
                type='number'
                min={0}
              />
            </div>
          )}
        </div>
      ))}

      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.PhotographerServices.additionalServices')}
      </h1>

      <CheckboxCombo
        name='selectedAdditionalServices'
        options={additionalServiceOptions}
        label=''
      />
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default PhotographerServices;
