import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import CheckboxCombo from '@/components/ui/Checkbox';
import SelectInputCombo from '@/components/ui/Dropdown';
import TextInputCombo from '@/components/ui/Input';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

const DrinkServices: React.FC = () => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const {
    drinkServiceOptions,
    drinkTypeOptions,
    provideAlcoholOptions,
    servingAlcoholOptions,
  } = useConstantData();

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };
  return (
    <div>
      <h1 className='block text-lg my-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.drinkService.heading')}
      </h1>

      <div>
        <TextInputCombo
          label={t('vendorSignUpQuestionnaire.drinkService.perHourLabel')}
          inputStyle='w-full'
          name='attendantPrice'
          placeholder={t(
            'vendorSignUpQuestionnaire.drinkService.perHourPlaceholder',
          )}
          type='number'
          min={0}
        />
      </div>

      <div className='flex flex-col items-start'>
        <div>
          <p className='block text-sm leading-6 text-gray-600 font-semibold mt-4'>
            {t('vendorSignUpQuestionnaire.drinkService.priceRangePerDrink')}
          </p>
        </div>
        <div className='flex justify-between w-full gap-4'>
          <TextInputCombo
            label=''
            name='drinkPriceRange_min'
            placeholder={t('vendorSignUpQuestionnaire.min')}
            type='number'
            min={0}
          />
          <p className='mx-2.5 mt-5 md:mx-8'>
            {t('vendorSignUpQuestionnaire.to')}
          </p>
          <TextInputCombo
            label=''
            name='drinkPriceRange_max'
            placeholder={t('vendorSignUpQuestionnaire.max')}
            type='number'
          />
        </div>
      </div>

      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.drinkService.drinkServicesOffered')}
      </h1>
      <CheckboxCombo
        name='selectedDrinkServices'
        options={drinkServiceOptions}
        label=''
      />

      <SelectInputCombo
        name='provideAlcohol'
        containerStyle='mt-3'
        label={t(
          'vendorSignUpQuestionnaire.drinkService.doYouProvideTheAlcohol',
        )}
        options={provideAlcoholOptions}
        placeholder={t('vendorSignUpQuestionnaire.options')}
      />

      <SelectInputCombo
        name='serveAlcohol'
        containerStyle='mt-3'
        label={t('vendorSignUpQuestionnaire.drinkService.canCustomerServe')}
        labelStyle='w-full sm:w-[480px]'
        options={servingAlcoholOptions}
        placeholder={t('vendorSignUpQuestionnaire.options')}
      />

      <h1 className='block text-lg mt-4 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.drinkService.drinkTypesOffered')}
      </h1>

      <CheckboxCombo
        name='selectedDrinkTypes'
        options={drinkTypeOptions}
        label=''
      />
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default DrinkServices;
