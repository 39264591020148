import Loader from '@/assets/svg/loader';

const GuestInput: React.FC<{
  GuestData: { name: string; email: string; phone: string };
  setGuestData: (value: { name: string; email: string; phone: string }) => void;
  addGuestLoading: boolean;
  onAddGuest: () => void;
}> = ({ GuestData, setGuestData, addGuestLoading, onAddGuest }) => (
  <div className='p-5 mt-4 rounded-2xl w-full bg-lightGray'>
    <p className='md:text-sm text-xs mb-1'>Name</p>
    <input
      type='text'
      placeholder='Enter guest full name'
      className='rounded-lg p-3 w-full focus:outline-none border mb-3 border-[#D2D3D6] text-sm md:text-base'
      value={GuestData.name}
      onChange={(e) => setGuestData({ ...GuestData, name: e.target.value })}
    />
    <p className='md:text-sm text-xs mb-1'>Email</p>
    <input
      type='email'
      placeholder='Enter email'
      className='rounded-lg p-3 w-full focus:outline-none border mb-3 border-[#D2D3D6] text-sm md:text-base'
      value={GuestData.email}
      onChange={(e) => setGuestData({ ...GuestData, email: e.target.value })}
    />
    <p className='md:text-sm text-xs mb-1'>Phone Number</p>
    <input
      type='text'
      placeholder='Enter phone number'
      className='rounded-lg p-3 w-full focus:outline-none border mb-3 border-[#D2D3D6] text-sm md:text-base'
      value={GuestData.phone}
      onChange={(e) => {
        const sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
        setGuestData({ ...GuestData, phone: sanitizedValue });
      }}
    />
    <button
      onClick={onAddGuest}
      className='bg-voiletColor rounded-lg my-2 leading-5 gap-2 flex justify-center text-sm md:text-base font-medium text-white w-full py-4'
    >
      {addGuestLoading ? <Loader fill='#ffffff' /> : 'Add Guest'}
    </button>
  </div>
);
export default GuestInput;
