import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectInputCombo from '@/components/ui/Dropdown';
import TextInputCombo from '@/components/ui/Input';
import InputRadioCombo from '@/components/ui/TextRadio';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

interface AddSpacesProps {
  spaceNumber: number;
}

const AddSpaces: React.FC<AddSpacesProps> = ({ spaceNumber }) => {
  const { t } = useTranslation();
  const { environmentRadioOptions, timePeriodOptions, vibeOptions } =
    useConstantData();

  return (
    <div className='my-4'>
      <h1 className='text-lg font-sans text-darkHeadingColor text-center font-semibold my-4'>
        {t('vendorSignUpQuestionnaire.addSpaceForm.space')} {spaceNumber}
      </h1>
      <div className='flex gap-2  items-center justify-between'>
        <div>
          <h1 className='mt-8 text-black/80 font-semibold'>
            {' '}
            {t('vendorSignUpQuestionnaire.addSpaceForm.capacity')}:
          </h1>
        </div>
        <div className='flex gap-4 w-9/12'>
          <TextInputCombo
            name={`spaces[${spaceNumber - 1}].capacitySeated`}
            placeholder='02'
            type='number'
            min={0}
            label={t('vendorSignUpQuestionnaire.addSpaceForm.seatedLabel')}
          />
          <TextInputCombo
            name={`spaces[${spaceNumber - 1}].capacityStanding`}
            placeholder='03'
            type='number'
            min={0}
            label={t('vendorSignUpQuestionnaire.addSpaceForm.standingLabel')}
          />
        </div>
      </div>
      <div className='grid grid-cols-3 gap-4 my-5'>
        <TextInputCombo
          name={`spaces[${spaceNumber - 1}].rate`}
          type='text'
          containerStyle='col-span-2'
          placeholder={t('vendorSignUpQuestionnaire.addSpaceForm.rateLabel')}
          label={t('vendorSignUpQuestionnaire.addSpaceForm.rateLabel')}
        />
        <SelectInputCombo
          name={`spaces[${spaceNumber - 1}].hourlyDropdown`}
          label={t('vendorSignUpQuestionnaire.addSpaceForm.HourlyLabel')}
          options={timePeriodOptions}
          placeholder={t('vendorSignUpQuestionnaire.addSpaceForm.HourlyLabel')}
        />
      </div>
      <InputRadioCombo
        name={`spaces[${spaceNumber - 1}].environmentOptions`}
        containerStyle='flex-col'
        options={environmentRadioOptions}
        label={t('vendorSignUpQuestionnaire.addSpaceForm.environmentLabel')}
        labelClassName='font-semibold text-black/80 '
      />

      <SelectInputCombo
        name={`spaces[${spaceNumber - 1}].vibeDropdown`}
        containerStyle='mt-5'
        label={t('vendorSignUpQuestionnaire.addSpaceForm.vibeLabel')}
        options={vibeOptions}
        placeholder=''
      />
    </div>
  );
};

export default AddSpaces;
