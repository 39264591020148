/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResponseType } from '@/@types';
import { API } from '@/api/ApiInstance';
import { apiEndpoints } from '@/constants/apiEndpoints';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import { GuestListState, GuestState } from '@/utils/types/storeStateInterface';

export const GetGuestList = async (count: number, search: string) => {
  const response = await API.Get<
    ResponseType<{ data: GuestListState; totalCount: any }>
  >(`${apiEndpoints.GET_GUEST_LIST}?limit=${count}&search=${search}`);

  if (!response.status) {
    showErrorToast(response.message || 'Error getting guest list.');
    return;
  }
  return response.data?.data;
};

export const AddGuestList = async (payload: GuestState) => {
  const response = await API.Post<GuestState, ResponseType<GuestState>>(
    apiEndpoints.ADD_GUEST_LIST,
    {
      ...payload,
    },
  );

  if (!response.status) {
    showErrorToast(
      response.message || 'Adding guest failed. Please try again.',
    );
    return;
  }

  return response.data;
};
export const UploadCSVFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await API.Post<any, ResponseType<any>>(
    apiEndpoints.UPLOAD_CSV,
    formData,
    true,
  );

  if (!response.status) {
    showErrorToast(
      response.message || 'Uploading file failed. Please try again.',
    );
    return;
  }

  return response.data;
};
export const DeleteGuest = async (id: string) => {
  const response = await API.Delete<any>(`${apiEndpoints.DELETE_GUEST}/${id}`);
  if (!response.status) {
    showErrorToast(
      response.message || 'Deleting guest failed. Please try again.',
    );
    return;
  }
  showSuccessToast(response?.message || 'Deleted successfully from the list');

  return response.data;
};
export const DeleteGuestList = async () => {
  const response = await API.Delete<any>(apiEndpoints.DELETE_ALL_GUEST);
  if (!response.status) {
    showErrorToast(
      response.message || 'Removing all guests from the list failed',
    );
    return;
  }
  showSuccessToast(
    response?.message || 'Removed all guests successfully from the list',
  );

  return response.data;
};
