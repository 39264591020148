import * as React from 'react';

import { SVGComponentProps } from './Venu';
const DropdownArrow: React.FC<SVGComponentProps> = ({
  color = '#585C67',
  ...props
}) => (
  <svg
    width='12'
    height='10'
    viewBox='0 0 12 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11 7L6 2L1 7'
      stroke={color}
      strokeOpacity='0.6'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default DropdownArrow;
