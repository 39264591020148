/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '@/assets/svg/loader';
import StartedArrow from '@/assets/svg/StartedArrow';
import { handleUpdateEvent } from '@/services/planner';
import { showErrorToast } from '@/utils/toast';

const SaveProgressButton = ({
  text,
  route,
  className,
  smallText,
  id,
  apiRoute,
  rightIcon,
  selectedValue,
  guest,
  invitation,
  partyFavour,
}: {
  text?: string;
  route?: string;
  className?: string;
  smallText?: boolean;
  rightIcon?: boolean;
  id?: string | null;
  apiRoute?: string;
  selectedValue?: any;
  guest?: boolean;
  invitation?: boolean;
  partyFavour?: string[];
}) => {
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);

  const handleUpdate = async () => {
    if (!loading) {
      if (id && route && apiRoute) {
        setloading(true);
        if (invitation && selectedValue?.image) {
          const { status }: any = await handleUpdateEvent(
            id,
            apiRoute,
            selectedValue,
          );
          if (status === 200) {
            navigate(`${route}?eventId=${id}`);
          }
          setloading(false);
          return;
        } else if (
          guest &&
          // selectedValue?.serviceIds?.length &&
          selectedValue?.approxGuestCount
        ) {
          const { status }: any = await handleUpdateEvent(
            id,
            apiRoute,
            selectedValue,
          );
          if (status === 200) {
            navigate(`${route}?eventId=${id}`);
          }
          setloading(false);
          return;
        } else if (selectedValue?.length) {
          try {
            const { status }: any = await handleUpdateEvent(id, apiRoute, {
              ...(Array.isArray(selectedValue)
                ? { serviceIds: selectedValue }
                : { serviceId: selectedValue }),
            });
            if (apiRoute === 'decor') {
              await handleUpdateEvent(id, 'party-favors', {
                serviceIds: partyFavour,
              });
            }
            if (status === 200) {
              navigate(`${route}?eventId=${id}`);
            }
          } catch (error) {
            showErrorToast('Something went wrong');
          }
          setloading(false);
          return;
        }
        if (guest && selectedValue?.approxGuestCount) {
          showErrorToast('Please add Guests');
        } else if (guest) {
          showErrorToast('Please add Approximate Guest Count');
        } else {
          showErrorToast('Please select something first');
        }
        setloading(false);
      } else if (route) {
        navigate(route);
      }
    }
  };

  return (
    <button
      onClick={handleUpdate}
      className={`bg-voiletColor rounded-[100px] my-2 leading-5 gap-2  ${smallText ? 'text-[10px] lg:text-base' : 'text-sm md:text-base'}  font-medium text-white py-2 px-4 lg:py-5 lg:px-8 ${className}`}
    >
      {loading ? (
        <div className='w-[148px] flex justify-center h-6'>
          <Loader fill='#ffffff' />
        </div>
      ) : (
        text || 'Save Your Progress'
      )}
      {rightIcon ? <StartedArrow className='inline ml-2' /> : null}
    </button>
  );
};

export default SaveProgressButton;
