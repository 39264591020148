import * as React from 'react';

import { SVGComponentProps } from './Venu';
const EntertainmentSVG: React.FC<SVGComponentProps> = ({
  color = '#0A0C2C',
  ...props
}) => (
  <svg
    width={50}
    height={51}
    viewBox='0 0 50 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23.377 10.7587V11.629C24.5848 11.5472 25.2315 11.6452 25.2986 11.6562C25.6874 11.7197 26.0195 11.9708 26.1867 12.3273L27.8841 15.9448C28.0895 15.9927 28.2851 16.0941 28.4468 16.2518C28.6516 16.4514 28.8967 16.6077 29.1744 16.6754C29.7289 16.8106 30.2791 16.6565 30.6679 16.2784C31.0692 15.8884 31.6814 15.7892 32.1544 16.0883C32.8307 16.5155 32.9033 17.4206 32.3729 17.951C31.6246 18.6996 30.6295 19.1119 29.5708 19.1119C29.5027 19.1119 29.435 19.1101 29.3672 19.1067L31.649 23.9703C31.7455 24.1754 31.8367 24.382 31.9234 24.5895C34.4927 23.7793 37.415 24.3811 39.457 26.3998C39.9135 26.8509 39.9642 27.5876 39.5375 28.0673C39.2992 28.3353 38.9714 28.4692 38.6437 28.4692C38.3382 28.4692 38.0324 28.3526 37.7996 28.1195C36.4156 26.7358 34.4286 26.315 32.6769 26.8548C33.3412 29.5038 33.297 32.2375 32.6113 34.801C32.9341 34.9078 33.2628 35.003 33.5967 35.086C33.9842 35.1822 34.3892 35.1831 34.7771 35.0873C40.7485 33.6102 45.0008 28.24 45.0008 22.0184V10.7587C45.0008 10.2011 44.6565 9.70123 44.1347 9.505C42.4901 8.88702 39.3117 8.04688 34.1893 8.04688C29.0666 8.04688 25.8879 8.88733 24.243 9.50531C23.7212 9.70123 23.377 10.2011 23.377 10.7587ZM36.5779 16.2625C37.0442 15.7962 37.8002 15.7962 38.2668 16.2625C38.564 16.5597 38.9592 16.7236 39.3798 16.7236C39.8003 16.7236 40.1958 16.5597 40.493 16.2625C40.9593 15.7959 41.7156 15.7959 42.1819 16.2625C42.6482 16.7288 42.6482 17.4847 42.1819 17.951C41.4333 18.6996 40.4384 19.1119 39.3798 19.1119C38.3214 19.1119 37.3262 18.6996 36.5776 17.951C36.1116 17.4847 36.1116 16.7288 36.5779 16.2625Z'
      fill={color}
    />
    <path
      d='M29.4863 24.9847L24.6783 14.7369C24.457 14.2651 23.9669 13.981 23.4472 14.0203C21.711 14.1516 18.4545 14.7274 13.764 16.9281C9.12621 19.104 6.60546 21.2149 5.37896 22.4728C4.98986 22.872 4.89037 23.4707 5.12719 23.9755L9.90959 34.1693C12.5521 39.8016 18.6834 42.8573 24.717 41.658C25.107 41.5805 25.472 41.4092 25.7809 41.1587C30.5599 37.2854 32.1288 30.6173 29.4863 24.9847ZM19.8238 24.2208C20.5663 22.1658 22.8423 21.0977 24.8974 21.8405C25.5175 22.0645 25.8385 22.749 25.6145 23.3694C25.3905 23.9895 24.706 24.3109 24.0856 24.0866C23.2693 23.7918 22.365 24.216 22.0699 25.0323C21.8941 25.5188 21.4358 25.8212 20.9469 25.8212C20.812 25.8212 20.675 25.7983 20.541 25.7498C19.9209 25.5255 19.5995 24.841 19.8238 24.2208ZM15.2056 28.2528C14.8101 28.11 14.3826 28.1295 14.0017 28.3084C13.6211 28.4869 13.3327 28.8031 13.1899 29.1986C13.0141 29.685 12.5555 29.9874 12.0666 29.9874C11.932 29.9874 11.795 29.9642 11.661 29.916C11.0406 29.692 10.7195 29.0075 10.9435 28.3871C11.3033 27.3916 12.029 26.5957 12.9873 26.1462C13.9458 25.6967 15.0216 25.6469 16.0171 26.0067C16.6375 26.2307 16.9585 26.9152 16.7345 27.5357C16.5102 28.1558 15.8257 28.4768 15.2056 28.2528ZM19.2153 36.0586C18.3715 36.0586 17.5143 35.9152 16.6759 35.6121C16.0555 35.3881 15.7345 34.7036 15.9585 34.0832C16.1828 33.4631 16.867 33.1417 17.4874 33.366C20.1354 34.3228 23.0682 32.9467 24.0249 30.299C24.2489 29.6786 24.9334 29.3576 25.5538 29.5816C26.1739 29.8059 26.495 30.4904 26.271 31.1108C25.1696 34.1586 22.2839 36.0586 19.2153 36.0586Z'
      fill={color}
    />
  </svg>
);
export default EntertainmentSVG;
