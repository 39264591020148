/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { decorFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setDecorFilters } from '@/redux/slices/filters';
import { GetDecorList } from '@/services/planner';

const PlannerDecor = () => {
  const dispatch = useAppDispatch();
  const { decorFilters } = useAppSelector((state) => state.filters);
  const [decorLists, setDecorLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [page, setPage] = useState(1);
  const getVenues = async () => {
    setLoading(true);
    const { data }: any = await GetDecorList({ page, ...decorFilters });
    setDecorLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getVenues();
  }, [page, decorFilters]);

  const setFilters = (value: any) => {
    dispatch(setDecorFilters(value));
  };

  const data = decorLists?.map((list: any) => {
    return {
      ...list,
      title: list?.businessName,
      imgUrl: list?.images?.length ? list?.images[0] : '',
      cardData: { subtitle: list?.description },
    };
  });

  return (
    <PlannerLayout
      tabLength={4}
      heroRoute={ROUTES.PLANNER_FOOD}
      heroLength={3}
      skipText='Decor already secured?'
      cardData={data}
      sections={decorFilterSections}
      setFilters={setFilters}
      filters={decorFilters}
      cardType='decor'
      distance
      setPage={setPage}
      totalTablePages={totalPages}
      apiRoute='decor'
      multiSelect
      loading={loading}
      setPartFavour={setSelectedValue}
      partyFavour={selectedValue}
    />
  );
};

export default PlannerDecor;
