import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import CheckboxCombo from '@/components/ui/Checkbox';
import TextInputCombo from '@/components/ui/Input';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

const EquipmentRental = () => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };
  const { equipmentRentalOptions } = useConstantData();
  return (
    <div>
      <h1 className='block text-lg mt-6 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.entertainment.musiciansOptions')}
      </h1>

      <CheckboxCombo
        name='rentalMusiciansOptions'
        options={equipmentRentalOptions}
        label=''
      />
      <h1 className='block text-lg mt-6 leading-6 text-gray-600 font-semibold'>
        {t('vendorSignUpQuestionnaire.equipmentRental.perHourPrice')}
      </h1>
      <div className='flex justify-between w-full gap-4'>
        <TextInputCombo
          label=''
          inputStyle='w-full sm:w-[200px]'
          name='equipmentRentalFromRate'
          placeholder={t('vendorSignUpQuestionnaire.min')}
          type='number'
        />
        <p className='mx-2.5 mt-5 md:mx-8'>
          {t('vendorSignUpQuestionnaire.to')}
        </p>
        <TextInputCombo
          label=''
          inputStyle='w-full sm:w-[200px]'
          name='equipmentRentalToRate'
          placeholder={t('vendorSignUpQuestionnaire.max')}
          type='number'
        />
      </div>
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </div>
  );
};

export default EquipmentRental;
