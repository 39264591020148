import React from 'react';

import { Typography } from './Typography';

interface Props {
  title: string;
}

const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <div className='flex justify-center items-center'>
      <div className='relative p-0.5 rounded-full'>
        <div className='absolute inset-0 rounded-full bg-gradient-to-r from-voiletColor via-brandGreen to-willowFlower' />
        <div className='relative py-2 md:py-4 px-8 md:px-16 bg-cream rounded-full'>
          <Typography
            variant='title'
            className='text-brandGreen font-extrabold text-base md:text-4x'
          >
            {title}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
