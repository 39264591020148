import * as React from 'react';

import { SVGComponentProps } from './Venu';

const BarTenderSvg: React.FC<SVGComponentProps> = ({
  color = '#0A0C2C',
  ...props
}) => (
  <svg
    width={36}
    height={43}
    viewBox='0 0 36 43'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M23.2927 6.97132C20.458 6.49433 17.56 7.32966 15.4141 9.24225L23.8283 17.2572L29.0345 22.2161C30.8405 19.9798 31.5339 17.0447 30.9198 14.2366L23.2927 6.97132ZM20.9984 9.6288C20.2638 9.88303 19.5633 10.2264 18.9123 10.6512C18.5485 10.8886 18.0611 10.7862 17.8238 10.4224C17.5865 10.0587 17.6888 9.57129 18.0526 9.33397C18.8113 8.83899 19.628 8.43877 20.4841 8.14247C20.8947 8.00042 21.3426 8.21807 21.4845 8.62861C21.6265 9.03915 21.4089 9.48694 20.9984 9.629V9.6288Z'
      fill={color}
    />
    <path
      d='M13.0341 10.5525C12.9319 10.6591 12.8768 10.802 12.8807 10.9496C12.896 11.5144 12.6847 12.0618 12.2939 12.4697L12.2835 12.4806C9.33513 15.5774 6.69908 18.9571 4.4137 22.5709L0.181604 29.2668C-0.117549 29.7424 -0.0407699 30.3628 0.365147 30.7512L8.28126 38.2915C8.68894 38.6782 9.31251 38.7247 9.77299 38.4027L16.2551 33.8499C19.7538 31.3915 23.0016 28.5943 25.9515 25.4988L25.9619 25.4879C26.3503 25.0779 26.8867 24.8404 27.4513 24.8283C27.5988 24.8252 27.739 24.7631 27.8405 24.656L28.5418 23.9197L13.7353 9.81628L13.0341 10.5525ZM9.412 20.7707C8.46057 22.0544 7.54522 23.3901 6.69161 24.7405C6.45144 25.1025 5.96333 25.2012 5.60146 24.961C5.25069 24.7283 5.14541 24.2607 5.36247 23.9001C6.23683 22.5169 7.1743 21.1489 8.14874 19.8341C8.40739 19.4851 8.89991 19.4119 9.24891 19.6706C9.59791 19.9292 9.67115 20.4217 9.4125 20.7707H9.412ZM11.8287 17.707C11.6618 17.9059 11.4961 18.1059 11.3318 18.3067C11.0566 18.6428 10.5611 18.6923 10.225 18.4172C9.8889 18.1421 9.83945 17.6466 10.1145 17.3105C10.2829 17.1047 10.4526 16.8999 10.6236 16.6961C10.9028 16.3633 11.3988 16.3198 11.7316 16.599C12.0644 16.8781 12.1079 17.3742 11.8287 17.707Z'
      fill={color}
    />
    <path
      d='M24.7812 6.21411L25.8666 5.0753L32.6994 11.5872L31.614 12.726L24.7812 6.21411Z'
      fill={color}
    />
    <path
      d='M35.6342 6.78067L30.5094 1.89886C30.0376 1.44949 29.2909 1.46768 28.8415 1.93936L28.5703 2.22446L35.4038 8.73377L35.6751 8.44868C36.1236 7.9765 36.1053 7.23034 35.6342 6.78067Z'
      fill={color}
    />
    <path
      d='M26.9531 3.93579L27.4958 3.36639L34.3286 9.87829L33.7859 10.4477L26.9531 3.93579Z'
      fill={color}
    />
    <path
      d='M11.1567 4.74573C10.857 5.06012 10.8689 5.55796 11.1833 5.8577C11.4976 6.15745 11.9955 6.14555 12.2952 5.83106L12.3885 5.73275C13.2252 4.84974 14.3805 4.33815 15.5967 4.31219C16.031 4.30157 16.3746 3.94088 16.364 3.50655C16.3534 3.07222 15.9927 2.72864 15.5584 2.73925C13.9251 2.77455 12.3738 3.46172 11.2502 4.64772L11.1567 4.74573Z'
      fill={color}
    />
    <path
      d='M9.98969 3.33566C10.205 3.33595 10.411 3.24767 10.5593 3.09156L10.6524 2.99384C11.489 2.11083 12.644 1.59933 13.8601 1.57318C14.2944 1.56266 14.638 1.20197 14.6274 0.767539C14.6168 0.333113 14.2562 -0.0103777 13.8218 0.000239652C12.1884 0.0355325 10.637 0.72271 9.51329 1.9088L9.42019 2.00652C9.12054 2.32101 9.13264 2.81885 9.44713 3.11839C9.59341 3.2577 9.78757 3.33546 9.98959 3.33546V3.33566H9.98969Z'
      fill={color}
    />
    <path
      d='M21.3528 37.6918L21.4406 37.5893C21.7304 37.2658 21.7032 36.7686 21.3797 36.4787C21.0563 36.1888 20.559 36.2161 20.2691 36.5396C20.2616 36.548 20.2541 36.5567 20.2469 36.5654L20.1591 36.6678C19.3696 37.593 18.2427 38.164 17.0297 38.2534C16.5965 38.2863 16.2721 38.6641 16.305 39.0972C16.3362 39.507 16.6776 39.8237 17.0887 39.8241C17.1088 39.8241 17.1291 39.8233 17.1494 39.8217C18.7788 39.7017 20.2924 38.9347 21.3528 37.6918Z'
      fill={color}
    />
    <path
      d='M23.231 39.1262C22.9014 38.8434 22.4049 38.8814 22.1221 39.211L22.0342 39.3134C21.2446 40.2387 20.1177 40.8096 18.9047 40.899C18.4715 40.9319 18.1471 41.3097 18.18 41.7427C18.2112 42.1526 18.5526 42.4693 18.9637 42.4696C18.9838 42.4696 19.0041 42.4689 19.0244 42.4674C20.6538 42.3474 22.1674 41.5804 23.2279 40.3375L23.3158 40.2352C23.5986 39.9056 23.5607 39.4091 23.231 39.1262Z'
      fill={color}
    />
  </svg>
);
export default BarTenderSvg;
