import * as React from 'react';

import { SVGComponentProps } from '../Venu';

const Fall: React.FC<SVGComponentProps> = ({ color = '#6B759D', ...props }) => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='22.163' rx='2.93333' fill={color} />
    <path
      d='M11.4237 4.19991C11.3613 4.03731 11.0034 3.84606 10.8032 4.19991C10.8032 4.19991 9.69226 7.16777 7.40109 9.06112C6.47657 9.82507 6 11.0111 6 12.211C6 14.3041 7.57495 16.5029 10.7726 16.5029V14.3825L8.5056 12.4394C8.15277 12.0706 8.6498 11.6547 8.94876 11.9212L10.7726 13.486V11.6622L9.19762 10.4008C8.83968 10.1094 9.28284 9.6011 9.62033 9.86904L10.7726 10.7895V9.29634L9.90328 8.7134C9.53545 8.44614 9.87601 7.91672 10.2783 8.14751L10.7726 8.47477V7.40776C10.7726 6.95539 11.4544 6.96596 11.4544 7.40776V8.47477L11.9487 8.14751C12.2579 7.92218 12.7225 8.39739 12.3237 8.7134L11.4544 9.29634V10.7895L12.6066 9.86904C12.8906 9.6253 13.4111 10.016 13.0293 10.4008L11.4544 11.6622V13.486L13.2782 11.9212C13.5737 11.6669 14.066 12.0494 13.7213 12.4394L11.4544 14.3825V16.5029C14.5753 16.6025 16.2269 14.3041 16.2269 12.211C16.2269 11.0111 15.7173 9.86393 14.8258 9.06112C12.3714 6.85074 11.4237 4.19991 11.4237 4.19991Z'
      fill='white'
    />
    <path
      d='M10.7725 16.5029V18.6574C10.7725 19.1197 11.4543 19.1088 11.4543 18.6574V16.5029C11.3384 16.5063 10.8884 16.5063 10.7725 16.5029Z'
      fill='white'
    />
  </svg>
);
export default Fall;
