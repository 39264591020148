/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { entertainmentFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setEntertainmentFilters } from '@/redux/slices/filters';
import { GetEntertainmentList } from '@/services/planner';

const PlannerEntertainment = () => {
  const dispatch = useAppDispatch();
  const { entertainmentFilters } = useAppSelector((state) => state.filters);
  const [entertainmentLists, setEntertainmentLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getEntertainmentServices = async () => {
    setLoading(true);

    const { data }: any = await GetEntertainmentList({
      page,
      ...entertainmentFilters,
    });
    setEntertainmentLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getEntertainmentServices();
  }, [entertainmentFilters, page]);

  const setFilters = (value: any) => {
    dispatch(setEntertainmentFilters(value));
  };

  const data = entertainmentLists?.map((list: any) => {
    return {
      ...list,
      title: list?.businessName,
      imgUrl: list?.images?.length ? list?.images[0] : '',
      cardData: { subtitle: list?.description },
    };
  });
  return (
    <PlannerLayout
      tabLength={9}
      heroRoute={ROUTES.PLANNER_INVITATION}
      heroLength={9}
      skipText='Entertainment already secured?'
      cardData={data}
      sections={entertainmentFilterSections}
      cardType='decor'
      setPage={setPage}
      setFilters={setFilters}
      filters={entertainmentFilters}
      totalTablePages={totalPages}
      apiRoute='entertainment'
      multiSelect
      loading={loading}
      map={false}
    />
  );
};

export default PlannerEntertainment;
