import { ResponseType } from '@/@types';
import { API } from '@/api/ApiInstance';
import { SignupType, UserRoles } from '@/constants';
import { apiEndpoints } from '@/constants/apiEndpoints';
import { clearStorage } from '@/utils/common';
import { showSuccessToast } from '@/utils/toast';
import { LoginCredentials } from '@/utils/types/authApiInterfaces';

import { signup } from './authServices';
import { getApiEndpointByCategory } from './mapData';

export const createBusinessType = async (
  credentials: LoginCredentials,
  category: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await API.Post<LoginCredentials, ResponseType<any>>(
    `${apiEndpoints.BUSINESS_VENDOR}/add-${category}`,
    {
      ...credentials,
    },
  );

  if (!response.status) {
    return;
  }
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const vendorSignup = async (postData: any) => {
  const { signupData, createBusinessData } = postData;

  const response = await signup({
    ...signupData,
    userRole: UserRoles.VENDOR,
    signupType: SignupType.EMAIL,
    suppressToast: true,
  });
  const mappedCategory = getApiEndpointByCategory(createBusinessData.category);
  if (!response) return false;

  await createBusinessType(createBusinessData, mappedCategory);
  clearStorage();
  showSuccessToast('Successfully signed up and created business');
  return true;
};
