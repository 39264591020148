import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';

import { ROUTES } from '@/constants';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900'>
      <div className='text-center'>
        <h1 className='text-6xl font-bold text-gray-900 dark:text-gray-100'>
          404
        </h1>
        <h2 className='mt-4 text-3xl font-semibold text-gray-700 dark:text-gray-300'>
          Page Not Found
        </h2>
        <p className='mt-2 text-lg text-gray-600 dark:text-gray-400'>
          {t('notFound')}
        </p>
      </div>
      <div className='mt-8'>
        <svg
          className='w-64 h-64'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M13 14H11V12H13V14ZM13 10H11V8H13V10ZM13 18H11V16H13V18ZM21 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H21C22.1 20 23 19.1 23 18V6C23 4.9 22.1 4 21 4ZM21 18H3V6H21V18Z'
            fill='currentColor'
            className='text-gray-400 dark:text-gray-600'
          />
        </svg>
      </div>
      <Link
        to={ROUTES.HOME}
        className='mt-8 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
      >
        <Home className='mr-2 -ml-1 h-5 w-5' aria-hidden='true' />
        Back to Home
      </Link>
    </div>
  );
};

export default NotFound;
