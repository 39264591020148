import React from 'react';

import SaveProgressButton from './SaveProgressButton';
import { Typography } from './Typography';

const PlannerInterestedSection = () => {
  return (
    <div className='ShadowBox md:flex items-center text-center md:text-left md:my-32 my-10 justify-between bg-white rounded-[30px] md:py-[70px] p-4 md:px-24'>
      <div>
        <Typography variant='heading'>Interested in advertising?</Typography>
        <Typography className='text-paragraphText block mt-3'>
          Partner with us!
        </Typography>
      </div>
      <SaveProgressButton text='Get Started' route={''} rightIcon />
    </div>
  );
};

export default PlannerInterestedSection;
