import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Location: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1.04163C6.8975 1.04163 4.375 3.58663 4.375 6.72579C4.375 7.78913 4.84333 9.10829 5.525 10.4241C7.02583 13.3216 9.52583 16.24 9.52583 16.24C9.64417 16.3783 9.8175 16.4583 10 16.4583C10.1825 16.4583 10.3558 16.3783 10.4742 16.24C10.4742 16.24 12.9742 13.3216 14.475 10.4241C15.1567 9.10829 15.625 7.78913 15.625 6.72579C15.625 3.58663 13.1025 1.04163 10 1.04163ZM10 4.37496C8.735 4.37496 7.70833 5.40163 7.70833 6.66663C7.70833 7.93163 8.735 8.95829 10 8.95829C11.265 8.95829 12.2917 7.93163 12.2917 6.66663C12.2917 5.40163 11.265 4.37496 10 4.37496Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.9863 14.4807C14.6638 14.6841 15.2222 14.9357 15.608 15.2307C15.858 15.4207 16.0413 15.6066 16.0413 15.8332C16.0413 15.9666 15.9655 16.0874 15.8588 16.2082C15.6822 16.4074 15.4197 16.5866 15.0947 16.7532C13.9463 17.3407 12.0897 17.7082 9.99967 17.7082C7.90967 17.7082 6.05301 17.3407 4.90467 16.7532C4.57967 16.5866 4.31717 16.4074 4.14051 16.2082C4.03384 16.0874 3.95801 15.9666 3.95801 15.8332C3.95801 15.6066 4.14134 15.4207 4.39134 15.2307C4.77717 14.9357 5.33551 14.6841 6.01301 14.4807C6.34301 14.3816 6.53051 14.0324 6.43134 13.7024C6.33217 13.3716 5.98301 13.1841 5.65301 13.2832C4.62134 13.5941 3.81967 14.0199 3.33801 14.4907C2.91467 14.9032 2.70801 15.3649 2.70801 15.8332C2.70801 16.4182 3.03884 17.0016 3.71134 17.4882C4.89134 18.3416 7.26134 18.9582 9.99967 18.9582C12.738 18.9582 15.108 18.3416 16.288 17.4882C16.9605 17.0016 17.2913 16.4182 17.2913 15.8332C17.2913 15.3649 17.0847 14.9032 16.6613 14.4907C16.1797 14.0199 15.378 13.5941 14.3463 13.2832C14.0163 13.1841 13.6672 13.3716 13.568 13.7024C13.4688 14.0324 13.6563 14.3816 13.9863 14.4807Z'
      fill={color}
    />
  </svg>
);
export default Location;
