/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { photographyFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setPhotographerFilters } from '@/redux/slices/filters';
import { GetPhotographerList } from '@/services/planner';

const PlannerPhotography = () => {
  const dispatch = useAppDispatch();
  const { photographerFilters } = useAppSelector((state) => state.filters);
  const [photoLists, setPhotoLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getPhotos = async () => {
    setLoading(true);
    const { data }: any = await GetPhotographerList({
      page,
      ...photographerFilters,
    });
    setPhotoLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getPhotos();
  }, [photographerFilters, page]);

  const setFilters = (value: any) => {
    dispatch(setPhotographerFilters(value));
  };

  const data = photoLists?.map((list: any) => {
    return {
      ...list,
      title: list?.businessName,
      imgUrl: list?.images?.length ? list?.images[0] : '',
      cardData: { subtitle: list?.description },
    };
  });
  return (
    <PlannerLayout
      tabLength={8}
      heroRoute={ROUTES.PLANNER_ENTERTAINMENT}
      heroLength={8}
      skipText='Photography already secured?'
      cardData={data}
      sections={photographyFilterSections}
      cardType='decor'
      setFilters={setFilters}
      filters={photographerFilters}
      setPage={setPage}
      totalTablePages={totalPages}
      apiRoute='photographer'
      multiSelect
      loading={loading}
    />
  );
};

export default PlannerPhotography;
