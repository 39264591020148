export const VENDOR_SIGNUP_INITIAL_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  title: '',
  phone: '',
  password: '',
  confirmPassword: '',
  business: '',
  images: [],
  category: '',
  venuType: '',
  location: '',
  website: '',
  socialMedia: [{ url: '', platform: '' }],
  description: '',
  selectedOptions: [],
  numberOfSpaces: 1,
  selectedServiceOption: '',
  averagePricePerPerson: 0,
  selectedBallonOption: '',
  selectedLightingOption: '',
  selectedPartyOption: '',
  selectedDecorOption: '',
  selectedAccentsOptions: [],
  selectedDecorsubOption: [],
  spaces: [
    {
      vibeDropdown: '',
      environmentOptions: '',
      hourlyDropdown: '',
      capacityStanding: '',
      capacitySeated: '',
      rate: '',
    },
  ],
  wirelessInternet: '',
  outsideCatering: '',
  smokingAllowed: '',
  outsideAlcohol: '',
  selectedCuisineOptions: [],
  selectedDietaryOptions: [],
  servingStyleOptions: [],
  cateringAmenities: [],
  deliveryOptionPickup: [],
  delivery_min: '',
  delivery_max: '',
  buffet_min: '',
  buffet_max: '',
  platedDinner_min: '',
  plattedDinner_max: '',
  familyStyle_min: '',
  familyStyle_max: '',
  passedAppetizers_min: '',
  passedAppetizers_max: '',
  setUp: [],
  setUp_min: '',
  setUp_max: '',
  cleanUp: [],
  cleanUp_min: '',
  cleanUp_max: '',
  foodServiceRentals_min: '',
  foodServiceRentals_max: '',
  foodServiceRentals: [],
  servers: [],
  servers_per_hour: '',
  chefStations: [],
  chefStations_per_hour: '',
  attendantPrice: '',
  drinkPriceRange_min: '',
  drinkPriceRange_max: '',
  selectedDrinkServices: [],
  provideAlcohol: '',
  serveAlcohol: '',
  selectedDrinkTypes: [],
  photographyServiceOptions: [],
  photography_min: '',
  photography_max: '',
  videography_min: '',
  videography_max: '',
  photobooth_min: '',
  photobooth_max: '',
  selectedAdditionalServices: [],
  selectedBakeryCuisineOptions: [],
  selectedBakeryDietaryOptions: [],
  bakeryDesertType: [],
  cake_min: '',
  cake_max: '',
  cupCake_min: '',
  cupCake_max: '',
  cookies_min: '',
  cookies_max: '',
  bakeryDeliveryOptionPickup: [],
  bakery_delivery_max: '',
  bakery_delivery_min: '',
  entertainmentOptions: '',
  musiciansOptions: [],
  musiciansHourlyFromRate: '',
  musiciansHourlyToRate: '',
  rentalMusiciansOptions: [],
  equipmentRentalFromRate: '',
  equipmentRentalToRate: '',
  partyFavorFromRate: '',
  partyFavorToRate: '',
};
