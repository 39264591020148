/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { foodFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setFoodFilters } from '@/redux/slices/filters';
import { GetFoodList } from '@/services/planner';

const PlannerFood = () => {
  const dispatch = useAppDispatch();
  const { foodFilters } = useAppSelector((state) => state.filters);
  const [foodLists, setFoodLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const getVenues = async () => {
    setLoading(true);
    const { data }: any = await GetFoodList({ page, ...foodFilters });
    setFoodLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getVenues();
  }, [page, foodFilters]);

  const setFilters = (value: any) => {
    dispatch(setFoodFilters(value));
  };

  const data = foodLists?.map((list: any) => {
    return {
      ...list,
      title: list?.businessName,
      imgUrl: list?.images?.length ? list?.images[0] : '',
      cardData: { subtitle: list?.description },
    };
  });

  return (
    <PlannerLayout
      tabLength={5}
      heroRoute={ROUTES.PLANNER_DESERT}
      heroLength={5}
      skipText='Food already secured?'
      cardData={data}
      sections={foodFilterSections}
      setFilters={setFilters}
      filters={foodFilters}
      cardType='decor'
      distance
      setPage={setPage}
      totalTablePages={totalPages}
      apiRoute='food'
      multiSelect
      loading={loading}
    />
  );
};

export default PlannerFood;
