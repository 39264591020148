import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Planning: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='120'
    height='88'
    viewBox='0 0 88 88'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g filter='url(#filter0_d_1969_4389)'>
      <rect
        x='11'
        y='11.6058'
        width='54'
        height='53'
        rx='26.5'
        fill='white'
        shapeRendering='crispEdges'
      />
      <g clipPath='url(#clip0_1969_4389)'>
        <g clipPath='url(#clip1_1969_4389)'>
          <path
            d='M35.4279 42.1837L34.951 44.9619C34.9389 45.0321 34.9468 45.1044 34.9736 45.1704C35.0005 45.2365 35.0453 45.2937 35.103 45.3355C35.1607 45.3774 35.229 45.4023 35.3001 45.4074C35.3712 45.4125 35.4423 45.3976 35.5054 45.3644L38.0004 44.0519L40.4954 45.3644C40.5585 45.3976 40.6296 45.4125 40.7007 45.4074C40.7718 45.4023 40.8401 45.3774 40.8978 45.3355C40.9555 45.2937 41.0003 45.2365 41.0271 45.1704C41.054 45.1044 41.0618 45.0321 41.0498 44.9619L40.5729 42.1837L42.5916 40.2162C42.6434 40.1666 42.6801 40.1034 42.6976 40.0338C42.715 39.9643 42.7126 39.8912 42.6905 39.823C42.6684 39.7548 42.6275 39.6942 42.5726 39.6481C42.5176 39.602 42.4508 39.5724 42.3798 39.5625L39.5904 39.1569L38.3404 36.6294C38.3091 36.5648 38.2603 36.5103 38.1995 36.4722C38.1387 36.434 38.0684 36.4138 37.9966 36.4138C37.9249 36.4138 37.8546 36.434 37.7938 36.4722C37.733 36.5103 37.6841 36.5648 37.6529 36.6294L36.4029 39.1569L33.621 39.5625C33.5505 39.5727 33.4843 39.6025 33.4298 39.6484C33.3753 39.6943 33.3347 39.7545 33.3127 39.8223C33.2906 39.89 33.288 39.9626 33.305 40.0318C33.3221 40.101 33.3581 40.164 33.4091 40.2137L35.4279 42.1837Z'
            fill={color}
          />
          <path
            d='M38 22.5C29.1637 22.5 22 29.6637 22 38.5C22 47.3363 29.1637 54.5 38 54.5C46.8363 54.5 54 47.3363 54 38.5C54 29.6637 46.8363 22.5 38 22.5ZM43.205 28.4125C43.205 28.1871 43.2946 27.9709 43.454 27.8115C43.6134 27.6521 43.8296 27.5625 44.055 27.5625C44.2804 27.5625 44.4966 27.6521 44.656 27.8115C44.8154 27.9709 44.905 28.1871 44.905 28.4125V31.0044C44.905 31.2298 44.8154 31.446 44.656 31.6054C44.4966 31.7648 44.2804 31.8544 44.055 31.8544C43.8296 31.8544 43.6134 31.7648 43.454 31.6054C43.2946 31.446 43.205 31.2298 43.205 31.0044V28.4125ZM31.095 28.4125C31.095 28.1871 31.1846 27.9709 31.344 27.8115C31.5034 27.6521 31.7196 27.5625 31.945 27.5625C32.1704 27.5625 32.3866 27.6521 32.546 27.8115C32.7054 27.9709 32.795 28.1871 32.795 28.4125V31.0044C32.795 31.2298 32.7054 31.446 32.546 31.6054C32.3866 31.7648 32.1704 31.8544 31.945 31.8544C31.7196 31.8544 31.5034 31.7648 31.344 31.6054C31.1846 31.446 31.095 31.2298 31.095 31.0044V28.4125ZM48.6513 47.0581C48.6513 47.6892 48.4006 48.2944 47.9543 48.7406C47.5081 49.1868 46.9029 49.4375 46.2719 49.4375H29.7281C29.0971 49.4375 28.4919 49.1868 28.0457 48.7406C27.5994 48.2944 27.3488 47.6892 27.3488 47.0581V46.9031H48.6513V47.0581ZM48.6513 46.4762H27.3488V35.2406H48.6513V46.4762ZM48.6513 34.8131H27.3488V31.8462C27.3488 31.2151 27.5994 30.6099 28.0456 30.1636C28.4918 29.7172 29.097 29.4664 29.7281 29.4662H30.6656V31.0044C30.6656 31.343 30.8002 31.6678 31.0396 31.9073C31.2791 32.1467 31.6039 32.2812 31.9425 32.2812C32.2811 32.2812 32.6059 32.1467 32.8454 31.9073C33.0848 31.6678 33.2194 31.343 33.2194 31.0044V29.4662H42.7756V31.0044C42.7756 31.343 42.9102 31.6678 43.1496 31.9073C43.3891 32.1467 43.7139 32.2812 44.0525 32.2812C44.3911 32.2812 44.7159 32.1467 44.9554 31.9073C45.1948 31.6678 45.3294 31.343 45.3294 31.0044V29.4662H46.2669C46.898 29.4664 47.5032 29.7172 47.9494 30.1636C48.3956 30.6099 48.6462 31.2151 48.6462 31.8462L48.6513 34.8131Z'
            fill={color}
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_1969_4389'
        x='0'
        y='0.605774'
        width='88'
        height='87'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='6' dy='6' />
        <feGaussianBlur stdDeviation='8.5' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.926406 0 0 0 0 0.9625 0 0 0 0 0.934091 0 0 0 1 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1969_4389'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1969_4389'
          result='shape'
        />
      </filter>
      <clipPath id='clip0_1969_4389'>
        <rect
          width='33.4'
          height='33.4'
          fill='white'
          transform='translate(21.2998 21.4058)'
        />
      </clipPath>
      <clipPath id='clip1_1969_4389'>
        <rect
          width='32'
          height='32'
          fill='white'
          transform='translate(22 22.5)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default Planning;
