import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Selected: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='23'
    height='23'
    viewBox='0 0 23 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='11.5' cy='11.5' r='9.5' fill={color} />
    <circle
      cx='11.5'
      cy='11.5'
      r='10.2'
      stroke={color}
      strokeOpacity='0.2'
      strokeWidth='1.4'
    />
    <path
      d='M16.0465 8.33325L10.0176 14.3621L7.27722 11.6217'
      stroke='white'
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default Selected;
