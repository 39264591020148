import * as React from 'react';

import { SVGComponentProps } from './Venu';
const PartySVG: React.FC<SVGComponentProps> = ({
  color = '#0A0C2C',
  ...props
}) => (
  <svg
    width={50}
    height={51}
    viewBox='0 0 50 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1969_2196)'>
      <path
        d='M13.5677 21.8717L9.73047 32.107V32.1078C10.6695 35.8591 12.7334 39.1753 15.6551 41.6768L20.4697 39.8714C15.3558 35.2911 12.8318 28.554 13.5677 21.8717Z'
        fill={color}
      />
      <path
        d='M8.43209 35.5717L5.49086 43.4141C5.13932 44.3508 6.05567 45.2773 6.99936 44.9227L13.2045 42.5962C11.1232 40.5762 9.50866 38.1688 8.43209 35.5717Z'
        fill={color}
      />
      <path
        d='M21.7944 20.917L17.4205 16.5431C13.8741 24.5428 16.2986 33.6864 22.9897 38.9269L27.6581 37.1762C23.5581 32.9421 21.3405 27.0321 21.7944 20.917Z'
        fill={color}
      />
      <path
        d='M24.082 23.2052C24.2617 28.1776 26.4272 32.8765 30.091 36.263H30.0918L33.5135 34.9803C34.3127 34.6803 34.5314 33.6546 33.9307 33.0538L24.082 23.2052Z'
        fill={color}
      />
      <path
        d='M32.6401 26.8865C33.0977 27.3442 33.8397 27.3442 34.2973 26.8865C38.7058 22.4781 42.9984 23.841 43.0413 23.8552C43.6552 24.0598 44.3189 23.7281 44.5235 23.1141C44.7283 22.5003 44.3964 21.8365 43.7824 21.6319C43.5513 21.5549 38.0615 19.808 32.6402 25.2294C32.1825 25.6869 32.1824 26.429 32.6401 26.8865Z'
        fill={color}
      />
      <path
        d='M25.1841 17.7719C30.6056 12.3511 28.8581 6.86083 28.7815 6.62959C28.5769 6.01557 27.9128 5.68434 27.2988 5.88901C26.6856 6.09369 26.3535 6.75693 26.5582 7.37095C26.5723 7.41392 27.9355 11.7066 23.5264 16.115C23.0716 16.5697 23.067 17.3111 23.5264 17.7719C23.9864 18.2319 24.7277 18.2283 25.1841 17.7719Z'
        fill={color}
      />
      <path
        d='M27.6694 20.2579C27.2118 20.7156 27.2118 21.4575 27.6694 21.9151C28.1228 22.3686 28.8596 22.3745 29.3196 21.9221C29.925 21.6204 31.8039 22.7518 32.8481 21.7079C33.8811 20.6749 32.7836 18.8341 33.0577 18.189C33.7037 17.9142 35.5438 19.0122 36.5767 17.9793C37.61 16.9463 36.5124 15.1055 36.7865 14.4603C37.4334 14.1854 39.2724 15.2836 40.3054 14.2506C41.3386 13.2176 40.2411 11.3768 40.5152 10.7316C41.1583 10.4581 43.0019 11.5539 44.0343 10.5219C45.078 9.47797 43.9488 7.59566 44.2482 6.99343C44.699 6.53534 44.6966 5.79858 44.2413 5.34322C43.7837 4.88559 43.0417 4.88559 42.5841 5.34322C41.6605 6.26684 41.8412 7.57074 42.0152 8.50295C41.083 8.32898 39.7791 8.14829 38.8555 9.07182C37.9319 9.99544 38.1126 11.2993 38.2866 12.2316C37.3544 12.0577 36.0504 11.8768 35.1268 12.8005C34.2032 13.7241 34.384 15.028 34.558 15.9602C33.6256 15.7863 32.3218 15.6055 31.3982 16.5291C30.4746 17.4527 30.6553 18.7566 30.8293 19.6888C29.897 19.515 28.5929 19.3343 27.6694 20.2579Z'
        fill={color}
      />
      <path
        d='M34.2973 10.3148C34.7549 9.85721 34.7549 9.1153 34.2973 8.65767C33.8396 8.20005 33.0977 8.20005 32.6401 8.65767C32.1825 9.1153 32.1825 9.85721 32.6401 10.3148C33.0977 10.7725 33.8396 10.7725 34.2973 10.3148Z'
        fill={color}
      />
      <path
        d='M41.7558 18.944C42.403 18.944 42.9276 18.4193 42.9276 17.7721C42.9276 17.125 42.403 16.6003 41.7558 16.6003C41.1086 16.6003 40.584 17.125 40.584 17.7721C40.584 18.4193 41.1086 18.944 41.7558 18.944Z'
        fill={color}
      />
      <path
        d='M39.271 28.5439C38.8133 29.0015 38.8133 29.7434 39.271 30.2011C39.7286 30.6587 40.4705 30.6587 40.9281 30.2011C41.3858 29.7434 41.3858 29.0015 40.9281 28.5439C40.4706 28.0863 39.7286 28.0863 39.271 28.5439Z'
        fill={color}
      />
      <path
        d='M22.6996 11.9719C23.1572 11.5143 23.1572 10.7724 22.6996 10.3148C22.242 9.85715 21.5001 9.85715 21.0424 10.3148C20.5848 10.7723 20.5848 11.5143 21.0424 11.9719C21.5001 12.4296 22.2421 12.4296 22.6996 11.9719Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1969_2196'>
        <rect width={40} height={40} fill='white' transform='translate(5 5)' />
      </clipPath>
    </defs>
  </svg>
);
export default PartySVG;
