import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Pinterest: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_1981_1803)'>
      <path
        d='M12.5 1C6.71688 1 2.01807 5.69881 2.01807 11.4819C2.01807 15.7831 4.58434 19.4699 8.30722 21.0964C8.27107 20.3735 8.30722 19.4699 8.48795 18.6747C8.70484 17.8072 9.8253 12.9638 9.8253 12.9638C9.8253 12.9638 9.49999 12.2771 9.49999 11.3012C9.49999 9.74696 10.4036 8.59035 11.5241 8.59035C12.4638 8.59035 12.9337 9.31323 12.9337 10.1807C12.9337 11.1205 12.3193 12.5662 11.994 13.9036C11.741 15.0241 12.5361 15.9277 13.6566 15.9277C15.6446 15.9277 16.9819 13.3614 16.9819 10.3614C16.9819 8.04811 15.4277 6.34934 12.6084 6.34934C9.42768 6.34934 7.43976 8.73488 7.43976 11.3734C7.43976 12.277 7.69276 12.9277 8.12649 13.4336C8.30722 13.6505 8.34338 13.759 8.27107 14.012C8.23491 14.1927 8.09033 14.6626 8.05418 14.8433C7.98187 15.0963 7.76503 15.2047 7.51203 15.0963C6.0301 14.4818 5.37949 12.8915 5.37949 11.0842C5.37949 8.12038 7.87345 4.54207 12.8614 4.54207C16.8735 4.54207 19.512 7.43365 19.512 10.5421C19.512 14.6625 17.2349 17.7348 13.8735 17.7348C12.753 17.7348 11.6687 17.1204 11.3072 16.4336C11.3072 16.4336 10.6928 18.8553 10.5843 19.3252C10.3675 20.1204 9.93372 20.9517 9.53614 21.5662C10.4759 21.8553 11.488 21.9999 12.5 21.9999C18.2831 21.9999 22.9819 17.3011 22.9819 11.518C22.9819 5.73488 18.2831 1 12.5 1Z'
        fill='#CB1F24'
      />
    </g>
    <defs>
      <clipPath id='clip0_1981_1803'>
        <rect width='21' height='21' fill='white' transform='translate(2 1)' />
      </clipPath>
    </defs>
  </svg>
);
export default Pinterest;
