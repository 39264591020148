/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import ArrowRight from '@/assets/svg/ArrowRight';
import { tabsData } from '@/constants/plannerSection';

import Menu from './Menu';
import { Typography } from './Typography';

interface TabsProps {
  length: number;
  className?: string;
  sort?: boolean;
}

const Tabs: React.FC<TabsProps> = ({ length, className, sort = true }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('eventId');
  const [type, setType] = useState('A - Z');
  const [optionType, setOptionType] = useState('Start Rating');

  const typesDropdownData = ['A - Z', 'Z - A', 'Most Popular'].map(
    (type: string) => ({
      label: type,
      onClick: () => {
        setType(type);
      },
    }),
  );
  const optionTypesDropdownData = [
    'Start Rating',
    'Price Low to High',
    'Price High to Low',
    'Most Popular',
    'Recommended',
  ].map((type: string) => ({
    label: type,
    onClick: () => {
      setOptionType(type);
    },
  }));
  return (
    <div className='w-full flex my-10 items-start justify-between'>
      <div
        className={clsx(
          `flex gap-3 ${sort ? 'w-3/5' : ''}  flex-wrap  md:text-lg items-center font-medium`,
          className,
        )}
      >
        {tabsData?.slice(0, length)?.map((tab, index) => (
          <div
            onClick={() => navigate(`${tab?.route}?eventId=${id}`)}
            key={index}
            className='flex items-center gap-3 cursor-pointer'
          >
            <span className='text-[10px] md:text-base'>{tab.title}</span>
            {index === 0 || index < length - 1 ? (
              <ArrowRight className='h-5 w-5 md:h-7 md:w-7' />
            ) : null}
          </div>
        ))}
      </div>
      {sort && length > 1 ? (
        length === 2 ? (
          <div className='md:w-72 -mt-5 md:mt-0'>
            <Typography className='block text-xs md:text-base'>
              Sort Options
            </Typography>
            <Menu fullWidth options={typesDropdownData} selectedValue={type} />
          </div>
        ) : (
          <div className='md:w-72 -mt-5 md:mt-0'>
            <Typography className='block text-xs md:text-base'>
              Sort by (1000+ Options)
            </Typography>
            <Menu
              fullWidth
              options={optionTypesDropdownData}
              selectedValue={optionType}
            />
          </div>
        )
      ) : null}
    </div>
  );
};

export default Tabs;
