/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import PlannerLayout from '@/components/PlannerLayout';
import { ROUTES } from '@/constants';
import { themeFilterSections } from '@/constants/plannerSection';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { setThemeFilters } from '@/redux/slices/filters';
import { GetThemeList } from '@/services/planner';

const PlannerTheme = () => {
  const dispatch = useAppDispatch();
  const { themeFilters } = useAppSelector((state) => state.filters);
  const [themeLists, setThemeLists] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const getThemes = async () => {
    setLoading(true);
    const { data }: any = await GetThemeList({
      page,
      pageSize: 10,
      ...themeFilters,
    });
    setThemeLists(data?.results);
    setTotalPages(data.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getThemes();
  }, [themeFilters, page]);

  const setFilters = (value: any) => {
    dispatch(setThemeFilters(value));
  };
  const data = themeLists?.map((list: any) => {
    return {
      ...list,
      imgUrl: list?.imgUrl ? list.imgUrl : list?.images[0],
      tags: list?.season,
    };
  });

  return (
    <PlannerLayout
      tabLength={2}
      heroRoute={ROUTES.PLANNER_VENUE}
      heroLength={1}
      cardData={data}
      sections={themeFilterSections}
      setFilters={setFilters}
      filters={themeFilters}
      setPage={setPage}
      totalTablePages={totalPages}
      apiRoute='theme'
      multiSelect
      loading={loading}
    />
  );
};

export default PlannerTheme;
