import * as React from 'react';

import { SVGComponentProps } from './Venu';
const ArrowRight: React.FC<SVGComponentProps> = ({
  color = '#F3F3F5',
  ...props
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect
      x='24'
      width='24'
      height='24'
      rx='4'
      transform='rotate(90 24 0)'
      fill={color}
    />
    <path
      d='M10 17L15 12L10 7'
      stroke='#737780'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default ArrowRight;
