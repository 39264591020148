import * as React from 'react';

import { SVGComponentProps } from './Venu';
const Sorting: React.FC<SVGComponentProps> = ({
  color = '#585C68',
  ...props
}) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.33333 3L9.66667 6.33333M14.6667 6.33333L14.6667 16.3333L14.6667 6.33333ZM14.6667 16.3333L18 13L14.6667 16.3333ZM14.6667 16.3333L11.3333 13L14.6667 16.3333ZM6.33333 13L6.33333 3L6.33333 13ZM6.33333 3L3 6.33333L6.33333 3Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default Sorting;
