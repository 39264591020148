import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaGlobe } from 'react-icons/fa';
import { VscCalendar } from 'react-icons/vsc';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { SummaryCard } from '@/@types';
import Facebook from '@/assets/svg/Facebook';
import Instagram from '@/assets/svg/Instagram';
import Tiktok from '@/assets/svg/Tiktok';
import Twitter from '@/assets/svg/Twitter';
import Youtube from '@/assets/svg/Youtube';

import Button from './ui/button';
import { Typography } from './Typography';

const VendorCard: React.FC<SummaryCard> = ({
  name,
  phone,
  email,
  theme,
  cuisine,
  style,
  price,
  guest,
  item,
  paid,
  dueDate,
  followUpDate,
  status,
  socialLinks,
  website,
  category,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date(followUpDate));
  const [priceValue, setPriceValue] = useState(Number(price.replace('$', '')));
  const [paidValue, setPaidValue] = useState(Number(paid.replace('$', '')));
  const dueAmount = priceValue - paidValue;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('eventId');

  const navigation = () => {
    switch (category) {
      case 'Venue':
        navigate(`/venue?eventId=${id}`);
        break;
      case 'Decor':
        navigate(`/decor?eventId=${id}`);
        break;
      case 'Food Service':
        navigate(`/food?eventId=${id}`);
        break;
      case 'Drink Services':
        navigate(`/bar?eventId=${id}`);
        break;
      case 'Photographer/Videographer':
        navigate(`/photography?eventId=${id}`);
        break;
      case 'Bakery/Treats':
        navigate(`/desert?eventId=${id}`);
        break;
      case 'Entertainment':
        navigate(`/entertainment?eventId=${id}`);
        break;
      case 'Party Favors':
        navigate(`/party?eventId=${id}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className='border border-gray-300 rounded-2xl p-3 md:p-6 mb-6 flex flex-col lg:flex-row justify-between items-start '>
      <div className='flex items-start gap-4 '>
        <Button
          variant='outline'
          onClick={navigation}
          className='rounded-full border text-sm md:text-base border-voiletColor text-voiletColor'
        >
          Edit
        </Button>
        <div>
          <Typography className='md:text-lg font-semibold mb-2 break-words'>
            {name}
          </Typography>

          <div className='space-y-1'>
            <Typography className=' text-xs md:text-sm text-gray-500'>
              Phone: {phone}
            </Typography>
            <Typography className=' text-xs md:text-sm text-gray-500'>
              Email: {email}
            </Typography>
            {theme && (
              <Typography className=' text-xs md:text-sm text-gray-500'>
                Theme: {theme}
              </Typography>
            )}
            {cuisine && (
              <Typography className=' text-xs md:text-sm text-gray-500'>
                Cuisine: {cuisine}
              </Typography>
            )}
            {style && (
              <Typography className=' text-xs md:text-sm text-gray-500'>
                Style: {style}
              </Typography>
            )}
            {guest && (
              <Typography className=' text-xs md:text-sm text-gray-500'>
                No. of guest: {guest}
              </Typography>
            )}
            {item && (
              <Typography className=' text-xs md:text-sm text-gray-500'>
                Item: {item}
              </Typography>
            )}
          </div>

          <div className='flex items-center md:my-2'>
            {website ? (
              <Typography className='text-gray-500 bg-white h-fit IconShadow p-1 lg:p-2 mr-2 rounded-full'>
                <Link to={website}>
                  <FaGlobe />
                </Link>
              </Typography>
            ) : null}
            {socialLinks?.map((link: { platform: string; url: string }) => {
              switch (link.platform) {
                case 'facebook':
                  return (
                    <Link to={link.url}>
                      <Facebook className='' />
                    </Link>
                  );
                case 'twitter':
                  return (
                    <Link to={link.url}>
                      <Twitter />
                    </Link>
                  );
                case 'instagram':
                  return (
                    <Link to={link.url}>
                      <Instagram />
                    </Link>
                  );
                case 'tiktok':
                  return (
                    <Link to={link.url}>
                      <Tiktok />
                    </Link>
                  );
                case 'youtube':
                  return (
                    <Link to={link.url}>
                      <Youtube />
                    </Link>
                  );
              }
              return;
            })}
          </div>

          <div className='hidden lg:flex items-center mt-4 text-gray-600'>
            <Typography className='text-xs md:text-sm'>
              Schedule Follow-up :
            </Typography>
            <div className='ml-2 relative'>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date as Date)}
                dateFormat='MM.dd.yy'
                className='text-xs md:text-sm border border-gray-300 rounded-md px-2 py-2 pr-10 w-28 text-left'
              />
              <VscCalendar className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400' />
            </div>
          </div>
        </div>
      </div>
      <div className='lg:hidden flex items-center mt-4 text-gray-600'>
        <Typography className='text-xs md:text-sm'>
          Schedule Follow-up :
        </Typography>
        <div className='ml-2 relative'>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date as Date)}
            dateFormat='MM.dd.yy'
            className='text-xs md:text-sm border border-gray-300 rounded-md px-2 py-2 pr-10 w-28 text-left'
          />
          <VscCalendar className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400' />
        </div>
      </div>

      <div className='flex flex-col text-right gap-3 mt-4 lg:mt-0 item md:w-full lg:w-fit'>
        <div className='flex items-center md:justify-between'>
          <Typography className='text-xs md:text-sm text-gray-500'>
            Price{' '}
          </Typography>
          <span>
            :{' '}
            <input
              type='text'
              value={`$${priceValue}`}
              readOnly
              onChange={(e) =>
                setPriceValue(Number(e.target.value.replace('$', '')))
              }
              className='text-xs md:text-sm font-medium ml-2 border border-gray-200 text-gray-600 rounded-xl px-2 py-2'
            />
          </span>
        </div>
        <div className='flex items-center md:justify-between'>
          <Typography className='text-xs md:text-sm text-gray-500'>
            Paid{' '}
          </Typography>
          <span>
            :{' '}
            <input
              type='text'
              value={`$${paidValue}`}
              readOnly
              onChange={(e) =>
                setPaidValue(Number(e.target.value.replace('$', '')))
              }
              className='text-xs md:text-sm font-medium ml-2 border border-gray-200 text-gray-600 rounded-xl px-2 py-2'
            />
          </span>
        </div>
        <div className='flex items-center md:justify-between'>
          <Typography className='text-xs md:text-sm text-gray-500'>
            Due{' '}
          </Typography>
          <span>
            :{' '}
            <input
              type='text'
              value={`$${dueAmount} By ${dueDate}`}
              readOnly
              className='text-xs md:text-sm font-medium ml-2 border border-gray-200 text-gray-600 rounded-xl px-2 py-2'
            />
          </span>
        </div>

        <div className='flex justify-end mt-6 gap-2 pl-0 lg:pl-6'>
          <button
            className={`px-4 py-2 rounded-3xl whitespace-nowrap ${
              status === 'Confirmed'
                ? 'bg-brandGreen text-white'
                : 'bg-willowFlower text-white'
            }`}
          >
            {status}
          </button>
          <button className='px-4 py-2 whitespace-nowrap rounded-3xl border border-gray-300 text-gray-700'>
            Make Payment
          </button>
        </div>
      </div>
    </div>
  );
};
export default VendorCard;
