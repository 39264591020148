import * as React from 'react';

import { SVGComponentProps } from './Venu';
const PurpleStar: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7 0C7 3.86599 3.86599 7 0 7C3.86599 7 7 10.134 7 14C7 10.134 10.134 7 14 7C10.134 7 7 3.86599 7 0Z'
      fill='#6B759D'
    />
  </svg>
);
export default PurpleStar;
