import React, { Fragment, useState } from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';

interface Option {
  value: string;
  label: string;
}

interface CheckboxComboProps {
  name: string;
  options: Option[];
  label?: string;
  disabled?: boolean;
  typeOther?: boolean;
  containerStyle?: string;
}

const CheckboxCombo: React.FC<CheckboxComboProps> = ({
  name,
  options,
  label,
  disabled,
  typeOther,
  containerStyle,
}) => {
  const [otherValue, setOtherValue] = useState('');

  return (
    <Fragment>
      <div className={`flex flex-col gap-3 ${containerStyle}`}>
        <div className='w-full'>
          {label && <span className='mb-2'>{label}</span>}
        </div>
        <Field name={name}>
          {({ field, form }: FieldProps) => {
            const value = Array.isArray(field.value) ? field.value : [];

            return (
              <div className='w-full gap-2 grid grid-cols-2 '>
                {options.map((option) => (
                  <label
                    key={option.value}
                    className='flex items-center whitespace-nowrap text-black/80 font-semibold'
                  >
                    <input
                      type='checkbox'
                      id={option.value}
                      className="mr-2 appearance-none min-w-4 size-4 border border-gray-300 rounded-sm checked:bg-brandGreen checked:border-transparent checked:before:content-['✔'] checked:before:text-white checked:before:flex checked:before:items-center checked:before:justify-center checked:before:p-0 checked:before:text-xs"
                      value={option.value}
                      checked={value.includes(option.value)}
                      onChange={() => {
                        if (!disabled) {
                          const updatedValue = value.includes(option.value)
                            ? value.filter((v) => v !== option.value)
                            : [...value, option.value];

                          form.setFieldValue(name, updatedValue);
                        }
                      }}
                      disabled={disabled}
                    />
                    <span className='whitespace-normal break-words flex-grow'>
                      {option.label}
                    </span>
                  </label>
                ))}
                {typeOther && (
                  <div className='flex items-center my-2 col-span-2'>
                    <input
                      type='checkbox'
                      id='other'
                      className="mr-2 appearance-none size-4 border border-gray-300 rounded-sm checked:bg-brandGreen checked:border-transparent checked:before:content-['✔'] checked:before:text-white checked:before:flex checked:before:items-center checked:before:justify-center checked:before:p-0 checked:before:text-xs"
                      value='other'
                      checked={value.includes('other')}
                      onChange={() => {
                        if (!disabled) {
                          const updatedValue = value.includes('other')
                            ? value.filter((v) => v !== 'other')
                            : [...value, 'other'];

                          form.setFieldValue(name, updatedValue);
                        }
                      }}
                      disabled={disabled}
                    />
                    <input
                      type='text'
                      className='ml-2 px-1 border-b bg-transparent w-full border-black ring-0 focus:outline-none'
                      placeholder='Other...'
                      value={otherValue}
                      onChange={(e) => setOtherValue(e.target.value)}
                      disabled={disabled || !value.includes('other')}
                    />
                  </div>
                )}
              </div>
            );
          }}
        </Field>
      </div>
      <ErrorMessage name={name}>
        {(msg) => <div className='text-red-600 text-sm mb-2'>{msg}</div>}
      </ErrorMessage>
    </Fragment>
  );
};

export default CheckboxCombo;
