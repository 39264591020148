import React from 'react';

import Logo from '@/assets/png/Logo.png';
import AdvertisingBanner from '@/components/AdvertisingBanner';
import CopyRightFooter from '@/components/CopyRightFooter';
import Footer from '@/components/Footer';

import VendorRegisterForm from './modules/register-form';

const VendorRegister = () => {
  return (
    <div className='flex flex-col min-h-screen'>
      <div className='flex w-full flex-col justify-center items-center border-b border-solid border-gray-100 p-3.5 mb-4'>
        <img src={Logo} alt='Main Logo' width={157} height={144} />
      </div>
      <VendorRegisterForm />
      <div className='py-3'>
        <AdvertisingBanner />
      </div>
      <Footer />
      <CopyRightFooter bgColor='bg-bannerDark' />
    </div>
  );
};

export default VendorRegister;
