import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '@/assets/png/Logo.png';
import { ROUTES } from '@/constants';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

const Footer = () => {
  const { footerLinks, socialIcons } = useConstantData();
  const { t } = useTranslation();

  return (
    <footer className='bg-white dark:bg-gray-900'>
      <div className='mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8'>
        <div className='md:flex md:justify-between'>
          <div className='mb-6 md:mr-2 md:mb-0 flex flex-col justify-center items-center'>
            <a href={ROUTES.VENDOR_REGISTER} className='flex items-center'>
              <img
                src={Logo}
                width={215}
                height={60}
                className='me-3'
                alt='GoPlaneMe Logo'
              />
            </a>
            <p className='text-[#3d424d] text-[16px] my-6 font-sans'>
              {t('Constant.footer.company.logoTagline')}
            </p>
            <div className='flex mt-4 sm:justify-center sm:mt-0'>
              {socialIcons.map((icon, index) => (
                <img
                  key={index}
                  src={icon.src}
                  alt={icon.alt}
                  className='w-14 h-14'
                />
              ))}
            </div>
          </div>
          <div className='grid grid-cols-1 text-center sm:text-left px-2 gap-8 lg:gap-32 sm:grid-cols-3'>
            {footerLinks.map((section, index) => (
              <div key={index}>
                <h2 className='mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white'>
                  {section.title}
                </h2>
                <ul className='text-gray-500 dark:text-gray-400 font-medium'>
                  {section.links.map((link, idx) => (
                    <li key={idx} className='mb-4'>
                      <a href={link.url} className='hover:underline'>
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
