import Alcohol from './Alcohol';
import Catering from './Catering';
import Fall from './Fall';
import Indoor from './Indoor';
import Outdoor from './Outdoor';
import Spring from './Spring';
import Summer from './Summer';
import WaterFront from './WaterFront';
import Winter from './Winter';

export const tagIcons = [
  { name: 'Fall', icon: <Fall /> },
  { name: 'Summer', icon: <Summer /> },
  { name: 'Winter', icon: <Winter /> },
  { name: 'Spring', icon: <Spring /> },
  { name: 'Outside alcohol allowed', icon: <Alcohol /> },
  { name: 'Indoor', icon: <Indoor /> },
  { name: 'Waterfront', icon: <WaterFront /> },
  { name: 'Outside catering allowed', icon: <Catering /> },
  { name: 'Outdoor', icon: <Outdoor /> },
];
