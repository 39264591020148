import * as React from 'react';

import { SVGComponentProps } from './Venu';
const GuestList2: React.FC<SVGComponentProps> = ({
  color = '#6B759D',
  ...props
}) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='36' height='36' rx='5' fill='#EAEBEF' />
    <g clipPath='url(#clip0_1969_6930)'>
      <path
        d='M18.625 22.1666C18.625 20.4275 19.8867 18.985 21.5417 18.6883V11.9583C21.5417 11.0375 20.7958 10.2916 19.875 10.2916H10.2917C9.37083 10.2916 8.625 11.0375 8.625 11.9583V23.2083C8.625 24.1291 9.37083 24.875 10.2917 24.875H19.875C19.8867 24.875 19.8967 24.8716 19.9083 24.8716C19.1308 24.2216 18.625 23.2566 18.625 22.1666Z'
        fill='#ECEFF1'
      />
      <path
        d='M22.1667 26.3333C19.8692 26.3333 18 24.4642 18 22.1667C18 19.8692 19.8692 18 22.1667 18C24.4642 18 26.3333 19.8692 26.3333 22.1667C26.3333 24.4642 24.4642 26.3333 22.1667 26.3333ZM22.1667 19.25C20.5583 19.25 19.25 20.5583 19.25 22.1667C19.25 23.775 20.5583 25.0833 22.1667 25.0833C23.775 25.0833 25.0833 23.775 25.0833 22.1667C25.0833 20.5583 23.775 19.25 22.1667 19.25Z'
        fill={color}
      />
      <path
        d='M27.3751 28C27.2151 28 27.0551 27.9391 26.9335 27.8166L24.2251 25.1083C23.981 24.8641 23.981 24.4683 24.2251 24.2241C24.4693 23.98 24.8651 23.98 25.1093 24.2241L27.8176 26.9325C28.0618 27.1766 28.0618 27.5725 27.8176 27.8166C27.6951 27.9391 27.5351 28 27.3751 28Z'
        fill={color}
      />
      <path
        d='M16.775 25.5H10.2917C9.0275 25.5 8 24.4725 8 23.2083V11.9583C8 10.6941 9.0275 9.66663 10.2917 9.66663H19.875C21.1392 9.66663 22.1667 10.6941 22.1667 11.9583V16.1583C22.1667 16.5033 21.8867 16.7833 21.5417 16.7833C21.1967 16.7833 20.9167 16.5033 20.9167 16.1583V11.9583C20.9167 11.3841 20.4492 10.9166 19.875 10.9166H10.2917C9.7175 10.9166 9.25 11.3841 9.25 11.9583V23.2083C9.25 23.7825 9.7175 24.25 10.2917 24.25H16.775C17.12 24.25 17.4 24.53 17.4 24.875C17.4 25.22 17.12 25.5 16.775 25.5Z'
        fill={color}
      />
      <path
        d='M11.125 12.1667C10.78 12.1667 10.5 11.8867 10.5 11.5417V8.625C10.5 8.28 10.78 8 11.125 8C11.47 8 11.75 8.28 11.75 8.625V11.5417C11.75 11.8867 11.47 12.1667 11.125 12.1667Z'
        fill={color}
      />
      <path
        d='M15.083 12.1667C14.738 12.1667 14.458 11.8867 14.458 11.5417V8.625C14.458 8.28 14.738 8 15.083 8C15.428 8 15.708 8.28 15.708 8.625V11.5417C15.708 11.8867 15.428 12.1667 15.083 12.1667Z'
        fill={color}
      />
      <path
        d='M19.042 12.1667C18.697 12.1667 18.417 11.8867 18.417 11.5417V8.625C18.417 8.28 18.697 8 19.042 8C19.387 8 19.667 8.28 19.667 8.625V11.5417C19.667 11.8867 19.387 12.1667 19.042 12.1667Z'
        fill={color}
      />
      <path
        d='M17.2833 19.25H11.125C10.78 19.25 10.5 18.97 10.5 18.625C10.5 18.28 10.78 18 11.125 18H17.2833C17.6283 18 17.9083 18.28 17.9083 18.625C17.9083 18.97 17.6283 19.25 17.2833 19.25Z'
        fill={color}
      />
      <path
        d='M14.875 15.9166H11.125C10.78 15.9166 10.5 15.6366 10.5 15.2916C10.5 14.9466 10.78 14.6666 11.125 14.6666H14.875C15.22 14.6666 15.5 14.9466 15.5 15.2916C15.5 15.6366 15.22 15.9166 14.875 15.9166Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1969_6930'>
        <rect width='20' height='20' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </svg>
);
export default GuestList2;
