import React from 'react';

import Footer from '@/components/Footer';
import Header from '@/components/Header';

import Dashboard from './modules/Dashboard';

const VendorDashboard = () => {
  return (
    <div className='w-full'>
      <Header />
      <Dashboard />
      <Footer />
    </div>
  );
};

export default VendorDashboard;
