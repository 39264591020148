/* eslint-disable no-nested-ternary */
import { ReactNode, useEffect, useRef, useState } from 'react';

import DropdownArrow from '@/assets/svg/DropdownArrow';

export interface IDropdownMenuItems {
  label: string;
  onClick: () => void;
  icon?: JSX.Element | string;
  disable?: boolean;
}

interface MenuProps {
  options: IDropdownMenuItems[];
  selectedValue: string | number;
  height?: string;
  leftIcon?: ReactNode;
  fullWidth?: boolean;
  placeholder?: string;
  isBorderRight?: boolean;
  disable?: boolean;
  className?: string;
  minWidth?: string;
  isArrow?: boolean;
}

const Menu: React.FC<MenuProps> = ({
  options,
  selectedValue,
  leftIcon,
  height = '',
  fullWidth,
  placeholder,
  isBorderRight = true,
  disable = false,
  className = '',
  minWidth = '',
  isArrow = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedValue);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionChange = (option: IDropdownMenuItems) => {
    if (!option.disable) {
      setSelectedOption(option.label);
      option.onClick();
      setIsOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`relative inline-block ${minWidth ? minWidth : 'min-w-36 '} text-left ${fullWidth ? 'w-full' : 'w-2/5'}`}
      ref={dropdownRef}
    >
      <div>
        <button
          disabled={disable}
          type='button'
          className={`inline-flex gap-3 items-center justify-between ${
            height ? `h-[${height}] ` : 'h-12'
          } ${
            disable ? 'bg-gray-100 text-gray-400' : 'text-[#0A0C2C]'
          } w-full px-3 py-2 text-xs md:text-lg font-semibold  bg-white border border-gray-200 ${
            !isBorderRight ? 'border-r-0 rounded-l-lg' : ' rounded-xl'
          } ${className}`}
          id='options-menu'
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-haspopup='true'
          onClick={toggleMenu}
        >
          {leftIcon}
          {selectedOption || (
            <span className='text-gray-400'>{placeholder}</span>
          )}
          {isArrow && (
            <div className='p-1 rounded-sm bg-[#F3F3F5]'>
              <DropdownArrow
                width={15}
                height={15}
                className={isOpen ? '' : 'rotate-180'}
              />
            </div>
          )}
        </button>
      </div>

      {isOpen && (
        <div
          className='absolute z-50 max-h-56 overflow-auto right-0 w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-2xl border border-borderColor ring-black focus:outline-none'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='options-menu'
        >
          <div className='py-1' role='none'>
            {options?.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionChange(option)}
                disabled={option.disable}
                className={`${
                  option.disable
                    ? 'text-gray-400 cursor-not-allowed'
                    : option.label === selectedOption
                      ? 'text-gray-900 bg-gray-100'
                      : 'text-[#0A0C2C]'
                } flex items-center font-semibold gap-3 w-full px-4 py-1 text-sm leading-5 text-left`}
                role='menuitem'
              >
                {option.icon}
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
