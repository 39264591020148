import * as React from 'react';

import { SVGComponentProps } from './Venu';

const BakerySVG: React.FC<SVGComponentProps> = ({
  color = '#0A0C2C',
  ...props
}) => (
  <svg
    width={50}
    height={51}
    viewBox='0 0 50 51'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_1969_2119)'>
      <path
        d='M11.9652 29.3448C11.9652 29.7447 12.2894 30.0689 12.6894 30.0689C13.0893 30.0689 13.4135 29.7447 13.4135 29.3448C13.4081 25.4632 15.1276 21.7799 18.1066 19.2916C18.1885 19.2192 18.2754 19.154 18.3586 19.0845C17.4453 19.2595 16.542 19.4831 15.6525 19.7543C13.2565 22.3708 11.9392 25.797 11.9652 29.3448Z'
        fill={color}
      />
      <path
        d='M35.1387 30.069C35.5387 30.069 35.8629 29.7448 35.8629 29.3448C35.7912 26.5085 36.6712 23.7298 38.3626 21.4517C37.9484 21.22 37.5175 20.997 37.0591 20.7863C35.2664 23.2736 34.3375 26.2797 34.4146 29.3448C34.4146 29.7448 34.7388 30.069 35.1387 30.069Z'
        fill={color}
      />
      <path
        d='M23.5521 30.0689C23.952 30.0689 24.2762 29.7447 24.2762 29.3448C24.2529 25.232 26.0866 21.3282 29.267 18.7203C28.636 18.6478 27.9843 18.5921 27.3118 18.553C24.3885 21.375 22.7652 25.2821 22.8279 29.3448C22.8279 29.5369 22.9042 29.721 23.04 29.8568C23.1758 29.9927 23.36 30.0689 23.5521 30.0689Z'
        fill={color}
      />
      <path
        d='M6.17383 43.8275C6.17383 45.0273 7.14645 45.9999 8.34624 45.9999H41.6566C42.8564 45.9999 43.829 45.0273 43.829 43.8275V43.1034H6.17383V43.8275Z'
        fill={color}
      />
      <path
        d='M11.2419 38.7586H38.7591C43.206 38.7586 45.2763 36.4573 45.2763 31.5173C45.2763 28.6157 43.6159 24.932 39.6107 22.2208C38.0461 24.2595 37.2337 26.7761 37.3108 29.3449C37.3108 30.5446 36.3382 31.5173 35.1384 31.5173C33.9386 31.5173 32.966 30.5446 32.966 29.3449C32.8932 26.0917 33.8447 22.8979 35.6859 20.2149C34.2501 19.6788 32.7684 19.275 31.2592 19.0085C27.7817 21.2932 25.6983 25.1841 25.7246 29.3449C25.7246 30.5446 24.752 31.5173 23.5522 31.5173C22.3524 31.5173 21.3798 30.5446 21.3798 29.3449C21.3376 25.359 22.7597 21.4963 25.3763 18.4893C25.2496 18.4893 25.1286 18.4828 25.0005 18.4828C23.7694 18.4828 22.6057 18.5407 21.5007 18.6421C20.6379 19.149 19.8219 19.7318 19.0625 20.3837C16.3847 22.586 14.8419 25.8778 14.8625 29.3449C14.8625 30.5446 13.8899 31.5173 12.6901 31.5173C11.4903 31.5173 10.5177 30.5446 10.5177 29.3449C10.4925 26.2788 11.3872 23.2756 13.0862 20.7233C7.11426 23.4243 4.72461 28.0342 4.72461 31.5173C4.72461 36.4573 6.79492 38.7586 11.2419 38.7586ZM11.2419 35.8621H38.7591C39.159 35.8621 39.4832 36.1863 39.4832 36.5862C39.4832 36.9862 39.159 37.3104 38.7591 37.3104H11.2419C10.8419 37.3104 10.5177 36.9862 10.5177 36.5862C10.5177 36.1863 10.8419 35.8621 11.2419 35.8621Z'
        fill={color}
      />
      <path
        d='M44.5522 40.2069H5.44875C5.04882 40.2069 4.72461 40.5311 4.72461 40.931C4.72461 41.331 5.04882 41.6552 5.44875 41.6552H44.5522C44.9521 41.6552 45.2763 41.331 45.2763 40.931C45.2763 40.5311 44.9521 40.2069 44.5522 40.2069Z'
        fill={color}
      />
      <path
        d='M24.5182 8.16234C24.7929 8.36214 24.9681 8.67063 24.9991 9.00886C24.9619 9.38072 24.7895 9.726 24.5146 9.97921C24.2178 10.2461 24.1928 10.7028 24.4589 11.0004C24.7249 11.2979 25.1816 11.3241 25.4799 11.0589C26.0597 10.5323 26.4075 9.79761 26.4473 9.01538C26.4269 8.25866 26.0727 7.54974 25.4799 7.07903C25.2052 6.87924 25.03 6.57075 24.9991 6.23252C25.0357 5.86231 25.2068 5.51837 25.4799 5.26579C25.7798 5.00024 25.8077 4.54181 25.5422 4.24186C25.2766 3.94191 24.8182 3.91403 24.5182 4.17959C23.9378 4.70692 23.5899 5.44275 23.5508 6.226C23.5712 6.98272 23.9254 7.69164 24.5182 8.16234Z'
        fill={color}
      />
      <path
        d='M16.5534 12.5072C16.8281 12.707 17.0033 13.0155 17.0342 13.3537C16.997 13.7256 16.8246 14.0709 16.5498 14.3241C16.2529 14.5909 16.228 15.0476 16.494 15.3452C16.7601 15.6428 17.2167 15.669 17.515 15.4037C18.0948 14.8771 18.4426 14.1425 18.4825 13.3602C18.4621 12.6035 18.1079 11.8946 17.515 11.4239C17.2404 11.2241 17.0651 10.9156 17.0342 10.5774C17.0708 10.2072 17.2419 9.86322 17.515 9.61064C17.815 9.34509 17.8429 8.88666 17.5773 8.58671C17.3118 8.28676 16.8533 8.25888 16.5534 8.52443C15.9729 9.05176 15.6251 9.7876 15.5859 10.5708C15.6063 11.3276 15.9605 12.0365 16.5534 12.5072Z'
        fill={color}
      />
      <path
        d='M32.485 12.5072C32.7597 12.707 32.9349 13.0155 32.9659 13.3537C32.9287 13.7256 32.7563 14.0709 32.4814 14.3241C32.1846 14.5909 32.1596 15.0476 32.4257 15.3452C32.6917 15.6428 33.1484 15.669 33.4467 15.4037C34.0264 14.8771 34.3743 14.1425 34.4141 13.3602C34.3937 12.6035 34.0395 11.8946 33.4467 11.4239C33.172 11.2241 32.9968 10.9156 32.9659 10.5774C33.0025 10.2072 33.1736 9.86322 33.4467 9.61064C33.7466 9.34509 33.7745 8.88666 33.509 8.58671C33.2434 8.28676 32.785 8.25888 32.485 8.52443C31.9046 9.05176 31.5567 9.7876 31.5176 10.5708C31.538 11.3276 31.8922 12.0365 32.485 12.5072Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1969_2119'>
        <rect width={42} height={42} fill='white' transform='translate(4 4)' />
      </clipPath>
    </defs>
  </svg>
);
export default BakerySVG;
