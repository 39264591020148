import React from 'react';
import { toast, Toaster } from 'react-hot-toast';

const ToastContainer = () => {
  return <Toaster position='top-right' reverseOrder={false} />;
};

export const showSuccessToast = (message: string) => {
  toast.success(message, {
    style: {
      border: '1px solid #4caf50',
      padding: '16px',
      color: '#4caf50',
    },
    iconTheme: {
      primary: '#4caf50',
      secondary: '#FFFAEE',
    },
  });
};

export const showErrorToast = (message: string) => {
  toast.error(message, {
    style: {
      border: '1px solid #f44336',
      padding: '16px',
      color: '#f44336',
    },
    iconTheme: {
      primary: '#f44336',
      secondary: '#FFFAEE',
    },
  });
};

export default ToastContainer;
