import { ResponseType } from '@/@types';
import { API } from '@/api/ApiInstance';
import { apiEndpoints } from '@/constants/apiEndpoints';
import { COMMON_SUCCESS } from '@/constants/feedback';
import { setTokenToLS } from '@/utils/common';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import {
  forgotPasswordRequest,
  LoginCredentials,
  LoginResponse,
  resetPasswordRequest,
  SignupCredentials,
} from '@/utils/types/authApiInterfaces';

export const signinUser = async (payload: LoginCredentials) => {
  const response = await API.Post<
    LoginCredentials,
    ResponseType<LoginResponse>
  >(apiEndpoints.LOGIN, {
    ...payload,
  });

  if (!response.status) {
    showErrorToast(response.message || 'Login failed. Please try again.');
    return;
  }

  showSuccessToast(response?.data?.message || COMMON_SUCCESS);
  return response.data;
};

export const signup = async (
  payload: SignupCredentials & { suppressToast?: boolean },
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await API.Post<SignupCredentials, ResponseType<any>>(
    apiEndpoints.SIGNUP,
    {
      ...payload,
    },
  );

  if (!response.status) {
    showErrorToast(response.message || 'Signup failed. Please try again.');
    return;
  }

  if (!payload.suppressToast)
    showSuccessToast(response?.data?.message || COMMON_SUCCESS);
  setTokenToLS(response?.data?.data?.token);
  return response.data;
};

export const forgotPassword = async (payload: forgotPasswordRequest) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await API.Post<forgotPasswordRequest, ResponseType<any>>(
    apiEndpoints.FORGOT_PASSWORD,
    {
      ...payload,
    },
  );

  if (!response.status) {
    showErrorToast(
      response.message || 'Failed to send reset link. Please try again.',
    );
    return;
  }

  showSuccessToast(response?.data?.message || COMMON_SUCCESS);
  return response.data;
};

export const resetPassword = async (payload: resetPasswordRequest) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await API.Post<resetPasswordRequest, ResponseType<any>>(
    apiEndpoints.CHANGE_PASSWORD,
    {
      ...payload,
    },
  );

  if (!response.status) {
    showErrorToast(
      response.message || 'Failed to reset password. Please try again.',
    );
    return;
  }

  showSuccessToast(response?.data?.message || COMMON_SUCCESS);
  return response.data;
};

export const verifyEmail = async (payload: { token: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await API.Post<{ token: string }, ResponseType<any>>(
    apiEndpoints.VERIFY_EMAIL,
    {
      ...payload,
    },
  );

  if (!response.status) {
    return {
      status: false,
      message:
        response.message ||
        'An error occurred while verifying your email. Please try again.',
    };
  }

  return { status: true };
};
