import * as React from 'react';

import { SVGComponentProps } from '../Venu';

const Catering: React.FC<SVGComponentProps> = (props) => (
  <svg
    width='22'
    height='23'
    viewBox='0 0 22 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='22.163' rx='2.93333' fill='#6B759D' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.40625 6.18223C5.40635 6.68023 5.81165 7.08544 6.3095 7.08544C6.33202 7.08544 6.35501 7.08456 6.37775 7.0829C6.42442 7.07941 6.47019 7.09557 6.50431 7.12732C6.53874 7.15907 6.55798 7.20371 6.55798 7.25043L6.55802 7.72454H10.2338V7.25041C10.2338 7.2037 10.2532 7.15911 10.2876 7.12732C10.3218 7.09549 10.3675 7.07935 10.4142 7.0829C10.4371 7.08452 10.46 7.08544 10.4824 7.08544C10.9804 7.08544 11.3857 6.68028 11.3857 6.18223C11.3858 5.68416 10.9803 5.27903 10.4824 5.27903C10.4615 5.27901 10.4396 5.27982 10.4177 5.28138C10.4143 5.28162 10.4109 5.28175 10.4076 5.28179C10.3554 5.28324 9.5112 5.31974 9.35025 6.12335C9.33461 6.20316 9.26424 6.25842 9.18576 6.25841C9.17502 6.25841 9.16389 6.25736 9.15253 6.25512C9.06189 6.23688 9.00261 6.14844 9.02099 6.05745C9.17468 5.28856 9.83072 5.01312 10.2569 4.95708C10.2579 4.93914 10.2585 4.92117 10.2585 4.90321C10.2586 4.40512 9.85337 4 9.35522 4C9.0103 3.99998 8.70045 4.19199 8.54645 4.50106C8.51789 4.55802 8.45956 4.59413 8.39599 4.59413C8.33243 4.59412 8.27406 4.55808 8.24547 4.50106C8.0916 4.19198 7.78175 4 7.43673 4C6.93863 3.99998 6.53348 4.40518 6.53348 4.90321C6.53348 4.92116 6.53402 4.93914 6.53508 4.95708C6.96126 5.01311 7.61727 5.28857 7.77097 6.05745C7.78935 6.14844 7.73042 6.23692 7.63942 6.25512C7.62844 6.2573 7.61696 6.25842 7.60626 6.25841C7.52777 6.25841 7.45773 6.20322 7.44169 6.12335C7.37357 5.78219 7.17657 5.53751 6.85635 5.39601C6.6172 5.29021 6.39274 5.28204 6.38385 5.28178L6.38358 5.28177L6.38315 5.28176L6.37944 5.28165L6.37922 5.28164L6.37529 5.28142L6.37492 5.2814L6.37455 5.28138L6.37351 5.2813C6.35168 5.27977 6.33018 5.27903 6.3095 5.27903C5.81165 5.27901 5.40625 5.68421 5.40625 6.18223Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5245 8.06055C6.20289 9.57917 6.51916 10.8282 7.38105 11.4179C7.39147 11.425 7.40196 11.432 7.4125 11.4389L7.4192 11.4432C8.01727 11.8309 8.77393 11.831 9.37183 11.4434L9.37927 11.4387C9.38969 11.4319 9.40006 11.4249 9.41036 11.4179C10.2723 10.8282 10.5885 9.5792 10.2669 8.06055H6.5245Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.9423 17.3218H10.781L10.7803 17.3218L10.7795 17.3218H10.4679L10.2338 17.9615C10.2326 17.9651 10.2314 17.9686 10.2299 17.972L10.2197 18H17.5026L17.2546 17.3218H16.9423Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8612 14.2408C12.3112 14.2408 11.0405 15.4575 10.9531 16.9859L16.7692 16.9859C16.6819 15.4575 15.4109 14.2408 13.8612 14.2408Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.5508 13.2266V13.9195C13.6528 13.9097 13.7562 13.9048 13.8607 13.9048C13.9653 13.9048 14.0687 13.9099 14.1707 13.9196L14.1708 13.2266H13.5508Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.53992 12.582L8.27394 13.9482L8.26889 13.9537L7.65234 14.6189L7.65238 17.7411H9.95641L10.1923 17.0962C10.2166 17.0299 10.2795 16.9859 10.3501 16.9859H10.6165C10.683 15.6871 11.5155 14.5872 12.6702 14.1308C12.4229 13.2252 11.6017 12.582 10.6666 12.582L9.53992 12.582ZM8.58154 16.2588C8.88581 16.2588 9.13304 16.5063 9.13304 16.8105C9.13307 17.1147 8.88574 17.3622 8.58157 17.3622C8.27735 17.3622 8.02973 17.1147 8.02973 16.8105C8.02983 16.5063 8.27735 16.2588 8.58154 16.2588ZM8.58154 14.748C8.88581 14.748 9.13304 14.9955 9.13304 15.2997C9.13307 15.6039 8.88574 15.8514 8.58157 15.8513C8.27735 15.8513 8.02973 15.6039 8.02973 15.2997C8.02983 14.9954 8.27735 14.748 8.58154 14.748Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.12496 12.582C4.91321 12.582 4.25722 13.6625 4.04548 14.6795L4 17.7411H5.07007L5.07016 15.1155C5.07016 15.0228 5.14529 14.9476 5.23805 14.9476C5.33121 14.9475 5.40604 15.0228 5.40604 15.1155L5.40613 17.7411H7.31637L7.3164 14.5528C7.3164 14.5068 7.33491 14.4651 7.36486 14.4348L7.94059 13.8136L7.22807 12.582H6.12496Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.49321 12.3693L8.17976 13.5557L9.29846 12.3484L9.29847 11.868C9.01175 12.003 8.70371 12.0705 8.39592 12.0705L8.39581 12.0705C8.08774 12.0705 7.77984 12.003 7.49316 11.868L7.49321 12.3693Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.58206 15.084C8.46304 15.084 8.36621 15.1808 8.36621 15.2997C8.36631 15.4186 8.46304 15.5155 8.58204 15.5155C8.70112 15.5154 8.79755 15.4186 8.79755 15.2997C8.79758 15.1808 8.70102 15.084 8.58206 15.084Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.58206 16.5948C8.46304 16.5948 8.36621 16.6916 8.36621 16.8106C8.36631 16.9295 8.46304 17.0263 8.58204 17.0263C8.70112 17.0262 8.79755 16.9295 8.79755 16.8106C8.79758 16.6916 8.70102 16.5948 8.58206 16.5948Z'
      fill='white'
    />
  </svg>
);
export default Catering;
