import Dj from '@/assets/png/DJ.png';
import EditaCake from '@/assets/png/EditaCake.png';

interface Section {
  title: string;
  value: string;
  route: string;
}

export const plannerHomeData = [
  '1 Unread Message',
  'Final payment of $850 due on 6/17/24',
  'Send guest reminders for Amv’s Graduation Party',
  'Follow-up with unconfirmed Vendor',
];
export const TopFourData: Section[] = [
  { title: 'Type', value: 'Birthday Party', route: '/planner' },
  { title: 'Location', value: 'Detroit, MI', route: '/planner' },
  { title: 'Date', value: 'January 21, 2024', route: '/planner' },
  { title: 'Time', value: '6pm-9pm', route: '/planner' },
];

export const PlannerHeroSectionData: Section[] = [
  { title: 'No. of Guests', value: '75', route: '/guest' },
  { title: 'Theme', value: 'Masquerade', route: '/theme' },
  { title: 'Venue', value: 'Infinity Ovation', route: '/venue' },
  { title: 'Decor', value: 'Labelle Events', route: '/decor' },
  { title: 'Party Favors', value: 'Bling Apples', route: '/decor' },
  { title: 'Catering', value: 'Celebrity Catering', route: '/food' },
  { title: 'Dessert', value: 'Trendy Treatz', route: '/desert' },
  { title: 'Bar Service', value: 'Love a Buzz', route: '/bar' },
  { title: 'Photo/Video', value: 'Rose Tint Media', route: '/photography' },
  { title: 'Entertainment', value: 'DJ Russel Cole', route: '/entertainment' },
  // { title: 'Registry', value: 'Amazon', route: '/registry' },
];
export const tabsData = [
  { title: 'Guest List', route: '/guest' },
  { title: 'Theme', route: '/theme' },
  { title: 'Venue', route: '/venue' },
  { title: 'Decor', route: '/decor' },
  { title: 'Food', route: '/food' },
  { title: 'Desserts', route: '/desert' },
  { title: 'Bar Service', route: '/bar' },
  { title: 'Photography/videographer', route: '/photography' },
  { title: 'Entertainment', route: '/entertainment' },
  // { title: 'Party favors', route: '/party' },
  // { title:  'Registry'},
  { title: 'Invitations', route: '/invitation' },
  // { title:  'RSVPS'},
  { title: 'Summary', route: '/summary' },
];

export const themeFilterSections = [
  {
    title: 'Age Range',
    items: [
      { label: 'Kids', value: 'kids' },
      { label: 'Teens', value: 'teens' },
      { label: 'Adults', value: 'adults' },
      { label: 'Family Appropriate', value: 'family' },
    ],
    name: 'ageRange',
    multiSelect: true,
  },

  {
    title: 'Environment',
    items: [
      { label: 'Indoor', value: 'indoor' },
      { label: 'Outdoor', value: 'outdoor' },
    ],
    name: 'environment',
  },

  {
    title: 'Season',
    items: [
      { label: 'Winter', value: 'winter' },
      { label: 'Spring', value: 'spring' },
      { label: 'Summer', value: 'summer' },
      { label: 'Fall', value: 'fall' },
    ],
    name: 'season',
    multiSelect: true,
  },
  {
    title: 'Color Themes',
    items: [
      {
        label: 'Blue',
        value: 'blue',
        color: '#0091F71A',
        textColor: '#0091F7',
        selectedColor: '#007ACC',
      },
      {
        label: 'Orange',
        value: 'orange',
        color: '#FFA5001A',
        textColor: '#FFA500',
        selectedColor: '#CC8400',
      },
      {
        label: 'Red',
        value: 'red',
        color: '#FF00001A',
        textColor: '#FF0000',
        selectedColor: '#CC0000',
      },
      {
        label: 'Green',
        value: 'green',
        color: '#0080001A',
        textColor: '#008000',
        selectedColor: '#006600',
      },
      {
        label: 'Yellow',
        value: 'yellow',
        color: '#FFFF001A',
        textColor: '#D2D200',
        selectedColor: '#B3B300',
      },
      {
        label: 'Purple',
        value: 'purple',
        color: '#8000801A',
        textColor: '#800080',
        selectedColor: '#660066',
      },
      {
        label: 'Black',
        value: 'black',
        color: '#0000001A',
        textColor: '#000000',
        selectedColor: '#333333',
      },
      {
        label: 'White',
        value: 'white',
        color: '#FFFFFF',
        textColor: '#000000',
        selectedColor: '#FFFFFF',
      },
    ],
    name: 'colorTheme',
  },
  {
    title: 'Favorites',
    items: [],
    name: 'Favourite',
  },
];

export const venueFilterSections = [
  {
    name: 'venueType',
    title: 'Venue Type',
    items: [
      { label: 'Ballroom', value: 'ballroom' },
      { label: 'Barn', value: 'barn' },
      { label: 'Convention Center', value: 'conventionCenter' },
      { label: 'Garden', value: 'garden' },
      { label: 'House Estate', value: 'houseEstate' },
      { label: 'Hotel', value: 'hotel' },
      { label: 'Museum', value: 'museum' },
      { label: 'Restaurant', value: 'restaurant' },
      { label: 'Rooftop', value: 'rooftop' },
      { label: 'Other', value: 'other' },
    ],
  },
  // {
  //   name: '',
  //   title: 'Venue Amenities',
  //   items: [],
  //   type: 'label',
  // },
  {
    name: 'outsideCatering',
    title: 'Outside Catering Allowed',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'outsideAlcohol',
    title: 'Outside Alcohol Allowed',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'wirelessInternet',
    title: 'Wirless Internet',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'smokingAllowed',
    title: 'Smoking Allowed',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'vibe',
    title: 'Vibe',
    items: [
      { label: 'Cozy', value: 'cozy' },
      { label: 'Modern', value: 'modern' },
      { label: 'Rustic', value: 'rustic' },
      { label: 'Elegant', value: 'elegant' },
    ],
  },
  {
    name: '',
    title: 'Favorites',
    items: [],
  },
];

export const decorFilterSections = [
  {
    name: 'decorTypes',
    title: 'Type',
    items: [
      { label: 'Full Service Decor', value: 'full decor' },
      { label: 'Balloon Design', value: 'balloon' },
      { label: 'Lighting', value: 'lighting' },
    ],
    multiSelect: true,
  },
  {
    name: 'rentalOptions',
    title: 'Decor rentals',
    items: [
      { label: 'Cloth Rentals', value: 'Cloth rental' },
      { label: 'Chair Rentals', value: 'Chair rental' },
    ],
  },
  {
    name: 'decorPieces',
    title: 'Accents / Decor Pieces',
    items: [
      { label: 'Backdrops', value: 'Backdrops' },
      { label: 'Centerpieces', value: 'Centerpieces' },
      { label: 'Cake Stands', value: 'Cake Stands' },
      { label: 'Theme Props', value: 'Theme Props' },
    ],
  },

  {
    name: 'partyFavors',
    title: 'Party Favors',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    title: 'Favorites',
    items: [],
  },
];

export const foodFilterSections = [
  {
    name: 'pickUp',
    title: 'Pick Up',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'delivery',
    title: 'Delivery',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  // {
  //   name: '',
  //   title: 'Delivery Options',
  //   items: [
  //     { label: 'Pick-up', value: 'pick-up' },
  //     { label: 'Delivery', value: 'delivery' },
  //   ],
  // },
  {
    name: 'cateringAmenities',
    title: 'Catering Amenities',
    items: [
      { label: 'Servers', value: 'servers' },
      { label: 'Food Service Rentals', value: 'Food Service Rentals' },
      { label: 'Chef Stations', value: 'Chef Stations' },
      { label: 'Set-Up included', value: 'Set-Up' },
      { label: 'Catering Clean-up', value: 'Clean-up' },
    ],
    multiSelect: true,
  },
  {
    name: 'servingStyles',
    title: 'Dinner Style',
    items: [
      { label: 'Buffet', value: 'Buffet' },
      { label: 'Plated/Seated Dinner', value: 'Plated/Seated Dinner' },
      { label: 'Food truck', value: 'Food truck' },
      { label: 'Family Style', value: 'Family Style' },
      { label: 'Passed Appetizers', value: 'Passed Appetizers' },
    ],
    multiSelect: true,
  },
  {
    name: 'dietaryOptions',
    title: 'Dietary options',
    items: [
      { label: 'Vegan', value: 'Vegan' },
      { label: 'Vegetarian', value: 'Vegetarian' },
      { label: 'Halal', value: 'Halal' },
      { label: 'Kosher', value: 'Kosher' },
      { label: 'Nut-free', value: 'Nut-free' },
      { label: 'Organic', value: 'Organic' },
      { label: 'Gluten-Free', value: 'Gluten-Free' },
    ],
    multiSelect: true,
    type: 'chip',
  },
  {
    name: 'cuisine',
    title: 'Cuisine',
    items: [
      { label: 'American', value: 'Vegan' },
      { label: 'African', value: 'African' },
      { label: 'Soul-food', value: 'Soul-food' },
      { label: 'Chinese', value: 'Chinese' },
      { label: 'Japanese', value: 'Japanese' },
      { label: 'French', value: 'French' },
      { label: 'Eastern European', value: 'Eastern European' },
      { label: 'Fusion', value: 'Fusion' },
      { label: 'Greek', value: 'Greek' },
      { label: 'Hawaiian', value: 'Hawaiian' },
      { label: 'Indian', value: 'Indian' },
      { label: 'Mediterranean', value: 'Mediterranean' },
      { label: 'Italian', value: 'Italian' },
    ],
    multiSelect: true,
    type: 'chip',
  },
  {
    title: 'Favorites',
    items: [],
  },
];

export const desertCardSection = [
  {
    title: 'Edita’s Cakes',
    imgUrl: EditaCake,
    tags: [],
    cardData: {
      rating: '5.0',
      review: 'Good',
      reviewCount: '734',
      subtitle:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s.',
    },
  },
  {
    title: 'Edita’s Cakes',
    imgUrl: EditaCake,
    tags: [],
    cardData: {
      rating: '5.0',
      review: 'Good',
      reviewCount: '734',
      subtitle:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s.',
    },
  },
];
export const desertFilterSections = [
  {
    name: 'pickUp',
    title: 'Pick Up',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'delivery',
    title: 'Delivery',
    items: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  {
    name: 'priceLevel',
    title: 'Price',
    items: [
      { label: '$- Inexpensive', value: '1' },
      { label: '$$- Affordable', value: '2' },
      { label: '$$$- Moderate', value: '3' },
      { label: '$$$$- Luxury', value: '4' },
    ],
  },
  {
    name: 'desserts',
    title: 'Desserts',
    items: [
      { label: 'Cake', value: 'Cake' },
      { label: 'Cupcakes', value: 'Cupcakes' },
      { label: 'Cookies', value: 'Cookies' },
      { label: 'Other Desserts', value: 'Other Desserts' },
      { label: 'Party favors', value: 'Party favors' },
    ],
    multiSelect: true,
  },
  {
    name: 'bakeryDietaryOptions',
    title: 'Dietary options',
    items: [
      { label: 'Vegan', value: 'Vegan' },
      { label: 'Vegetarian', value: 'Vegetarian' },
      { label: 'Halal', value: 'Halal' },
      { label: 'Kosher', value: 'Kosher' },
      { label: 'Nut-free', value: 'Nut-free' },
      { label: 'Organic', value: 'Organic' },
      { label: 'Gluten-Free', value: 'Gluten-Free' },
    ],
    multiSelect: true,
    type: 'chip',
  },
  {
    name: 'bakeryCuisine',
    title: 'Cuisine',
    items: [
      { label: 'American', value: 'Vegan' },
      { label: 'African', value: 'African' },
      { label: 'Soul-food', value: 'Soul-food' },
      { label: 'Chinese', value: 'Chinese' },
      { label: 'Japanese', value: 'Japanese' },
      { label: 'French', value: 'French' },
      { label: 'Eastern European', value: 'Eastern European' },
      { label: 'Fusion', value: 'Fusion' },
      { label: 'Greek', value: 'Greek' },
      { label: 'Hawaiian', value: 'Hawaiian' },
      { label: 'Indian', value: 'Indian' },
      { label: 'Mediterranean', value: 'Mediterranean' },
      { label: 'Italian', value: 'Italian' },
    ],
    multiSelect: true,
    type: 'chip',
  },
  {
    title: 'Favorites',
    items: [],
  },
];

export const drinkFilterSections = [
  {
    name: 'priceLevel',
    title: 'Price',
    items: [
      { label: '$- Inexpensive', value: '1' },
      { label: '$$- Affordable', value: '2' },
      { label: '$$$- Moderate', value: '3' },
      { label: '$$$$- Luxury', value: '4' },
    ],
  },
  {
    name: 'drinkServices',
    title: 'Drink Services',
    items: [
      { label: 'Bartenders', value: 'Bartenders' },
      { label: 'Barista', value: 'Barista' },
    ],
  },
  {
    name: 'provideAlcohol',
    title: 'Alcohol Included',
    items: [],
    type: 'check',
  },
  {
    name: 'customerBuyAlcohol',
    title: 'Customer Buys Alcohol',
    items: [],
    type: 'check',
  },
  {
    name: 'drinkTypesOffered',
    title: 'Drink Type',
    items: [
      { label: 'Beer', value: 'Beer' },
      { label: 'Champagne', value: 'Champagne' },
      { label: 'Liquor', value: 'Liquor' },
      { label: 'Non-Alcoholic Drinks', value: 'Non-Alcoholic Drinks' },
      { label: 'Signature Cocktails', value: 'Signature Cocktails' },
      { label: 'Wine', value: 'Wine' },
    ],
  },
  {
    title: 'Favorites',
    items: [],
  },
];

export const photographyFilterSections = [
  {
    name: 'priceLevel',
    title: 'Price',
    items: [
      { label: '$- Inexpensive', value: '1' },
      { label: '$$- Affordable', value: '2' },
      { label: '$$$- Moderate', value: '3' },
      { label: '$$$$- Luxury', value: '4' },
    ],
  },
  {
    name: 'type',
    title: 'Type',
    items: [
      { label: 'Photographer', value: 'Photographer' },
      { label: 'Videographer', value: 'Videographer' },
      { label: 'Photo Booth', value: 'Photo Booth' },
    ],
    multiSelect: true,
  },
  {
    name: 'additionalServices',
    title: 'Services',
    items: [
      { label: 'Drone', value: 'Drone' },
      { label: 'Photo retouching', value: 'Photo retouching' },
      { label: 'Video Editing', value: 'Video Editing' },
      { label: 'Physical Proofs/Prints', value: 'Physical Proofs/Prints' },
    ],
    multiSelect: true,
  },
  {
    title: 'Favorites',
    items: [],
  },
];
export const entertainmentCardSection = [
  {
    title: 'DJ Russel Cole',
    imgUrl: Dj,
    tags: [],
    cardData: {
      rating: '5.0',
      review: 'Good',
      reviewCount: '734',
      subtitle:
        'We are a mobile bar & waitstaff company. The company was created and established in 2012',
    },
  },
];

export const entertainmentFilterSections = [
  // {
  //   name: 'priceLevel',
  //   title: 'Price Range',
  //   items: [
  //     { label: '$- Inexpensive', value: '1' },
  //     { label: '$$- Affordable', value: '2' },
  //     { label: '$$$- Moderate', value: '3' },
  //     { label: '$$$$- Luxury', value: '4' },
  //   ],
  // },
  // {
  //   title: 'Age Group',
  //   items: [
  //     { label: 'Children', value: 'Children' },
  //     { label: 'Teen', value: 'Teen' },
  //     { label: 'Adult', value: 'Adult' },
  //   ],
  // },
  {
    name: 'musicianOptions',
    title: 'Music',
    items: [
      { label: 'DJ', value: 'DJ' },
      { label: 'Band', value: 'Band' },
      { label: 'Instrument Player', value: 'Instrument Player' },
      { label: 'Singer', value: 'Singer' },
      { label: 'Equipment Rentals', value: 'Equipment Rentals' },
    ],
  },
  {
    name: 'entertainmentType',
    title: 'Entertainments',
    items: [
      { label: 'Comedy', value: 'Comedy' },
      { label: 'Poet', value: 'Poet' },
      { label: 'Dancer', value: 'Dancer' },
      { label: 'Character', value: 'Character' },
      { label: 'Magician', value: 'Magician' },
      { label: 'Clown', value: 'Clown' },
      { label: 'Face Painter', value: 'Face Painter' },
      { label: 'Painting Instructor', value: 'Painting Instructor' },
      { label: 'Kids Train Ride', value: 'fixRide' },
      { label: 'Animal Rental', value: 'Animal Rental' },
      { label: 'Petting zoo', value: 'Petting zoo' },
    ],
    multiSelect: true,
  },
  // {
  //   title: 'Equipment Rentals',
  //   items: [
  //     { label: 'Bounce house', value: 'Bounce house' },
  //     { label: 'Sauna', value: 'Sauna' },
  //     { label: 'Hot tub', value: 'Hot tub' },
  //     { label: 'Popcorn Machine', value: 'Popcorn Machine' },
  //     { label: 'Cotton Candy Machine', value: 'Cotton Candy Machine' },
  //     { label: 'Slushie Machine', value: 'Slushie Machine' },
  //     { label: 'Speakers', value: 'Speakers' },
  //   ],
  // },

  {
    title: 'Favorites',
    items: [],
  },
];
export const partyFilterSections = [
  {
    name: 'priceLevel',
    title: 'Price',
    items: [
      { label: '$- Inexpensive', value: '1' },
      { label: '$$- Affordable', value: '2' },
      { label: '$$$- Moderate', value: '3' },
      { label: '$$$$- Luxury', value: '4' },
    ],
  },
];
