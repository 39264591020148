import * as React from 'react';
const InfoSVG = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M7.99904 0.222656C10.062 0.222656 12.0404 1.04216 13.4991 2.50088C14.9579 3.9596 15.7774 5.93805 15.7774 8.00099C15.7774 10.0639 14.9579 12.0424 13.4991 13.5011C12.0404 14.9598 10.062 15.7793 7.99904 15.7793C5.93609 15.7793 3.95764 14.9598 2.49892 13.5011C1.0402 12.0424 0.220703 10.0639 0.220703 8.00099C0.220703 5.93805 1.0402 3.9596 2.49892 2.50088C3.95764 1.04216 5.93609 0.222656 7.99904 0.222656ZM9.1657 4.99821C9.74348 4.99821 10.2124 4.5971 10.2124 4.00266C10.2124 3.40821 9.74237 3.0071 9.1657 3.0071C8.58793 3.0071 8.12126 3.40821 8.12126 4.00266C8.12126 4.5971 8.58793 4.99821 9.1657 4.99821ZM9.36904 11.2504C9.36904 11.1315 9.41015 10.8227 9.38681 10.6471L8.47348 11.6982C8.28459 11.8971 8.04793 12.0349 7.93681 11.9982C7.8864 11.9797 7.84427 11.9437 7.81799 11.8969C7.79172 11.85 7.78302 11.7953 7.79348 11.7427L9.3157 6.93377C9.44015 6.32377 9.09793 5.7671 8.37237 5.69599C7.60682 5.69599 6.48015 6.47266 5.79459 7.45821C5.79459 7.57599 5.77237 7.86932 5.7957 8.04488L6.70793 6.99266C6.89681 6.79599 7.11682 6.6571 7.22793 6.69488C7.28267 6.71453 7.32753 6.75485 7.35287 6.8072C7.37821 6.85955 7.38202 6.91975 7.36348 6.97488L5.85459 11.7604C5.68015 12.3204 6.01015 12.8693 6.81015 12.9938C7.98793 12.9938 8.68348 12.236 9.37015 11.2504H9.36904Z'
      fill='#3D424D'
    />
  </svg>
);
export default InfoSVG;
