import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, useFormikContext } from 'formik';

import FileUploader from '@/components/FileUpload';
import SelectInputCombo from '@/components/ui/Dropdown';
import InputRadioCombo from '@/components/ui/TextRadio';
import { useConstantData } from '@/hooks/useLocalizedDummyData';

import AddSpaces from './add-spaces';

interface VenuFormProps {
  values: {
    spaces: {
      vibeDropdown: string;
      environmentOptions: string;
      hourlyDropdown: string;
      capacityStanding: string;
      capacitySeated: string;
      rate: string;
    }[];
    numberOfSpaces: number;
    [key: string]: unknown;
  };
}

const VenuForm: React.FC<VenuFormProps> = ({ values }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.numberOfSpaces) {
      const spacesArray = Array.from({ length: values.numberOfSpaces }).map(
        () => ({
          vibeDropdown: '',
          environmentOptions: '',
          hourlyDropdown: '',
          capacityStanding: '',
          capacitySeated: '',
          rate: '',
        }),
      );
      setFieldValue('spaces', spacesArray);
    }
  }, [values.numberOfSpaces, setFieldValue]);

  const handleFilesUpdate = (files: string[]) => {
    setFieldValue('images', files);
  };

  const { t } = useTranslation();
  const {
    radioInternetOptions,
    radioOutsideCateringOptions,
    radioSmokingAllowedOptions,
    venuTypeOptions,
    radioOutsideAlcoholOptions,
  } = useConstantData();

  return (
    <>
      <SelectInputCombo
        name='venuType'
        containerStyle='mt-1'
        label='Venu Type'
        options={venuTypeOptions}
        placeholder='Venue Type'
      />

      <InputRadioCombo
        name='wirelessInternet'
        containerStyle='mt-5'
        options={radioInternetOptions}
        label={t('vendorSignUpQuestionnaire.venue.wirelessInternet')}
        labelClassName='font-semibold text-black/80 '
      />
      <InputRadioCombo
        name='outsideCatering'
        containerStyle='mt-2'
        options={radioOutsideCateringOptions}
        label={t('vendorSignUpQuestionnaire.venue.outsideCateringAllThoued')}
        labelClassName='font-semibold text-black/80 '
      />
      <InputRadioCombo
        name='outsideAlcohol'
        containerStyle='mt-2'
        options={radioOutsideAlcoholOptions}
        label={t('vendorSignUpQuestionnaire.venue.outsideAlcoholAllowed')}
        labelClassName='font-semibold text-black/80 '
      />
      <InputRadioCombo
        name='smokingAllowed'
        containerStyle='mt-2'
        options={radioSmokingAllowedOptions}
        label={t('vendorSignUpQuestionnaire.venue.smokingAllowed')}
        labelClassName='font-semibold text-black/80 '
      />

      <SelectInputCombo
        name='numberOfSpaces'
        inputStyle='!w-full sm:w-[180px]'
        label={t('vendorSignUpQuestionnaire.venue.numberOfSpaces')}
        containerStyle='mt-5'
        options={Array.from({ length: 10 }, (_, i) => ({
          value: (i + 1).toString(),
          label: (i + 1).toString(),
        }))}
        placeholder='01'
      />

      <FieldArray
        name='spaces'
        render={() => (
          <>
            {values.spaces.map((_, index) => (
              <AddSpaces key={index} spaceNumber={index + 1} />
            ))}
          </>
        )}
      />
      <FileUploader onFilesUpdate={handleFilesUpdate} />
    </>
  );
};

export default VenuForm;
