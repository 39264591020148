import * as React from 'react';

import { SVGComponentProps } from './Venu';
const GoldenStar: React.FC<SVGComponentProps> = ({ ...props }) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      opacity='0.3'
      d='M11 0C11 6.07513 6.07513 11 0 11C6.07513 11 11 15.9249 11 22C11 15.9249 15.9249 11 22 11C15.9249 11 11 6.07513 11 0Z'
      fill='#FDB022'
    />
  </svg>
);
export default GoldenStar;
