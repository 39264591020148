import React from 'react';

import Container from '@/components/ui/Container';

import About from './About';
import CounterSection from './CounterSection';
import EventSection from './EventSection';
import Hero from './Hero';
import SignupToday from './SignupToday';

const PlannerSection = () => {
  return (
    <div>
      <Hero />
      <Container>
        <CounterSection />
        <About />
        <EventSection />
        <SignupToday />
      </Container>
    </div>
  );
};

export default PlannerSection;
