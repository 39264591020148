import { combineReducers } from '@reduxjs/toolkit';

import { authSlice } from './slices/authSlice';
import { businessSlice } from './slices/businessSlice';
import { filtersSlice } from './slices/filters';
import { guestListSlice } from './slices/guestListSlice';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [businessSlice.name]: businessSlice.reducer,
  [guestListSlice.name]: guestListSlice.reducer,
  [filtersSlice.name]: filtersSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
